import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Modal, Row } from "react-bootstrap";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import vectorGreaterSymbol from "../../../../assets/images/landingPage/vectorGreaterSymbol.svg";
import delistFlag from "../../../../assets/images/delist_flag.svg";
import ActivityIndicator from "../../../../components/Loader/loader";
import { InfoTooltip } from "../../../../components/Tooltip/tooltip";
import ModalWarning from "../modalWarning";
import ModalWarnReset from "../../TaskflowScreens/modalWarningReset";
import UserDetailsService from "../../../../service/userDetailsService";
import {
  fetchCheckDelists,
  SendEmailToEToEPlanner,
  saveCheckDelistData,
  fetchProjectWorkFlowStatus,
} from "../../../../service/projectDetails";
import { validateUnileverMailId, handleSort } from "../../../../utils";
import { postUpdatedWorkFlowStatus } from "../../../../slices/projectDetailSlice";
import "./checkDelists.css";
import ArrowUp from "../../../../assets/images/Arrow up sm.svg";
import ArrowDown from "../../../../assets/images/Arrow down sm.svg";
import polygon from "../../../../assets/images/Polygon-enable.svg";
import searchIcon from "../../../../assets/images/Search.svg";
// import { data } from "./data";

const CheckDelists = (props) => {
  const [objCheckDelist, setObjCheckDelist] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("");
  const { isEdit, stepStatus, userHasAccess } = props;
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [isSendE2EEnabled, setSendE2EEnabled] = useState(true);
  const [isEditAccessEnabled, setIsEditAccessEnabled] = useState(false);
  // const [values, setValues] = useState([]);
  const [activeSort, setActiveSort] = useState();
  const [isSortActive, setIsSortActive] = useState(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [isResetDisabled, setIsResetDisabled] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [fGImpactedIsOpen, setFGImpactedIsOpen] = useState(false);
  const [fGImpactedSearchTerm, setFGImpactedSearchTerm] = useState("");
  const [fGImpactedSelectAll, setFGImpactedSelectAll] = useState(true);
  const [selectedFGImpacted, setSelectedFGImpacted] = useState([]);
  const [fGNameIsOpen, setFGNameIsOpen] = useState(false);
  const [fGNameSearchTerm, setFGNameSearchTerm] = useState("");
  const [fGNameSelectAll, setFGNameSelectAll] = useState(true);
  const [selectedFGName, setSelectedFGName] = useState([]);
  const [sCIsOpen, setSCIsOpen] = useState(false);
  const [sCSearchTerm, setSCSearchTerm] = useState("");
  const [sCSelectAll, setSCSelectAll] = useState(true);
  const [selectedSC, setSelectedSC] = useState([]);
  const [sNIsOpen, setSNIsOpen] = useState(false);
  const [sNSearchTerm, setSNSearchTerm] = useState("");
  const [sNSelectAll, setSNSelectAll] = useState(true);
  const [selectedSN, setSelectedSN] = useState([]);
  const [ePlannerIsOpen, setEPlannerIsOpen] = useState(false);
  const [ePlannerSearchTerm, setEPlannerSearchTerm] = useState("");
  const [ePlannerSelectAll, setEPlannerSelectAll] = useState(true);
  const [selectedEPlanner, setSelectedEPlanner] = useState([]);
  const [fGSoldIsOpen, setFGSoldIsOpen] = useState(false);
  const [fGSoldSearchTerm, setFGSoldSearchTerm] = useState("");
  const [fGSoldSelectAll, setFGSoldSelectAll] = useState(true);
  const [selectedFGSold, setSelectedFGSold] = useState([]);
  // const [originalObjCheckDelist, setOriginalObjCheckDelist] =
  //   useState(objCheckDelist);
  const [tabChange, setTabChange] = useState("");
  const [filteredObjCheckDelist, setFilteredObjCheckDelist] =
    useState(objCheckDelist);
  const isDataLoaded = useRef(false);
  const dropdownRef = useRef(null);
  const userDetails = useSelector((state) => state.userDetails);
  // const hasEditAccess =
  //   isEdit ||
  //   email ===
  //     ? true
  //     : false;
  const dispatch = useDispatch();

  //Click outside
  const DROPDOWN_TYPES = {
    FG_IMPACTED: "fgImpacted",
    FG_NAME: "fgName",
    SU_SUPPLIER_CODE: "suSupplierCode",
    SU_SUPPLIER_NAME: "suSupplierName",
    E2E_PLANNER: "e2ePlanner",
    FG_SOLD_AT: "fgSoldAt",
  };

  const DropdownManager = () => {
    // Create a single state object for all dropdowns

    const [dropdownStates, setDropdownStates] = useState({
      [DROPDOWN_TYPES.FG_IMPACTED]: false,
      [DROPDOWN_TYPES.FG_NAME]: false,
      [DROPDOWN_TYPES.SU_SUPPLIER_CODE]: false,
      [DROPDOWN_TYPES.SU_SUPPLIER_NAME]: false,
      [DROPDOWN_TYPES.E2E_PLANNER]: false,
      [DROPDOWN_TYPES.FG_SOLD_AT]: false,
    });

    // Create refs object
    const refs = {
      [DROPDOWN_TYPES.FG_IMPACTED]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
      [DROPDOWN_TYPES.FG_NAME]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
      [DROPDOWN_TYPES.SU_SUPPLIER_CODE]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
      [DROPDOWN_TYPES.SU_SUPPLIER_NAME]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
      [DROPDOWN_TYPES.E2E_PLANNER]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
      [DROPDOWN_TYPES.FG_SOLD_AT]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
    };

    //UsEffect for Refer id
    // Single handler for all dropdowns
    const handleClickOutside = useCallback((event) => {
      Object.entries(refs).forEach(([key, { dropdown, toggle }]) => {
        if (
          dropdown.current &&
          toggle.current &&
          !dropdown.current.contains(event.target) &&
          !toggle.current.contains(event.target)
        ) {
          setDropdownStates((prev) => ({
            ...prev,
            [key]: false,
          }));
        }
      });
    }, []);

    // Single useEffect for all click outside listeners
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }, [handleClickOutside]);

    // Single toggle function for all dropdowns
    const toggleDropdown = useCallback((dropdownType) => {
      setDropdownStates((prev) => ({
        ...prev,
        [dropdownType]: !prev[dropdownType],
      }));
    }, []);

    return {
      states: dropdownStates,
      refs,
      toggleDropdown,
    };
  };
  const { states, refs, toggleDropdown } = DropdownManager();

  useEffect(() => {
    if (tabChange !== "") {
      if (!selectedFilters.length > 0 && objCheckDelist) {
        // setOriginalObjCheckDelist(objCheckDelist);
        setFilteredObjCheckDelist(objCheckDelist);

        // Helper function to extract unique values
        const getUniqueValues = (key) => {
          return Array.from(
            new Set(
              objCheckDelist[activeTab].reduce((acc, item) => {
                if (item?.fgData && Array.isArray(item.fgData)) {
                  item.fgData.forEach((fg) => {
                    if (fg?.[key]) {
                      acc.push(fg[key]);
                    }
                  });
                } else if (item?.[key]) {
                  acc.push(item[key]);
                }
                return acc;
              }, [])
            )
          );
        };

        // FG Impacted
        const uniqueFGImpacted = getUniqueValues("skuCode");
        setSelectedFGImpacted(uniqueFGImpacted);
        setFGImpactedSelectAll(uniqueFGImpacted.length > 0);

        // FG Name
        const uniqueFGName = getUniqueValues("skuName");
        setSelectedFGName(uniqueFGName);
        setFGNameSelectAll(uniqueFGName.length > 0);

        // Supplier Code
        const uniqueSC = getUniqueValues("plantCode");
        setSelectedSC(uniqueSC);
        setSCSelectAll(uniqueSC.length > 0);

        // Supplier Name
        const uniqueSN = getUniqueValues("plantName");
        setSelectedSN(uniqueSN);
        setSNSelectAll(uniqueSN.length > 0);

        // E2E Planner
        const uniqueEPlanner = getUniqueValues("updatedSchedulername");
        setSelectedEPlanner(uniqueEPlanner);
        setEPlannerSelectAll(uniqueEPlanner.length > 0);

        // FG Sold
        const uniqueFGSold = getUniqueValues("country");
        setSelectedFGSold(uniqueFGSold);
        setFGSoldSelectAll(uniqueFGSold.length > 0);
        // Mark data as loaded
        isDataLoaded.current = true;
      } else {
        // Reset all selections if no data or filters are applied
        setSelectedFGImpacted([]);
        setFGImpactedSelectAll(false);
        setSelectedFGName([]);
        setFGNameSelectAll(false);
        setSelectedSC([]);
        setSCSelectAll(false);
        setSelectedSN([]);
        setSNSelectAll(false);
        setSelectedEPlanner([]);
        setEPlannerSelectAll(false);
        setSelectedFGSold([]);
        setFGSoldSelectAll(false);
      }
    }
  }, [tabChange]);

  const getAllFGImpacted = (data) =>
    data[activeTab]?.map((item) => item?.skuCode) || [];

  const getAllFGName = (data) =>
    data[activeTab]?.map((item) => item?.skuName) || [];

  const getAllSC = (data) =>
    data[activeTab]?.map((item) => item?.plantCode) || [];

  const getAllSN = (data) =>
    data[activeTab]?.map((item) => item?.plantName) || [];

  const getAllEPlanner = (data) =>
    data[activeTab]?.map((item) => item?.updatedSchedulername) || [];

  const getAllFGSold = (data) =>
    data[activeTab]?.map((item) => item?.country) || [];
  //logic for filter and search in dropdown
  //FG Impacted searching and sorting below
  const getFGImpactedOptions = () => {
    const allFGImpacted = Array.isArray(objCheckDelist[activeTab])
      ? objCheckDelist[activeTab]
      : [];
    const uniqueFGImpacted = Array.from(
      new Map(allFGImpacted.map((item) => [item.skuCode, item])).values()
    );

    if (!fGImpactedSearchTerm) {
      return uniqueFGImpacted;
    }

    return uniqueFGImpacted.filter((item) =>
      item.skuCode?.toLowerCase().includes(fGImpactedSearchTerm.toLowerCase())
    );
  };

  const handleSelectAllFGImpacted = (e) => {
    const isChecked = e.target.checked;
    setFGImpactedSelectAll(isChecked);

    if (isChecked) {
      const allFGImpacted = getAllFGImpacted(objCheckDelist);
      setSelectedFGImpacted(allFGImpacted);
      setSelectedFilters(allFGImpacted);
      setFilteredObjCheckDelist(objCheckDelist);
    } else {
      setSelectedFGImpacted([]);
      setSelectedFilters([]);
      setFilteredObjCheckDelist({
        ...objCheckDelist,
        [activeTab]: [],
      });
    }
  };

  const handleFGImpactedCheckboxChange = (e, skuCode) => {
    const isChecked = e.target.checked;
    const updatedFGImpacted = isChecked
      ? [...selectedFGImpacted, skuCode]
      : selectedFGImpacted.filter((name) => name !== skuCode);

    setSelectedFGImpacted(updatedFGImpacted);
    setSelectedFilters(updatedFGImpacted);
    filterDataBasedOnFGImpacted(updatedFGImpacted);

    const allFGImpacted = getAllFGImpacted(objCheckDelist);
    setFGImpactedSelectAll(
      updatedFGImpacted.length ===
        [...new Set(allFGImpacted.map((item) => item))].length
    );
  };

  const filterDataBasedOnFGImpacted = (filters) => {
    if (filters.length === 0) {
      setFilteredObjCheckDelist({
        ...objCheckDelist,
        [activeTab]: [],
      });
      return;
    }
    const filtered = objCheckDelist[activeTab]?.filter((fg) =>
      filters.includes(fg.skuCode)
    );
    setFilteredObjCheckDelist({
      ...objCheckDelist,
      [activeTab]: filtered,
    });
  };

  //FG Name searching and sorting below
  const getFGNameOptions = () => {
    const allFGName = Array.isArray(objCheckDelist[activeTab])
      ? objCheckDelist[activeTab]
      : [];
    const uniqueFGName = Array.from(
      new Map(allFGName.map((item) => [item.skuName, item])).values()
    );

    if (!fGNameSearchTerm) {
      return uniqueFGName;
    }

    return uniqueFGName.filter((item) =>
      item.skuName?.toLowerCase().includes(fGNameSearchTerm.toLowerCase())
    );
  };

  const handleSelectAllFGName = (e) => {
    const isChecked = e.target.checked;
    setFGNameSelectAll(isChecked);

    if (isChecked) {
      const allFGName = getAllFGName(objCheckDelist);
      setSelectedFGName(allFGName);
      setSelectedFilters(allFGName);
      setFilteredObjCheckDelist(objCheckDelist);
    } else {
      setSelectedFGName([]);
      setSelectedFilters([]);
      setFilteredObjCheckDelist({
        ...objCheckDelist,
        [activeTab]: [],
      });
    }
  };

  const handleFGNameCheckboxChange = (e, skuName) => {
    const isChecked = e.target.checked;
    const updatedFGName = isChecked
      ? [...selectedFGName, skuName]
      : selectedFGName.filter((name) => name !== skuName);

    setSelectedFGName(updatedFGName);
    setSelectedFilters(updatedFGName);
    filterDataBasedOnFGName(updatedFGName);

    const allFGName = getAllFGName(objCheckDelist);
    setFGNameSelectAll(
      updatedFGName.length ===
        [...new Set(allFGName.map((item) => item))].length
    );
  };

  const filterDataBasedOnFGName = (filters) => {
    if (filters.length === 0) {
      setFilteredObjCheckDelist({
        ...objCheckDelist,
        [activeTab]: [],
      });
      return;
    }
    const filtered = objCheckDelist[activeTab]?.filter((fg) =>
      filters.includes(fg.skuName)
    );
    setFilteredObjCheckDelist({
      ...objCheckDelist,
      [activeTab]: filtered,
    });
  };

  //Supplier Code searching and sorting below
  const getSCOptions = () => {
    const allSC = Array.isArray(objCheckDelist[activeTab])
      ? objCheckDelist[activeTab]
      : [];
    const uniqueSC = Array.from(
      new Map(allSC.map((item) => [item.plantCode, item])).values()
    );

    if (!sCSearchTerm) {
      return uniqueSC;
    }

    return uniqueSC.filter((item) =>
      item.plantCode?.toLowerCase().includes(sCSearchTerm.toLowerCase())
    );
  };

  const handleSelectAllSC = (e) => {
    const isChecked = e.target.checked;
    setSCSelectAll(isChecked);

    if (isChecked) {
      const allSC = getAllSC(objCheckDelist);
      setSelectedSC(allSC);
      setSelectedFilters(allSC);
      setFilteredObjCheckDelist(objCheckDelist);
    } else {
      setSelectedSC([]);
      setSelectedFilters([]);
      setFilteredObjCheckDelist({
        ...objCheckDelist,
        [activeTab]: [],
      });
    }
  };

  const handleSCCheckboxChange = (e, plantCode) => {
    const isChecked = e.target.checked;
    const updatedSC = isChecked
      ? [...selectedSC, plantCode]
      : selectedSC.filter((name) => name !== plantCode);

    setSelectedSC(updatedSC);
    setSelectedFilters(updatedSC);
    filterDataBasedOnSC(updatedSC);

    const allSC = getAllSC(objCheckDelist);
    setSCSelectAll(
      updatedSC.length === [...new Set(allSC.map((item) => item))].length
    );
  };

  const filterDataBasedOnSC = (filters) => {
    if (filters.length === 0) {
      setFilteredObjCheckDelist({
        ...objCheckDelist,
        [activeTab]: [],
      });
      return;
    }
    const filtered = objCheckDelist[activeTab]?.filter((fg) =>
      filters.includes(fg.plantCode)
    );
    setFilteredObjCheckDelist({
      ...objCheckDelist,
      [activeTab]: filtered,
    });
  };

  //FG Name searching and sorting below
  const getSNOptions = () => {
    const allSN = Array.isArray(objCheckDelist[activeTab])
      ? objCheckDelist[activeTab]
      : [];
    const uniqueSN = Array.from(
      new Map(allSN.map((item) => [item.plantName, item])).values()
    );

    if (!sNSearchTerm) {
      return uniqueSN;
    }

    return uniqueSN.filter((item) =>
      item.plantName?.toLowerCase().includes(sNSearchTerm.toLowerCase())
    );
  };

  const handleSelectAllSN = (e) => {
    const isChecked = e.target.checked;
    setSNSelectAll(isChecked);

    if (isChecked) {
      const allSN = getAllSN(objCheckDelist);
      setSelectedSN(allSN);
      setSelectedFilters(allSN);
      setFilteredObjCheckDelist(objCheckDelist);
    } else {
      setSelectedSN([]);
      setSelectedFilters([]);
      setFilteredObjCheckDelist({
        ...objCheckDelist,
        [activeTab]: [],
      });
    }
  };

  const handleSNCheckboxChange = (e, plantName) => {
    const isChecked = e.target.checked;
    const updatedSN = isChecked
      ? [...selectedSN, plantName]
      : selectedSN.filter((name) => name !== plantName);

    setSelectedSN(updatedSN);
    setSelectedFilters(updatedSN);
    filterDataBasedOnSN(updatedSN);

    const allSN = getAllSN(objCheckDelist);
    setSNSelectAll(
      updatedSN.length === [...new Set(allSN.map((item) => item))].length
    );
  };

  const filterDataBasedOnSN = (filters) => {
    if (filters.length === 0) {
      setFilteredObjCheckDelist({
        ...objCheckDelist,
        [activeTab]: [],
      });
      return;
    }
    const filtered = objCheckDelist[activeTab]?.filter((fg) =>
      filters.includes(fg.plantName)
    );
    setFilteredObjCheckDelist({
      ...objCheckDelist,
      [activeTab]: filtered,
    });
  };

  //E2E Planner searching and sorting below
  const getEPlannerOptions = () => {
    const allEPlanner = Array.isArray(objCheckDelist[activeTab])
      ? objCheckDelist[activeTab]
      : [];
    const uniqueEPlanner = Array.from(
      new Map(
        allEPlanner.map((item) => [item.updatedSchedulername, item])
      ).values()
    );

    if (!ePlannerSearchTerm) {
      return uniqueEPlanner;
    }

    return uniqueEPlanner.filter((item) =>
      item.updatedSchedulername
        ?.toLowerCase()
        .includes(ePlannerSearchTerm.toLowerCase())
    );
  };

  const handleSelectAllEPlanner = (e) => {
    const isChecked = e.target.checked;
    setEPlannerSelectAll(isChecked);

    if (isChecked) {
      const allEPlanner = getAllEPlanner(objCheckDelist);
      setSelectedEPlanner(allEPlanner);
      setSelectedFilters(allEPlanner);
      setFilteredObjCheckDelist(objCheckDelist);
    } else {
      setSelectedEPlanner([]);
      setSelectedFilters([]);
      setFilteredObjCheckDelist({
        ...objCheckDelist,
        [activeTab]: [],
      });
    }
  };

  const handleEPlannerCheckboxChange = (e, updatedSchedulername) => {
    const isChecked = e.target.checked;
    const updatedEPlanner = isChecked
      ? [...selectedEPlanner, updatedSchedulername]
      : selectedEPlanner.filter((name) => name !== updatedSchedulername);

    setSelectedEPlanner(updatedEPlanner);
    setSelectedFilters(updatedEPlanner);
    filterDataBasedOnEPlanner(updatedEPlanner);

    const allEPlanner = getAllEPlanner(objCheckDelist);
    setEPlannerSelectAll(
      updatedEPlanner.length ===
        [...new Set(allEPlanner.map((item) => item))].length
    );
  };

  const filterDataBasedOnEPlanner = (filters) => {
    if (filters.length === 0) {
      setFilteredObjCheckDelist({
        ...objCheckDelist,
        [activeTab]: [],
      });
      return;
    }
    const filtered = objCheckDelist[activeTab]?.filter((fg) =>
      filters.includes(fg.updatedSchedulername)
    );
    setFilteredObjCheckDelist({
      ...objCheckDelist,
      [activeTab]: filtered,
    });
  };

  //FG Impacted searching and sorting below
  const getFGSoldOptions = () => {
    const allFGSold = Array.isArray(objCheckDelist[activeTab])
      ? objCheckDelist[activeTab]
      : [];
    const uniqueFGSold = Array.from(
      new Map(allFGSold.map((item) => [item.country, item])).values()
    );

    if (!fGSoldSearchTerm) {
      return uniqueFGSold;
    }

    return uniqueFGSold.filter((item) =>
      item.country?.toLowerCase().includes(fGSoldSearchTerm.toLowerCase())
    );
  };

  const handleSelectAllFGSold = (e) => {
    const isChecked = e.target.checked;
    setFGSoldSelectAll(isChecked);

    if (isChecked) {
      const allFGSold = getAllFGSold(objCheckDelist);
      setSelectedFGSold(allFGSold);
      setSelectedFilters(allFGSold);
      setFilteredObjCheckDelist(objCheckDelist);
    } else {
      setSelectedFGSold([]);
      setSelectedFilters([]);
      setFilteredObjCheckDelist({
        ...objCheckDelist,
        [activeTab]: [],
      });
    }
  };

  const handleFGSoldCheckboxChange = (e, country) => {
    const isChecked = e.target.checked;
    const updatedFGSold = isChecked
      ? [...selectedFGSold, country]
      : selectedFGSold.filter((name) => name !== country);

    setSelectedFGSold(updatedFGSold);
    setSelectedFilters(updatedFGSold);
    filterDataBasedOnFGSold(updatedFGSold);

    const allFGSold = getAllFGSold(objCheckDelist);
    setFGSoldSelectAll(
      updatedFGSold.length ===
        [...new Set(allFGSold.map((item) => item))].length
    );
  };

  const filterDataBasedOnFGSold = (filters) => {
    if (filters.length === 0) {
      setFilteredObjCheckDelist({
        ...objCheckDelist,
        [activeTab]: [],
      });
      return;
    }
    const filtered = objCheckDelist[activeTab]?.filter((fg) =>
      filters.includes(fg.country)
    );
    setFilteredObjCheckDelist({
      ...objCheckDelist,
      [activeTab]: filtered,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    retrieveCheckDelists(false);
  }, []);

  useEffect(() => {
    if (activeTab !== "") {
      setSelectedFilters([]);
      setTabChange(!tabChange);
    }
  }, [activeTab]);

  const onChangeTab = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const retrieveCheckDelists = async (reset) => {
    if (reset) {
      setActiveTab("");
    }
    const response = await fetchCheckDelists(props.arrItem.projectId);
    if (response.status === 200) {
      let modifiedData = {
        ...response?.data,
        checkdelistdetails: response?.data?.checkdelistdetails.map((item) => {
          return {
            ...item,
            updatedSchedulername:
              item.updatedSchedulername !== ""
                ? item.updatedSchedulername
                : item.masterSchedulername,
          };
        }),
      };
      setObjCheckDelist(modifiedData);
      setActiveTab("checkdelistdetails");
      if (!reset) {
        setEmail(modifiedData.etoepocemail);
        setIsEditAccessEnabled(
          isEdit ||
            modifiedData.etoepocemail === userDetails.email?.toLowerCase()
            ? true
            : false
        );
      }
    } else {
      console.log("some error happened", response.data);
    }
    setIsLoading(false);
  };

  const onWarnModalOpen = (modalOpen, button) => {
    if (button === "okButton") {
      onOkBtnClicked();
    } else {
      setIsResetModalOpen(modalOpen);
    }
  };

  const onOkBtnClicked = async () => {
    const shouldUpdateWorkflow = stepStatus === "4" ? true : false;
    setIsLoading(true);
    let updateCheckDelistDetails = {};
    updateCheckDelistDetails.etoepocemail = "";
    updateCheckDelistDetails.emailMessage = "";
    updateCheckDelistDetails.appLink = window.location.href;
    updateCheckDelistDetails.piUser = "";
    updateCheckDelistDetails.projectID = objCheckDelist?.projectID;
    updateCheckDelistDetails.projectName = props.arrItem.projectName;
    updateCheckDelistDetails.projectDescription = props.arrItem.projectDesc;
    updateCheckDelistDetails.checkdelistdetails =
      objCheckDelist?.checkdelistdetails;
    updateCheckDelistDetails.impactedcheckdelistdetails =
      objCheckDelist?.impactedcheckdelistdetails;
    updateCheckDelistDetails.notimpactedcheckdelistdetails =
      objCheckDelist?.notimpactedcheckdelistdetails;
    updateCheckDelistDetails.checkdelisttemplatedetail =
      objCheckDelist?.checkdelistdetails;
    const res = await saveCheckDelistData(
      props.arrItem.projectId,
      updateCheckDelistDetails,
      shouldUpdateWorkflow
    );
    if (res.status === 200) {
      const res1 = await fetchProjectWorkFlowStatus(props.arrItem.projectId);
      if (res1.status === 200) {
        const res = {
          projectName: props.selectedProjectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(res));
      }
      // setShowModal(false);
      props.onComplete(false);
    } else {
      console.log(res.status);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    dataCheck();
  }, [message, email, objCheckDelist]);

  const emptyValueCheck = (value) => {
    if (value === null || value === "") {
      return true;
    } else {
      return false;
    }
  };

  const dataCheck = () => {
    let isObjectValueNullCheck = objCheckDelist?.checkdelistdetails?.length > 0;
    // ||
    // objCheckDelist?.impactedcheckdelistdetails?.length > 0 ||
    // objCheckDelist?.impactedcheckdelistdetails?.length > 0;

    setSendE2EEnabled(
      emptyValueCheck(email) ||
        emptyValueCheck(message) ||
        !isObjectValueNullCheck
    );
  };

  const getUserDetails = async (recepientMailId) => {
    const userDetails = await UserDetailsService.fetchUsersByMailId(
      recepientMailId
    );
    if (userDetails.status === 200) {
      setError("");
      setEmail(recepientMailId);
    } else {
      setError("The Unilever mail id does not exist");
    }
  };

  const e2ePlannerEmailHandleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleEmailValueOnEnter = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
    if (event.type === "blur") {
      if (validateUnileverMailId(event.target.value)) {
        setError("");
        setEmail(event.target.value);
        getUserDetails(event.target.value);
      } else {
        setError("Please provide valid Unilever Mail id");
      }
    }
  };

  // const handleRemove = (indexToRemove) => {
  //   const newValues = values.filter((_, index) => index !== indexToRemove);
  //   setValues(newValues);
  // };

  const handleMessageValueOnEnter = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
    if (event.type === "blur") {
      setMessage(event.target.value);
    }
  };
  const e2ePlannerHandleClick = async () => {
    let updateCheckDelistDetails = {};
    updateCheckDelistDetails.etoepocemail = email;
    updateCheckDelistDetails.emailMessage = message;
    updateCheckDelistDetails.appLink = window.location.href;
    updateCheckDelistDetails.piUser = userDetails.email;
    updateCheckDelistDetails.projectID = objCheckDelist?.projectID;
    updateCheckDelistDetails.projectName = props.arrItem.projectName;
    updateCheckDelistDetails.projectDescription = props.arrItem.projectDesc;
    updateCheckDelistDetails.checkdelistdetails =
      objCheckDelist?.checkdelistdetails;
    updateCheckDelistDetails.impactedcheckdelistdetails =
      objCheckDelist?.impactedcheckdelistdetails;
    updateCheckDelistDetails.notimpactedcheckdelistdetails =
      objCheckDelist?.notimpactedcheckdelistdetails;
    updateCheckDelistDetails.checkdelisttemplatedetail =
      objCheckDelist?.checkdelistdetails;

    const res = await SendEmailToEToEPlanner(
      props.arrItem.projectId,
      updateCheckDelistDetails
    );
    if (res.status === 200) {
      console.log("success", res.data);
    } else {
      console.log("error is send to E2Eplanner", res.data);
    }
  };

  const handleChange = (e, i, code) => {
    setIsResetDisabled(false);
    const updatedFilterCheckDelist = {
      ...filteredObjCheckDelist,
      checkdelistdetails: filteredObjCheckDelist.checkdelistdetails.map(
        (item, index) => {
          if (index === i) {
            return {
              ...item,
              updatedSchedulername: e.target.value,
            };
          }
          return item;
        }
      ),
    };
    setFilteredObjCheckDelist(updatedFilterCheckDelist);
    const updatedCheckDelist = {
      ...objCheckDelist,
      checkdelistdetails: objCheckDelist.checkdelistdetails.map(
        (item, index) => {
          if (item.skuCode === code) {
            return {
              ...item,
              updatedSchedulername: e.target.value,
            };
          }
          return item;
        }
      ),
    };
    setObjCheckDelist(updatedCheckDelist);
  };

  const handleSortChange = (event, isSortingDoneOnString, tabSelected) => {
    if (event.target.id !== null && event.target.id !== undefined) {
      const itemId = event.target.id.split("-")[1];
      if (
        filteredObjCheckDelist &&
        filteredObjCheckDelist[tabSelected].length > 0
      ) {
        setIsSortActive(true);
        changeActiveSort(itemId, isSortingDoneOnString, tabSelected);
      }
    }
  };

  const changeActiveSort = async (item, isSortingDoneOnString, tabSelected) => {
    let sortedArray = [];
    setActiveSort(item);
    if (!isSortActive) {
      sortedArray = await handleSort(
        filteredObjCheckDelist[tabSelected],
        "ascending",
        item,
        isSortingDoneOnString
      );
    } else {
      sortedArray = await handleSort(
        filteredObjCheckDelist[tabSelected],
        "descending",
        item,
        isSortingDoneOnString
      );
      setIsSortActive(!isSortActive);
    }
    setFilteredObjCheckDelist((prevObj) => ({
      ...prevObj,
      [tabSelected]: [...sortedArray],
    }));
  };

  const handleResetModalConfirm = () => {
    retrieveCheckDelists(true);
    setIsResetModalOpen(false);
  };

  const handleResetModalClose = () => {
    setIsResetModalOpen(false);
  };
  return (
    <>
      {/* {isWarnWindowOpen && (
        <ModalWarning
          show={true}
          onOk={onOkBtnClicked}
          onWarnModal={onWarnModalOpen}
        />
      )} */}
      {isResetModalOpen && (
        <ModalWarnReset
          show={true}
          onOk={handleResetModalConfirm}
          onWarnModal={handleResetModalClose}
          setIsLoading={setIsLoading}
          setIsResetDisabled={setIsResetDisabled}
        />
      )}
      <div className="bwi-modal bwi-modal-checkdelists">
        <div className="bwi-landing-modal-header bwi-modal-checkdelists-header">
          <div className="bwi-modal-checkdelists-header-title-div">
            <span className="bwi-modal-title dsf-span-ml">
              Review the factories (SU's/ Suppliers) where Finished Goods (FG)
              will be delisted or will have a BOM change
            </span>
            <br />
            <span className="bwi-modal-sub-title dsf-span-s">
              Below list is auto generated based on the FG movements between
              SUs, DCs and Countries in the last 12 months. Please review this
              to understand which SUs are going to be impacted by the Delist/BOM
              changes of FGs and Countries selected in the previous screen
              (Delist/BOM)
            </span>
          </div>
        </div>
        <div>
          <Row className=" bwi-e2e-planner-container items-end">
            <Col xs={3}>
              <div className="bwi-e2e-planner-field relative">
                <div className="bwi-e2e-planner-field-lable-info">
                  <label>Point of Contact E2E planner</label>
                  {/* <InfoTooltip
                    name="info"
                    width={"226px"}
                    height={"38px"}
                    Tooltiptext="Type email address and press Enter (max 10 users)"
                    positionClass={"tooltip-position-top-delists-email-Text"}
                  /> */}
                </div>
                <div className="flex items-center w-100">
                  <input
                    type="text"
                    name="e2ePlanner "
                    value={email}
                    disabled={!isEdit}
                    onChange={(e) => e2ePlannerEmailHandleChange(e)}
                    className={`bwi-inventorycm-textField ${
                      error?.length > 0 ? "errorBorder" : "successBorder"
                    }`}
                    onBlur={(e) => handleEmailValueOnEnter(e)}
                    onKeyDown={(e) => handleEmailValueOnEnter(e)}
                    placeholder="Enter POC E2E Planner's Email"
                  />
                </div>
                {/* {error && <span className="bwi-error-msg">{error}</span>} */}
              </div>
            </Col>
            <Col xs={6}>
              <div className="bwi-e2e-planner-field relative">
                <label>Message</label>
                <input
                  type="text"
                  name="message "
                  disabled={!isEdit}
                  className={`bwi-inventorycm-textField`}
                  onChange={(e) => handleMessageChange(e)}
                  value={message}
                  onBlur={(e) => handleMessageValueOnEnter(e)}
                  onKeyDown={(e) => handleMessageValueOnEnter(e)}
                  placeholder="Enter message for the POC E2E Planners"
                />
              </div>
            </Col>
            <Col xs={3} className="flex items-center ">
              <Button
                onClick={e2ePlannerHandleClick}
                className="bwi-inventorycm-download-btn"
                variant="secondary"
                disabled={isSendE2EEnabled || !isEdit}
              >
                Send to E2E Planner
              </Button>
            </Col>
          </Row>
        </div>
        {/* <div className="bwi-email-value-container">
          {values.length > 0 &&
            values.map((value, index) => (
              <div key={index} className="value-chip">
                <span className="value-chip-text">{value}</span>
                <div
                  // type="button"
                  className="value-chip-remove"
                  onClick={() => handleRemove(index)}
                  aria-label={`Remove ${value}`}
                  disabled={!isEdit}
                >
                  ×
                </div>
              </div>
            ))}
        </div> */}
        <div className="bwi-modal-checkDelist-body">
          <Row>
            <ul className=" nav bwi-modal-checkDelist_nav">
              <li
                className={activeTab === "checkdelistdetails" ? "active" : ""}
                onClick={() => {
                  onChangeTab("checkdelistdetails");
                  setActiveSort();
                }}
              >
                SU Impacted
              </li>
              <li
                className={
                  activeTab === "impactedcheckdelistdetails" ? "active" : ""
                }
                onClick={() => {
                  onChangeTab("impactedcheckdelistdetails");
                  setActiveSort();
                }}
              >
                Possible SU Impacted
              </li>
              <li
                className={
                  activeTab === "notimpactedcheckdelistdetails" ? "active" : ""
                }
                onClick={() => {
                  onChangeTab("notimpactedcheckdelistdetails");
                  setActiveSort();
                }}
              >
                SU Not Impacted
              </li>
            </ul>
          </Row>
          {isLoading && (
            <div className="bwi-loader-div">
              <ActivityIndicator />
            </div>
          )}
          {!isLoading &&
            filteredObjCheckDelist &&
            activeTab === "checkdelistdetails" && (
              <Row className="bwi-checkdelists-header-row bwi-checkdelists-row bwi-review-su-header">
                <div
                  style={{ width: "8%" }}
                  className="flex-fill delist-tooltip-header-div bwi-checkdelists-header-col pr-3 bwi-relative"
                >
                  <div
                    className=""
                    id="bwirpmid-skuCode"
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                    onClick={(e) =>
                      handleSortChange(e, false, "checkdelistdetails")
                    }
                  >
                    <span className="dsf-span-sl width">FG Impacted</span>
                    {activeSort === "skuCode" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "20%",
                      }}
                    >
                      <InfoTooltip
                        name="info"
                        Tooltiptext={"Will be considered in BW calculation"}
                        width={"226px"}
                        height={"38px"}
                        positionClass={"tooltip-position-bottom-right-delists"}
                      />

                      <img
                        src={polygon}
                        className={`bwi-rpm-filter-arrow ${
                          states[DROPDOWN_TYPES.FG_IMPACTED]
                            ? "bwi-arrowup"
                            : "bwi-arrowdown"
                        }`}
                        ref={refs[DROPDOWN_TYPES.FG_IMPACTED].toggle}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown(DROPDOWN_TYPES.FG_IMPACTED);
                        }}
                      />
                    </div>
                  </div>
                  {states[DROPDOWN_TYPES.FG_IMPACTED] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.FG_IMPACTED].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={fGImpactedSearchTerm}
                          onChange={(e) =>
                            setFGImpactedSearchTerm(e.target.value)
                          }
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={fGImpactedSelectAll}
                            onChange={handleSelectAllFGImpacted}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getFGImpactedOptions().map((item) => (
                          <div
                            key={item.skuCode}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedFGImpacted.includes(
                                item.skuCode
                              )}
                              onChange={(e) =>
                                handleFGImpactedCheckboxChange(e, item.skuCode)
                              }
                              id={`checkbox-${item.skuCode}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.skuCode}`}>
                              {item.skuCode}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{ width: "20%", display: "flex" }}
                  className="flex-fill bwi-checkdelists-header-col pr-3 bwi-relative"
                >
                  <div
                    id="bwirpmid-skuName"
                    onClick={(e) =>
                      handleSortChange(e, true, "checkdelistdetails")
                    }
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      width: "40%",
                    }}
                  >
                    <p className="dsf-span-sl"> FG Name</p>
                    {activeSort === "skuName" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.FG_NAME]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.FG_NAME].toggle}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(DROPDOWN_TYPES.FG_NAME);
                      }}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.FG_NAME] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.FG_NAME].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={fGNameSearchTerm}
                          onChange={(e) => setFGNameSearchTerm(e.target.value)}
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={fGNameSelectAll}
                            onChange={handleSelectAllFGName}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getFGNameOptions().map((item) => (
                          <div
                            key={item.skuName}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedFGName.includes(item.skuName)}
                              onChange={(e) =>
                                handleFGNameCheckboxChange(e, item.skuName)
                              }
                              id={`checkbox-${item.skuName}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.skuName}`}>
                              {item.skuName}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{ width: "10%", display: "flex" }}
                  className="flex-fill bwi-checkdelists-header-col pr-3 bwi-relative"
                >
                  <div
                    id="bwirpmid-plantCode"
                    onClick={(e) =>
                      handleSortChange(e, true, "checkdelistdetails")
                    }
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      width: "95%",
                    }}
                  >
                    <p className="dsf-span-sl"> SU/ supplier Code </p>
                    {activeSort === "plantCode" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.SU_SUPPLIER_CODE]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.SU_SUPPLIER_CODE].toggle}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(DROPDOWN_TYPES.SU_SUPPLIER_CODE);
                      }}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.SU_SUPPLIER_CODE] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.SU_SUPPLIER_CODE].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={sCSearchTerm}
                          onChange={(e) => setSCSearchTerm(e.target.value)}
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={sCSelectAll}
                            onChange={handleSelectAllSC}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getSCOptions().map((item) => (
                          <div
                            key={item.plantCode}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedSC.includes(item.plantCode)}
                              onChange={(e) =>
                                handleSCCheckboxChange(e, item.plantCode)
                              }
                              id={`checkbox-${item.plantCode}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.plantCode}`}>
                              {item.plantCode}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{ width: "12%", display: "flex" }}
                  className="flex-fill bwi-checkdelists-header-col pr-3 bwi-relative"
                >
                  <div
                    id="bwirpmid-plantName"
                    onClick={(e) =>
                      handleSortChange(e, true, "checkdelistdetails")
                    }
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      width: "95%",
                    }}
                  >
                    <p className="dsf-span-sl"> SU/ supplier name </p>
                    {activeSort === "plantName" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.SU_SUPPLIER_NAME]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.SU_SUPPLIER_NAME].toggle}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(DROPDOWN_TYPES.SU_SUPPLIER_NAME);
                      }}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.SU_SUPPLIER_NAME] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.SU_SUPPLIER_NAME].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={sNSearchTerm}
                          onChange={(e) => setSNSearchTerm(e.target.value)}
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={sNSelectAll}
                            onChange={handleSelectAllSN}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getSNOptions().map((item) => (
                          <div
                            key={item.plantName}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedSN.includes(item.plantName)}
                              onChange={(e) =>
                                handleSNCheckboxChange(e, item.plantName)
                              }
                              id={`checkbox-${item.plantName}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.plantName}`}>
                              {item.plantName}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{ width: "20%", display: "flex" }}
                  className="flex-fill bwi-checkdelists-header-col pr-3 bwi-relative"
                >
                  <div
                    id="bwirpmid-updatedSchedulername"
                    onClick={(e) =>
                      handleSortChange(e, "email", "checkdelistdetails")
                    }
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      width: "45%",
                    }}
                  >
                    <p className="dsf-span-sl"> E2E Planner </p>
                    {activeSort === "updatedSchedulername" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.E2E_PLANNER]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.E2E_PLANNER].toggle}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(DROPDOWN_TYPES.E2E_PLANNER);
                      }}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.E2E_PLANNER] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.E2E_PLANNER].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={ePlannerSearchTerm}
                          onChange={(e) =>
                            setEPlannerSearchTerm(e.target.value)
                          }
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={ePlannerSelectAll}
                            onChange={handleSelectAllEPlanner}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getEPlannerOptions().map((item) => (
                          <div
                            key={item.updatedSchedulername}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedEPlanner.includes(
                                item.updatedSchedulername
                              )}
                              onChange={(e) =>
                                handleEPlannerCheckboxChange(
                                  e,
                                  item.updatedSchedulername
                                )
                              }
                              id={`checkbox-${item.updatedSchedulername}`}
                              className="bwi-checkbox"
                            />
                            <label
                              for={`checkbox-${item.updatedSchedulername}`}
                            >
                              {item.updatedSchedulername}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{ width: "10%", display: "flex" }}
                  className="flex-fill bwi-checkdelists-header-col pr-3 bwi-relative"
                >
                  <div
                    id="bwirpmid-country"
                    onClick={(e) =>
                      handleSortChange(e, true, "checkdelistdetails")
                    }
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      width: "75%",
                    }}
                  >
                    <p className="dsf-span-sl"> FG Sold at </p>
                    {activeSort === "country" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.FG_SOLD_AT]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.FG_SOLD_AT].toggle}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(DROPDOWN_TYPES.FG_SOLD_AT);
                      }}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.FG_SOLD_AT] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.FG_SOLD_AT].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={fGSoldSearchTerm}
                          onChange={(e) => setFGSoldSearchTerm(e.target.value)}
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={fGSoldSelectAll}
                            onChange={handleSelectAllFGSold}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getFGSoldOptions().map((item) => (
                          <div
                            key={item.country}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedFGSold.includes(item.country)}
                              onChange={(e) =>
                                handleFGSoldCheckboxChange(e, item.country)
                              }
                              id={`checkbox-${item.country}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.country}`}>
                              {item.country}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </Row>
            )}
          {!isLoading &&
            filteredObjCheckDelist &&
            activeTab === "checkdelistdetails" &&
            filteredObjCheckDelist.checkdelistdetails &&
            filteredObjCheckDelist.checkdelistdetails.map((item, index) => {
              return (
                <>
                  <Row
                    key={item.projectID + index}
                    className="bwi-checkdelists-row bwi-checkdelists-data-row"
                  >
                    <div
                      style={{ width: "8%" }}
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                    >
                      <span className="dsf-span-s width">{item.skuCode}</span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "20%" }}
                    >
                      <span className="dsf-span-s width dsf-desc-length-txt">
                        {item.skuName}
                      </span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "10%" }}
                    >
                      <span className="dsf-span-s width">{item.plantCode}</span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "12%" }}
                    >
                      <span className="dsf-span-s width dsf-desc-length-txt">
                        {item.plantName}
                      </span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "20%" }}
                    >
                      <input
                        type="text"
                        value={item.updatedSchedulername}
                        disabled={!isEditAccessEnabled}
                        className={`dsf-textField ${
                          isEditAccessEnabled
                            ? "bwi-landing-cursor-pointer"
                            : ""
                        }`}
                        onChange={(e) => handleChange(e, index, item.skuCode)}
                        // required
                        // onBlur={(e) => handleValueOnEnter(e,i)}
                        // onKeyDown={(e) => handleValueOnEnter(e,i)}
                      />
                      {item?.updatedSchedulername?.length > 0 &&
                        item?.updatedSchedulername !==
                          item?.masterSchedulername && (
                          <div className="bwi-flag-tooltip-container">
                            <img
                              src={delistFlag}
                              alt="flag"
                              className="bwi-delist-flag"
                            />
                            <div className="bwi-flag-tooltip">
                              E2E planner different information in Data Lake
                            </div>
                          </div>
                        )}
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "10%" }}
                    >
                      <p className="dsf-span-s width dsf-desc-length-txt">
                        {item.country}
                      </p>
                    </div>
                  </Row>
                </>
              );
            })}
          {!isLoading &&
            filteredObjCheckDelist &&
            activeTab === "impactedcheckdelistdetails" && (
              <Row className="bwi-checkdelists-header-row bwi-checkdelists-row">
                <div
                  className="flex-fill delist-tooltip-header-div bwi-checkdelists-header-col bwi-relative"
                  style={{ width: "18%" }}
                >
                  <div
                    id="bwirpmid-skuCode"
                    onClick={(e) =>
                      handleSortChange(e, false, "impactedcheckdelistdetails")
                    }
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      width: "80%",
                    }}
                  >
                    <span className="dsf-span-sl width">
                      Possible FG Impacted
                    </span>
                    {activeSort === "skuCode" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        justifyContent: "space-between",
                        width: "15%",
                      }}
                    >
                      <InfoTooltip
                        name="info"
                        width={"294px"}
                        height={"102px"}
                        Tooltiptext={
                          "These SUs supply FGs to countries in the selected list and also to other countries outside the selected list (in Delist/BOM screen). This means the production in these SUs might possibly be reduced, but might not be stopped."
                        }
                        positionClass={"tooltip-position-top-right-delists"}
                      />

                      <img
                        src={polygon}
                        className={`bwi-rpm-filter-arrow ${
                          states[DROPDOWN_TYPES.FG_IMPACTED]
                            ? "bwi-arrowup"
                            : "bwi-arrowdown"
                        }`}
                        ref={refs[DROPDOWN_TYPES.FG_IMPACTED].toggle}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown(DROPDOWN_TYPES.FG_IMPACTED);
                        }}
                      />
                    </div>
                  </div>
                  {states[DROPDOWN_TYPES.FG_IMPACTED] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.FG_IMPACTED].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={fGImpactedSearchTerm}
                          onChange={(e) =>
                            setFGImpactedSearchTerm(e.target.value)
                          }
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={fGImpactedSelectAll}
                            onChange={handleSelectAllFGImpacted}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getFGImpactedOptions().map((item) => (
                          <div
                            key={item.skuCode}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedFGImpacted.includes(
                                item.skuCode
                              )}
                              onChange={(e) =>
                                handleFGImpactedCheckboxChange(e, item.skuCode)
                              }
                              id={`checkbox-${item.skuCode}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.skuCode}`}>
                              {item.skuCode}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className=" pr-3 bwi-relative"
                  style={{ width: "27%", display: "flex" }}
                >
                  <div
                    id="bwirpmid-skuName"
                    onClick={(e) =>
                      handleSortChange(e, true, "impactedcheckdelistdetails")
                    }
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      width: "40%",
                    }}
                  >
                    <span className="dsf-span-sl"> FG Name</span>
                    {activeSort === "skuName" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.FG_NAME]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.FG_NAME].toggle}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(DROPDOWN_TYPES.FG_NAME);
                      }}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.FG_NAME] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.FG_NAME].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={fGNameSearchTerm}
                          onChange={(e) => setFGNameSearchTerm(e.target.value)}
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={fGNameSelectAll}
                            onChange={handleSelectAllFGName}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getFGNameOptions().map((item) => (
                          <div
                            key={item.skuName}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedFGName.includes(item.skuName)}
                              onChange={(e) =>
                                handleFGNameCheckboxChange(e, item.skuName)
                              }
                              id={`checkbox-${item.skuName}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.skuName}`}>
                              {item.skuName}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="bwi-relative"
                  style={{ width: "15%", display: "flex" }}
                >
                  <div
                    id="bwirpmid-plantCode"
                    onClick={(e) =>
                      handleSortChange(e, true, "impactedcheckdelistdetails")
                    }
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      width: "80%",
                    }}
                  >
                    <p className="dsf-span-sl"> SU/ supplier Code </p>
                    {activeSort === "plantCode" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.SU_SUPPLIER_CODE]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.SU_SUPPLIER_CODE].toggle}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(DROPDOWN_TYPES.SU_SUPPLIER_CODE);
                      }}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.SU_SUPPLIER_CODE] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.SU_SUPPLIER_CODE].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={sCSearchTerm}
                          onChange={(e) => setSCSearchTerm(e.target.value)}
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={sCSelectAll}
                            onChange={handleSelectAllSC}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getSCOptions().map((item) => (
                          <div
                            key={item.plantCode}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedSC.includes(item.plantCode)}
                              onChange={(e) =>
                                handleSCCheckboxChange(e, item.plantCode)
                              }
                              id={`checkbox-${item.plantCode}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.plantCode}`}>
                              {item.plantCode}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <div
                  style={{ width: "20%", display: "flex" }}
                  className="pr-3 bwi-relative"
                >
                  <div
                    id="bwirpmid-plantName"
                    onClick={(e) =>
                      handleSortChange(e, true, "impactedcheckdelistdetails")
                    }
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      width: "75%",
                    }}
                  >
                    <p className="dsf-span-sl"> SU/ supplier name </p>
                    {activeSort === "plantName" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.SU_SUPPLIER_NAME]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.SU_SUPPLIER_NAME].toggle}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(DROPDOWN_TYPES.SU_SUPPLIER_NAME);
                      }}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.SU_SUPPLIER_NAME] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.SU_SUPPLIER_NAME].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={sNSearchTerm}
                          onChange={(e) => setSNSearchTerm(e.target.value)}
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={sNSelectAll}
                            onChange={handleSelectAllSN}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getSNOptions().map((item) => (
                          <div
                            key={item.plantName}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedSN.includes(item.plantName)}
                              onChange={(e) =>
                                handleSNCheckboxChange(e, item.plantName)
                              }
                              id={`checkbox-${item.plantName}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.plantName}`}>
                              {item.plantName}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{ width: "20%", display: "flex" }}
                  className="flex-fill bwi-checkdelists-header-col pr-3 bwi-relative"
                >
                  <div
                    id="bwirpmid-country"
                    onClick={(e) =>
                      handleSortChange(e, true, "impactedcheckdelistdetails")
                    }
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      width: "55%",
                    }}
                  >
                    <p className="dsf-span-sl"> FG Sold at </p>
                    {activeSort === "country" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.FG_SOLD_AT]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.FG_SOLD_AT].toggle}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(DROPDOWN_TYPES.FG_SOLD_AT);
                      }}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.FG_SOLD_AT] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.FG_SOLD_AT].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={fGSoldSearchTerm}
                          onChange={(e) => setFGSoldSearchTerm(e.target.value)}
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={fGSoldSelectAll}
                            onChange={handleSelectAllFGSold}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getFGSoldOptions().map((item) => (
                          <div
                            key={item.country}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedFGSold.includes(item.country)}
                              onChange={(e) =>
                                handleFGSoldCheckboxChange(e, item.country)
                              }
                              id={`checkbox-${item.country}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.country}`}>
                              {item.country}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </Row>
            )}
          {!isLoading &&
            filteredObjCheckDelist &&
            activeTab === "impactedcheckdelistdetails" &&
            filteredObjCheckDelist.impactedcheckdelistdetails &&
            filteredObjCheckDelist.impactedcheckdelistdetails.map(
              (item, index) => {
                return (
                  <>
                    <Row className="bwi-checkdelists-row bwi-checkdelists-data-row">
                      <div style={{ width: "18%" }} className="">
                        <span className="dsf-span-s width">{item.skuCode}</span>
                      </div>
                      <div
                        className="flex-fill bwi-checkdelists-data-col"
                        style={{ width: "27%" }}
                      >
                        <span className="dsf-span-s width dsf-desc-length-txt">
                          {item.skuName}
                        </span>
                      </div>
                      <div
                        className="flex-fill bwi-checkdelists-data-col"
                        style={{ width: "15%" }}
                      >
                        <p className="dsf-span-s width">{item.plantCode}</p>
                      </div>
                      <div
                        className="flex-fill bwi-checkdelists-data-col"
                        style={{ width: "20%" }}
                      >
                        <p className="dsf-span-s width dsf-desc-length-txt">
                          {item.plantName}
                        </p>
                      </div>
                      <div
                        className="flex-fill bwi-checkdelists-data-col"
                        style={{ width: "20%" }}
                      >
                        <p className="dsf-span-s width bw-country-value dsf-desc-length-txt">
                          {item.country}
                        </p>
                      </div>
                    </Row>
                  </>
                );
              }
            )}
          {!isLoading &&
            filteredObjCheckDelist &&
            activeTab === "notimpactedcheckdelistdetails" && (
              <Row className="bwi-checkdelists-header-row bwi-checkdelists-row">
                <div className="pr-3 bwi-relative" style={{ width: "18%" }}>
                  <div
                    id="bwirpmid-skuCode"
                    onClick={(e) =>
                      handleSortChange(
                        e,
                        false,
                        "notimpactedcheckdelistdetails"
                      )
                    }
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      width: "70%",
                    }}
                  >
                    <span className="dsf-span-sl width">Not Impacted</span>
                    {activeSort === "skuCode" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <div
                      style={{
                        display: "flex",
                        width: "15%",
                        justifyContent: "space-between",
                      }}
                    >
                      <InfoTooltip
                        name="info"
                        width={"294px"}
                        height={"82px"}
                        Tooltiptext={
                          "Based on material movement data for the past 12 months, production in these SUs will not be impacted by the renovation project."
                        }
                        positionClass={"tooltip-position-top-right-delists"}
                      />
                      <img
                        src={polygon}
                        className={`bwi-rpm-filter-arrow ${
                          states[DROPDOWN_TYPES.FG_IMPACTED]
                            ? "bwi-arrowup"
                            : "bwi-arrowdown"
                        }`}
                        ref={refs[DROPDOWN_TYPES.FG_IMPACTED].toggle}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown(DROPDOWN_TYPES.FG_IMPACTED);
                        }}
                      />
                    </div>
                  </div>
                  {states[DROPDOWN_TYPES.FG_IMPACTED] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.FG_IMPACTED].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={fGImpactedSearchTerm}
                          onChange={(e) =>
                            setFGImpactedSearchTerm(e.target.value)
                          }
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={fGImpactedSelectAll}
                            onChange={handleSelectAllFGImpacted}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getFGImpactedOptions().map((item) => (
                          <div
                            key={item.skuCode}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedFGImpacted.includes(
                                item.skuCode
                              )}
                              onChange={(e) =>
                                handleFGImpactedCheckboxChange(e, item.skuCode)
                              }
                              id={`checkbox-${item.skuCode}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.skuCode}`}>
                              {item.skuCode}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{ width: "27%", display: "flex" }}
                  className="flex-fill bwi-checkdelists-header-col pr-3 bwi-relative"
                >
                  <div
                    id="bwirpmid-skuName"
                    onClick={(e) =>
                      handleSortChange(e, true, "notimpactedcheckdelistdetails")
                    }
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      width: "40%",
                    }}
                  >
                    <p className="dsf-span-sl"> FG Name</p>
                    {activeSort === "skuName" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.FG_NAME]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.FG_NAME].toggle}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(DROPDOWN_TYPES.FG_NAME);
                      }}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.FG_NAME] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.FG_NAME].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={fGNameSearchTerm}
                          onChange={(e) => setFGNameSearchTerm(e.target.value)}
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={fGNameSelectAll}
                            onChange={handleSelectAllFGName}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getFGNameOptions().map((item) => (
                          <div
                            key={item.skuName}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedFGName.includes(item.skuName)}
                              onChange={(e) =>
                                handleFGNameCheckboxChange(e, item.skuName)
                              }
                              id={`checkbox-${item.skuName}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.skuName}`}>
                              {item.skuName}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{ width: "15%", display: "flex" }}
                  className="flex-fill bwi-checkdelists-header-col pr-3 bwi-relative"
                >
                  <div
                    id="bwirpmid-plantCode"
                    onClick={(e) =>
                      handleSortChange(e, true, "notimpactedcheckdelistdetails")
                    }
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      width: "85%",
                    }}
                  >
                    <p className="dsf-span-sl"> SU/ supplier Code </p>
                    {activeSort === "plantCode" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.SU_SUPPLIER_CODE]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.SU_SUPPLIER_CODE].toggle}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(DROPDOWN_TYPES.SU_SUPPLIER_CODE);
                      }}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.SU_SUPPLIER_CODE] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.SU_SUPPLIER_CODE].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={sCSearchTerm}
                          onChange={(e) => setSCSearchTerm(e.target.value)}
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={sCSelectAll}
                            onChange={handleSelectAllSC}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getSCOptions().map((item) => (
                          <div
                            key={item.plantCode}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedSC.includes(item.plantCode)}
                              onChange={(e) =>
                                handleSCCheckboxChange(e, item.plantCode)
                              }
                              id={`checkbox-${item.plantCode}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.plantCode}`}>
                              {item.plantCode}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{ width: "20%", display: "flex" }}
                  className="flex-fill bwi-checkdelists-header-col pr-3 bwi-relative"
                >
                  <div
                    id="bwirpmid-plantName"
                    onClick={(e) =>
                      handleSortChange(e, true, "notimpactedcheckdelistdetails")
                    }
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      width: "75%",
                    }}
                  >
                    <p className="dsf-span-sl"> SU/ supplier name </p>
                    {activeSort === "plantName" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.SU_SUPPLIER_NAME]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.SU_SUPPLIER_NAME].toggle}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(DROPDOWN_TYPES.SU_SUPPLIER_NAME);
                      }}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.SU_SUPPLIER_NAME] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.SU_SUPPLIER_NAME].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={sNSearchTerm}
                          onChange={(e) => setSNSearchTerm(e.target.value)}
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={sNSelectAll}
                            onChange={handleSelectAllSN}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getSNOptions().map((item) => (
                          <div
                            key={item.plantName}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedSN.includes(item.plantName)}
                              onChange={(e) =>
                                handleSNCheckboxChange(e, item.plantName)
                              }
                              id={`checkbox-${item.plantName}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.plantName}`}>
                              {item.plantName}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{ width: "20%", display: "flex" }}
                  className="flex-fill bwi-checkdelists-header-col pr-3 bwi-relative"
                >
                  <div
                    id="bwirpmid-country"
                    onClick={(e) =>
                      handleSortChange(e, true, "notimpactedcheckdelistdetails")
                    }
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      width: "65%",
                    }}
                  >
                    <p className="dsf-span-sl"> FG Sold at </p>
                    {activeSort === "country" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.FG_SOLD_AT]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.FG_SOLD_AT].toggle}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(DROPDOWN_TYPES.FG_SOLD_AT);
                      }}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.FG_SOLD_AT] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.FG_SOLD_AT].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={fGSoldSearchTerm}
                          onChange={(e) => setFGSoldSearchTerm(e.target.value)}
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={fGSoldSelectAll}
                            onChange={handleSelectAllFGSold}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getFGSoldOptions().map((item) => (
                          <div
                            key={item.country}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedFGSold.includes(item.country)}
                              onChange={(e) =>
                                handleFGSoldCheckboxChange(e, item.country)
                              }
                              id={`checkbox-${item.country}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.country}`}>
                              {item.country}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </Row>
            )}
          {!isLoading &&
            filteredObjCheckDelist &&
            activeTab === "notimpactedcheckdelistdetails" &&
            filteredObjCheckDelist.notimpactedcheckdelistdetails &&
            filteredObjCheckDelist.notimpactedcheckdelistdetails.map(
              (item, index) => {
                return (
                  <>
                    <Row className="bwi-checkdelists-row bwi-checkdelists-data-row">
                      <div
                        style={{ width: "18%" }}
                        className="flex-fill bwi-checkdelists-data-col"
                      >
                        <span className="dsf-span-s width">{item.skuCode}</span>
                      </div>
                      <div
                        className="flex-fill bwi-checkdelists-data-col"
                        style={{ width: "27%" }}
                      >
                        <span className="dsf-span-s width dsf-desc-length-txt">
                          {item.skuName}
                        </span>
                      </div>
                      <div
                        className="flex-fill bwi-checkdelists-data-col"
                        style={{ width: "15%" }}
                      >
                        <p className="dsf-span-s width">{item.plantCode}</p>
                      </div>
                      <div
                        className="flex-fill bwi-checkdelists-data-col"
                        style={{ width: "20%" }}
                      >
                        <p className="dsf-span-s width dsf-desc-length-txt">
                          {item.plantName}
                        </p>
                      </div>
                      <div
                        className="flex-fill bwi-checkdelists-data-col"
                        style={{ width: "20%" }}
                      >
                        <p className="dsf-span-s width bw-country-value dsf-desc-length-txt">
                          {item.country}
                        </p>
                      </div>
                    </Row>
                  </>
                );
              }
            )}
        </div>
        <div className="bwi-modal-footer ">
          <Button
            className="bwi-checkinnoflex-footer-btn bwi-reset-button"
            variant="secondary"
            onClick={() => onWarnModalOpen(true, "resetButton")}
            disabled={!isEditAccessEnabled || isResetDisabled}
          >
            Reset
          </Button>
          <Button
            disabled={!isEditAccessEnabled}
            className="bwi-checkdelists-footer-btn bwi-submit-button"
            variant="secondary"
            onClick={() => onWarnModalOpen(true, "okButton")}
          >
            Reviewed
          </Button>
        </div>
      </div>
    </>
  );
};
export default CheckDelists;
