import React, { useEffect, useState, useRef, useCallback } from "react";
import "./fgCheckModal.css";
import addList from "../../../../assets/images/addList.svg";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { fetchDelistBOMFGData } from "../../../../service/projectDetails";
import ActivityIndicator from "../../../../components/Loader/loader";
import { Checkbox } from "@mui/material";
import InnoflexDatePicker from "../../../../components/DatePicker/datePickerNew";
import { dateTimeFormat, handleSort } from "../../../../utils";
import ArrowUp from "../../../../assets/images/Arrow up sm.svg";
import ArrowDown from "../../../../assets/images/Arrow down sm.svg";
import polygon from "../../../../assets/images/Polygon-enable.svg";
import searchIcon from "../../../../assets/images/Search.svg";
// import {data} from "./data";

const FGCheckModal = (props) => {
  const [showTable, setShowTable] = useState(false);
  const [copiedData, setCopiedData] = useState("");
  const [isSelectedAll, setSelectedAll] = useState(false);
  const [fgDelistDBData, setFGDelistDBData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addFgInputDate, setAddFgInputDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [referenceValue, setReferenceValue] = useState("");
  const [activeSort, setActiveSort] = useState();
  const [isSortActive, setIsSortActive] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [countryIsOpen, setCountryIsOpen] = useState(false);
  const [countrySearchTerm, setCountrySearchTerm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [countrySelectAll, setCountrySelectAll] = useState(true);
  const [fGCodeIsOpen, setFGCodeIsOpen] = useState(false);
  const [fGCodeSearchTerm, setFGCodeSearchTerm] = useState("");
  const [selectedFGCode, setSelectedFGCode] = useState([]);
  const [fGCodeSelectAll, setFGCodeSelectAll] = useState(true);
  const [fGNameIsOpen, setFGNameIsOpen] = useState(false);
  const [fGNameSearchTerm, setFGNameSearchTerm] = useState("");
  const [selectedFGName, setSelectedFGName] = useState([]);
  const [fGNameSelectAll, setFGNameSelectAll] = useState(true);
  const [fGStatusIsOpen, setFGStatusIsOpen] = useState(false);
  const [fGStatusSearchTerm, setFGStatusSearchTerm] = useState("");
  const [selectedFGStatus, setSelectedFGStatus] = useState([]);
  const [fGStatusSelectAll, setFGStatusSelectAll] = useState(true);
  const [soldFlagIsOpen, setSoldFlagIsOpen] = useState(false);
  const [soldFlagSearchTerm, setSoldFlagSearchTerm] = useState("");
  const [selectedSoldFlag, setSelectedSoldFlag] = useState([]);
  const [soldFlagSelectAll, setSoldFlagSelectAll] = useState(true);
  const [fGValidIsOpen, setFGValidIsOpen] = useState(false);
  const [fGValidSearchTerm, setFGValidSearchTerm] = useState("");
  const [selectedFGValid, setSelectedFGValid] = useState([]);
  const [fGValidSelectAll, setFGValidSelectAll] = useState(true);
  const [originalFgDelistDBData, setOriginalFgDelistDBData] =
    useState(fgDelistDBData);
  const [filteredFgDelistDBData, setFilteredFgDelistDBData] =
    useState(fgDelistDBData);

  const {
    action,
    isEdit,
    handleSelectedFGData,
    isReset,
    handleResetSearchEnable,
  } = props;
  const isDataLoaded = useRef(false);
  //Click outside left Box
  //Define dropdown configurations
  const DROPDOWN_TYPES = {
    COUNTRY: "country",
    FG: "fg",
    FG_NAME: "fgName",
    FG_STATUS: "fgStatus",
    SOLD_FLAG: "soldFlag",
    FG_VALID: "fgValid",
  };

  const DropdownManager = () => {
    // Create a single state object for all dropdowns

    const [dropdownStates, setDropdownStates] = useState({
      [DROPDOWN_TYPES.COUNTRY]: false,
      [DROPDOWN_TYPES.FG]: false,
      [DROPDOWN_TYPES.FG_NAME]: false,
      [DROPDOWN_TYPES.FG_STATUS]: false,
      [DROPDOWN_TYPES.SOLD_FLAG]: false,
      [DROPDOWN_TYPES.FG_VALID]: false,
    });

    // Create refs object
    const refs = {
      [DROPDOWN_TYPES.COUNTRY]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
      [DROPDOWN_TYPES.FG]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
      [DROPDOWN_TYPES.FG_NAME]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
      [DROPDOWN_TYPES.FG_STATUS]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
      [DROPDOWN_TYPES.SOLD_FLAG]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
      [DROPDOWN_TYPES.FG_VALID]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
    };

    //UsEffect for Refer id
    // Single handler for all dropdowns
    const handleClickOutside = useCallback((event) => {
      Object.entries(refs).forEach(([key, { dropdown, toggle }]) => {
        if (
          dropdown.current &&
          toggle.current &&
          !dropdown.current.contains(event.target) &&
          !toggle.current.contains(event.target)
        ) {
          setDropdownStates((prev) => ({
            ...prev,
            [key]: false,
          }));
        }
      });
    }, []);

    // Single useEffect for all click outside listeners
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }, [handleClickOutside]);

    // Single toggle function for all dropdowns
    const toggleDropdown = useCallback((dropdownType) => {
      setDropdownStates((prev) => ({
        ...prev,
        [dropdownType]: !prev[dropdownType],
      }));
    }, []);

    return {
      states: dropdownStates,
      refs,
      toggleDropdown,
    };
  };
  const { states, refs, toggleDropdown } = DropdownManager();

  useEffect(() => {
    if (!selectedFilters.length > 0) {
      if (fgDelistDBData) {
        const countryOptions = fgDelistDBData.reduce((acc, item) => {
          if (item && Array.isArray(item)) {
            item.forEach((fg) => {
              if (fg?.country) {
                acc.push(fg.country);
              }
            });
          } else {
            acc.push(item?.country);
          }
          return acc;
        }, []);
        const uniqueCountryNames = Array.from(new Set(countryOptions));
        setSelectedCountry(uniqueCountryNames);
        setCountrySelectAll(true);

        const fgCodeOptions = fgDelistDBData.reduce((acc, item) => {
          if (item && Array.isArray(item)) {
            item.forEach((fg) => {
              if (fg?.fgCode) {
                acc.push(fg.fgCode);
              }
            });
          } else {
            acc.push(item?.fgCode);
          }
          return acc;
        }, []);
        const uniqueFGCode = Array.from(new Set(fgCodeOptions));
        setSelectedFGCode(uniqueFGCode);
        setFGCodeSelectAll(true);

        const fgNameOptions = fgDelistDBData.reduce((acc, item) => {
          if (item && Array.isArray(item)) {
            item.forEach((fg) => {
              if (fg?.fgName) {
                acc.push(fg.fgName);
              }
            });
          } else {
            acc.push(item?.fgName);
          }
          return acc;
        }, []);
        const uniqueFGName = Array.from(new Set(fgNameOptions));
        setSelectedFGName(uniqueFGName);
        setFGNameSelectAll(true);

        const fgStatusOptions = fgDelistDBData.reduce((acc, item) => {
          if (item && Array.isArray(item)) {
            item.forEach((fg) => {
              if (fg?.fgStatus) {
                acc.push(fg.fgStatus);
              }
            });
          } else {
            acc.push(item?.fgStatus);
          }
          return acc;
        }, []);
        const uniqueFGStatus = Array.from(new Set(fgStatusOptions));
        setSelectedFGStatus(uniqueFGStatus);
        setFGStatusSelectAll(true);

        const soldFlagOptions = fgDelistDBData.reduce((acc, item) => {
          if (item && Array.isArray(item)) {
            item.forEach((fg) => {
              if (fg?.soldFlag) {
                acc.push(fg.soldFlag);
              }
            });
          } else {
            acc.push(item?.soldFlag);
          }
          return acc;
        }, []);
        const uniqueSoldFlag = Array.from(new Set(soldFlagOptions));
        setSelectedSoldFlag(uniqueSoldFlag);
        setSoldFlagSelectAll(true);

        const fgValidOptions = fgDelistDBData.reduce((acc, item) => {
          if (item && Array.isArray(item)) {
            item.forEach((fg) => {
              if (fg?.fgValid) {
                acc.push(fg.fgValid);
              }
            });
          } else {
            acc.push(item?.fgValid);
          }
          return acc;
        }, []);
        const uniqueFGValid = Array.from(new Set(fgValidOptions));
        console.log("fgvalid",fgValidOptions,uniqueFGValid);
        setSelectedFGValid(uniqueFGValid);
        setFGValidSelectAll(true);
        setOriginalFgDelistDBData(fgDelistDBData);
        setFilteredFgDelistDBData(fgDelistDBData);

        // const allCountryNames = getAllCountryNames(fgDelistDBData);
        // setSelectedCountry(allCountryNames);

        // const allFGCode = getAllFGCode(fgDelistDBData);
        // setSelectedFGCode(allFGCode);

        // const allFGName = getAllFGName(fgDelistDBData);
        // setSelectedFGName(allFGName);

        // const allFGStatus = getAllFGStatus(fgDelistDBData);
        // setSelectedFGStatus(allFGStatus);

        // const allSoldFlag = getAllSoldFlag(fgDelistDBData);
        // setSelectedSoldFlag(allSoldFlag);

        // const allFGValid = getAllFGValid(fgDelistDBData);
        // setSelectedFGValid(allFGValid);

        isDataLoaded.current = true;
      } else {
        setSelectedCountry([]);
        setSelectedFGCode([]);
        setSelectedFGName([]);
        setSelectedFGStatus([]);
        setSelectedSoldFlag([]);
        setSelectedFGValid([]);
        setCountrySelectAll(false);
        setFGCodeSelectAll(false);
        setFGNameSelectAll(false);
        setFGStatusSelectAll(false);
        setSoldFlagSelectAll(false);
        setFGValidSelectAll(false);
      }
    }
  }, [fgDelistDBData]);

  const getAllCountryNames = (data) =>
    data?.flatMap((item) => item?.country) || [];
  const getAllFGCode = (data) => data?.flatMap((item) => item?.fgCode) || [];
  const getAllFGName = (data) => data?.flatMap((item) => item?.fgName) || [];
  const getAllFGStatus = (data) =>
    data?.flatMap((item) => item?.fgStatus) || [];
  const getAllSoldFlag = (data) =>
    data?.flatMap((item) => item?.soldFlag) || [];
  const getAllFGValid = (data) => data?.flatMap((item) => item?.fgValid) || [];

  useEffect(() => {
    if (isReset) {
      setShowTable(false);
      setCopiedData("");
      setFGDelistDBData([]);
      setSelectedAll(false);
      handleResetSearchEnable(false);
    }
  }, [isReset]);

  useEffect(() => {
    if (searchTerm !== "") {
      const filtered = fgDelistDBData.filter(
        (item) =>
          item.fgCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.fgName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.country.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFGDelistDBData(filtered);
    } else {
      setFGDelistDBData(filteredData);
    }
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter data based on selected filters
  const filterBasedOnCountryData = (filters) => {
    // if (filters.length === 0) {
    //   // No filters selected: show all data
    //   setFilteredFgDelistDBData(originalFgDelistDBData);
    //   return;
    // }
    const filtered = originalFgDelistDBData?.filter((fg) =>
      filters.includes(fg.country)
    );
    setFilteredFgDelistDBData(filtered);
  };

  // Handle "Select All" checkbox
  const handleCountrySelectAll = (e) => {
    const isChecked = e.target.checked;
    setCountrySelectAll(isChecked);

    if (isChecked) {
      const allCountryNames = getAllCountryNames(originalFgDelistDBData);
      setSelectedFilters(allCountryNames); // Select all filters
      setSelectedCountry(allCountryNames); // Update dropdown
      setFilteredFgDelistDBData(originalFgDelistDBData); // Show all data
    } else {
      setSelectedFilters([]); // Clear filters
      setSelectedCountry([]); // Clear dropdown
      setFilteredFgDelistDBData({
        ...originalFgDelistDBData,
        fgDelistDBData: [],
      }); // Clear main container
    }
  };

  // Handle individual checkbox changes
  const handleCountryCheckboxChange = (e, country) => {
    const isChecked = e.target.checked;
    const updatedFilters = isChecked
      ? [...selectedCountry, country]
      : selectedCountry.filter((name) => name !== country);

    setSelectedFilters(updatedFilters);
    setSelectedCountry(updatedFilters);

    // Update filtered data based on the updated filters
    filterBasedOnCountryData(updatedFilters);

    // Check if all items are selected
    const allCountryNames = getAllCountryNames(originalFgDelistDBData);
    const uniqueCountryNames = Array.from(new Set(allCountryNames));
    setCountrySelectAll(updatedFilters.length === uniqueCountryNames.length);
  };

  // Get unique options for the dropdown
  const getCountryOptions = () => {
    const allOptions = originalFgDelistDBData?.flatMap((item) => item) || [];
    const searchUniqueOptions = Array.from(
      new Map(allOptions.map((item) => [item.country, item])).values()
    );
    if (!countrySearchTerm) {
      return searchUniqueOptions;
    }
    return searchUniqueOptions.filter((item) =>
      item.country.toLowerCase().includes(countrySearchTerm.toLowerCase())
    );
  };

  // Filter data based on selected filters
  const filterBasedOnFGCodeData = (filters) => {
    // if (filters.length === 0) {
    //   // No filters selected: show all data
    //   setFilteredFgDelistDBData(originalFgDelistDBData);
    //   return;
    // }
    const filtered = originalFgDelistDBData?.filter((fg) =>
      filters.includes(fg.fgCode)
    );
    setFilteredFgDelistDBData(filtered);
  };

  // Handle "Select All" checkbox
  const handleFGCodeSelectAll = (e) => {
    const isChecked = e.target.checked;
    setFGCodeSelectAll(isChecked);

    if (isChecked) {
      const allFGCode = getAllFGCode(originalFgDelistDBData);
      setSelectedFilters(allFGCode); // Select all filters
      setSelectedFGCode(allFGCode); // Update dropdown
      setFilteredFgDelistDBData(originalFgDelistDBData); // Show all data
    } else {
      setSelectedFilters([]); // Clear filters
      setSelectedFGCode([]); // Clear dropdown
      setFilteredFgDelistDBData({
        ...originalFgDelistDBData,
        fgDelistDBData: [],
      }); // Clear main container
    }
  };

  // Handle individual checkbox changes
  const handleFGCodeCheckboxChange = (e, fgCode) => {
    const isChecked = e.target.checked;
    const updatedFilters = isChecked
      ? [...selectedFGCode, fgCode]
      : selectedFGCode.filter((name) => name !== fgCode);

    setSelectedFilters(updatedFilters);
    setSelectedFGCode(updatedFilters);

    // Update filtered data based on the updated filters
    filterBasedOnFGCodeData(updatedFilters);

    // Check if all items are selected
    const allFGCode = getAllFGCode(originalFgDelistDBData);
    const uniqueFGCode = Array.from(new Set(allFGCode));
    setFGCodeSelectAll(updatedFilters.length === uniqueFGCode.length);
  };

  // Get unique options for the dropdown
  const getFGCodeOptions = () => {
    const allOptions = originalFgDelistDBData?.flatMap((item) => item) || [];
    const searchUniqueOptions = Array.from(
      new Map(allOptions.map((item) => [item.fgCode, item])).values()
    );
    if (!fGCodeSearchTerm) {
      return searchUniqueOptions;
    }
    return searchUniqueOptions.filter((item) =>
      item.fgCode.toLowerCase().includes(fGCodeSearchTerm.toLowerCase())
    );
  };

  // FG Name Filter data based on selected filters
  const filterBasedOnFGNameData = (filters) => {
    // if (filters.length === 0) {
    //   // No filters selected: show all data
    //   setFilteredFgDelistDBData(fgDelistDBData);
    //   return;
    // }
    const filtered = originalFgDelistDBData?.filter((fg) =>
      filters.includes(fg.fgName)
    );
    setFilteredFgDelistDBData(filtered);
  };

  // Handle "Select All" checkbox
  const handleFGNameSelectAll = (e) => {
    const isChecked = e.target.checked;
    setFGNameSelectAll(isChecked);

    if (isChecked) {
      const allFGName = getAllFGName(originalFgDelistDBData);
      setSelectedFilters(allFGName); // Select all filters
      setSelectedFGName(allFGName); // Update dropdown
      setFilteredFgDelistDBData(originalFgDelistDBData); // Show all data
    } else {
      setSelectedFilters([]); // Clear filters
      setSelectedFGName([]); // Clear dropdown
      setFilteredFgDelistDBData({
        ...originalFgDelistDBData,
        fgDelistDBData: [],
      }); // Clear main container
    }
  };

  // Handle individual checkbox changes
  const handleFGNameCheckboxChange = (e, fgName) => {
    const isChecked = e.target.checked;
    const updatedFilters = isChecked
      ? [...selectedFGName, fgName]
      : selectedFGName.filter((name) => name !== fgName);

    setSelectedFilters(updatedFilters);
    setSelectedFGName(updatedFilters);

    // Update filtered data based on the updated filters
    filterBasedOnFGNameData(updatedFilters);

    // Check if all items are selected
    const allFGName = getAllFGName(originalFgDelistDBData);
    const uniqueFGName = Array.from(new Set(allFGName));
    setFGNameSelectAll(updatedFilters.length === uniqueFGName.length);
  };

  // Get unique options for the dropdown
  const getFGNameOptions = () => {
    const allOptions = originalFgDelistDBData?.flatMap((item) => item) || [];
    const searchUniqueOptions = Array.from(
      new Map(allOptions.map((item) => [item.fgName, item])).values()
    );
    if (!fGNameSearchTerm) {
      return searchUniqueOptions;
    }
    return searchUniqueOptions.filter((item) =>
      item.fgName.toLowerCase().includes(fGNameSearchTerm.toLowerCase())
    );
  };

  // FG Status Filter data based on selected filters
  const filterBasedOnFGStatusData = (filters) => {
    // if (filters.length === 0) {
    //   // No filters selected: show all data
    //   setFilteredFgDelistDBData(fgDelistDBData);
    //   return;
    // }
    const filtered = originalFgDelistDBData?.filter((fg) =>
      filters.includes(fg.fgStatus)
    );
    setFilteredFgDelistDBData(filtered);
  };

  // Handle "Select All" checkbox
  const handleFGStatusSelectAll = (e) => {
    const isChecked = e.target.checked;
    setFGStatusSelectAll(isChecked);

    if (isChecked) {
      const allFGStatus = getAllFGStatus(originalFgDelistDBData);
      setSelectedFilters(allFGStatus); // Select all filters
      setSelectedFGStatus(allFGStatus); // Update dropdown
      setFilteredFgDelistDBData(originalFgDelistDBData); // Show all data
    } else {
      setSelectedFilters([]); // Clear filters
      setSelectedFGStatus([]); // Clear dropdown
      setFilteredFgDelistDBData({
        ...originalFgDelistDBData,
        fgDelistDBData: [],
      }); // Clear main container
    }
  };

  // Handle individual checkbox changes
  const handleFGStatusCheckboxChange = (e, fgStatus) => {
    const isChecked = e.target.checked;
    const updatedFilters = isChecked
      ? [...selectedFGStatus, fgStatus]
      : selectedFGStatus.filter((name) => name !== fgStatus);

    setSelectedFilters(updatedFilters);
    setSelectedFGStatus(updatedFilters);

    // Update filtered data based on the updated filters
    filterBasedOnFGStatusData(updatedFilters);

    // Check if all items are selected
    const allFGStatus = getAllFGStatus(originalFgDelistDBData);
    const uniqueAllFGStatus = Array.from(new Set(allFGStatus));
    setFGStatusSelectAll(updatedFilters.length === uniqueAllFGStatus.length);
  };

  // Get unique options for the dropdown
  const getFGStatusOptions = () => {
    const allOptions = originalFgDelistDBData?.flatMap((item) => item) || [];
    const searchUniqueOptions = Array.from(
      new Map(allOptions.map((item) => [item.fgStatus, item])).values()
    );
    if (!fGStatusSearchTerm) {
      return searchUniqueOptions;
    }
    return searchUniqueOptions.filter((item) =>
      item.fgStatus.toLowerCase().includes(fGStatusSearchTerm.toLowerCase())
    );
  };

  // sold flag Filter data based on selected filters
  const filterBasedOnSoldFlagData = (filters) => {
    // if (filters.length === 0) {
    //   // No filters selected: show all data
    //   setFilteredFgDelistDBData(fgDelistDBData);
    //   return;
    // }
    const filtered = originalFgDelistDBData?.filter((fg) =>
      filters.includes(fg.soldFlag)
    );
    setFilteredFgDelistDBData(filtered);
  };

  // Handle "Select All" checkbox
  const handleSoldFlagSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSoldFlagSelectAll(isChecked);

    if (isChecked) {
      const allSoldFlag = getAllSoldFlag(originalFgDelistDBData);
      setSelectedFilters(allSoldFlag); // Select all filters
      setSelectedSoldFlag(allSoldFlag); // Update dropdown
      setFilteredFgDelistDBData(originalFgDelistDBData); // Show all data
    } else {
      setSelectedFilters([]); // Clear filters
      setSelectedSoldFlag([]); // Clear dropdown
      setFilteredFgDelistDBData({
        ...originalFgDelistDBData,
        fgDelistDBData: [],
      }); // Clear main container
    }
  };

  // Handle individual checkbox changes
  const handleSoldFlagCheckboxChange = (e, soldFlag) => {
    const isChecked = e.target.checked;
    const updatedFilters = isChecked
      ? [...selectedSoldFlag, soldFlag]
      : selectedSoldFlag.filter((name) => name !== soldFlag);

    setSelectedFilters(updatedFilters);
    setSelectedSoldFlag(updatedFilters);

    // Update filtered data based on the updated filters
    filterBasedOnSoldFlagData(updatedFilters);

    // Check if all items are selected
    const allSoldFlag = getAllSoldFlag(originalFgDelistDBData);
    const uniqueAllSoldFlag= Array.from(new Set(allSoldFlag));
    setSoldFlagSelectAll(updatedFilters.length === uniqueAllSoldFlag.length);
  };

  // Get unique options for the dropdown
  const getSoldFlagOptions = () => {
    const allOptions = originalFgDelistDBData?.flatMap((item) => item) || [];
    const searchUniqueOptions = Array.from(
      new Map(allOptions.map((item) => [item.soldFlag, item])).values()
    );
    if (!soldFlagSearchTerm) {
      return searchUniqueOptions;
    }
    return searchUniqueOptions.filter((item) =>
      item.soldFlag.toLowerCase().includes(soldFlagSearchTerm.toLowerCase())
    );
  };

  // Filter data based on selected filters
  const filterBasedOnFGValidData = (filters) => {
    // if (filters.length === 0) {
    //   // No filters selected: show all data
    //   setFilteredFgDelistDBData(originalFgDelistDBData);
    //   return;
    // }
    const filtered = originalFgDelistDBData?.filter((fg) =>
      filters.includes(fg.fgValid)
    );
    setFilteredFgDelistDBData(filtered);
  };

  // Handle "Select All" checkbox
  const handleFGValidSelectAll = (e) => {
    const isChecked = e.target.checked;
    setFGValidSelectAll(isChecked);

    if (isChecked) {
      const allFGValid = getAllFGValid(originalFgDelistDBData);
      setSelectedFilters(allFGValid); // Select all filters
      setSelectedFGValid(allFGValid); // Update dropdown
      setFilteredFgDelistDBData(originalFgDelistDBData); // Show all data
    } else {
      setSelectedFilters([]); // Clear filters
      setSelectedFGValid([]); // Clear dropdown
      setFilteredFgDelistDBData({
        ...originalFgDelistDBData,
        fgDelistDBData: [],
      }); // Clear main container
    }
  };

  // Handle individual checkbox changes
  const handleFGValidCheckboxChange = (e, fgValid) => {
    const isChecked = e.target.checked;
    const updatedFilters = isChecked
      ? [...selectedFGValid, fgValid]
      : selectedFGValid.filter((name) => name !== fgValid);

    setSelectedFilters(updatedFilters);
    setSelectedFGValid(updatedFilters);

    // Update filtered data based on the updated filters
    filterBasedOnFGValidData(updatedFilters);

    // Check if all items are selected
    const allFGValid = getAllFGValid(originalFgDelistDBData);
    const uniqueAllFGValid = Array.from(new Set(allFGValid));
    setFGValidSelectAll(updatedFilters.length === uniqueAllFGValid.length);
  };

  // Get unique options for the dropdown
  const getFGValidOptions = () => {
    const allOptions = originalFgDelistDBData?.flatMap((item) => item) || [];
    const searchUniqueOptions = Array.from(
      new Map(allOptions.map((item) => [item.fgValid, item])).values()
    );
    if (!fGValidSearchTerm) {
      return searchUniqueOptions;
    }
    return searchUniqueOptions.filter((item) =>
      item.fgValid.toLowerCase().includes(fGValidSearchTerm.toLowerCase())
    );
  };

  const okButtonClicked = () => {
    const result = [];

    fgDelistDBData
      .filter((item) => item.isSelected)
      .forEach((item) => {
        result.push({
          skuCode: item.fgCode,
          skuName: item.fgName,
          country: item.country,
          action: action,
          isSelected: item.isSelected,
          launchDate: addFgInputDate,
          reference: referenceValue,
        });
      });
    handleSelectedFGData(result);
    setAddFgInputDate("");
    setReferenceValue("");
  };

  const getDelistBOMFGCheckData = async (data) => {
    let res1 = await fetchDelistBOMFGData(props.projectId, data);
    if (res1.status === 200) {
      setFGDelistDBData(res1.data);
      setFilteredData(res1.data);
    }
    setIsLoading(false);
    handleResetSearchEnable(true);
  };

  const handleDataCheck = () => {
    const parsedData = parseCopiedData(copiedData);
    setIsLoading(true);
    getDelistBOMFGCheckData(parsedData);
    setShowTable(true);
  };

  const parseCopiedData = (data) => {
    const rows = data.trim().split("\n");
    const firstColumnValues = rows.map((row) => {
      const columns = row.split("\t");
      const firstColumnValue = columns[0];
      return firstColumnValue;
    });
    return firstColumnValues;
  };
  //capture the first column value and store it in a list of array object

  const onCheckBoxAllClicked = (e) => {
    setSelectedAll(!isSelectedAll);
    const updatedData = [...fgDelistDBData];
    if (!isSelectedAll) {
      updatedData.forEach((item) => {
        if (item.fgValid.split("")[1] !== "N") {
          item.isSelected = true;
        }
      });
    } else {
      updatedData.forEach((item) => (item.isSelected = false));
    }
    setFGDelistDBData(updatedData);
  };

  const onCheckBoxClicked = (e, index) => {
    const updatedData = [...fgDelistDBData];
    const item = updatedData[index];
    if (item && item.fgValid.split("")[1] !== "N") {
      item.isSelected = !item.isSelected;
      setFGDelistDBData(updatedData);
    }
    if (
      updatedData.filter(
        (item) => !item.isSelected && item.fgValid.split("")[1] === "Y"
      ).length
    ) {
      setSelectedAll(false);
    } else {
      setSelectedAll(true);
    }
  };

  const handleAddDatePickerChange = (
    value,
    isNull,
    objInnoflexCountryItem,
    date,
    index
  ) => {
    setAddFgInputDate(dateTimeFormat(date, "MM/DD/YYYY"));
  };

  const handleSortChange = (event, isSortingDoneOnString) => {
    const itemId = event.target.id.split("-")[1];
    if (filteredFgDelistDBData && filteredFgDelistDBData?.length > 0) {
      setIsSortActive(true);
      changeActiveSort(itemId, isSortingDoneOnString);
    }
  };

  const changeActiveSort = async (item, isSortingDoneOnString) => {
    let sortedArray = [];
    setActiveSort(item);
    if (!isSortActive) {
      sortedArray = await handleSort(
        filteredFgDelistDBData,
        "ascending",
        item,
        isSortingDoneOnString
      );
    } else {
      sortedArray = await handleSort(
        filteredFgDelistDBData,
        "descending",
        item,
        isSortingDoneOnString
      );
      setIsSortActive(!isSortActive);
    }
    setFilteredFgDelistDBData(sortedArray);
  };

  return (
    <>
      <div className="bwi-delistBOMfg-modal">
        <div className="bwi-delistBOMfg-table">
          {showTable ? (
            <div className="bwi-delistBOMfg-table-body">
              <Row className="bwi-delistBOMfg-table-header bwi-delistBOMfg-row-not-selected">
                <div
                  className="bwi-delistBOMfg-table-col"
                  style={{ width: "5%" }}
                >
                  <Checkbox
                    checked={isSelectedAll}
                    id={`default-checkbox`}
                    disabled={fgDelistDBData.every(
                      (item) => item.fgValid.split("")[1] === "N"
                    )}
                    onChange={(e) => onCheckBoxAllClicked(e)}
                    className="bwi-checkbox"
                    disableRipple
                  />
                </div>
                <div style={{ width: "15%" }} className="bwi-relative">
                  <div
                    id="bwirpmid-country"
                    style={{
                      display: "flex",
                      paddingRight: "10px",
                      justifyContent: "space-between",
                    }}
                    className="bwi-delistBOMfg-table-col dsf-text-left"
                    onClick={(e) => handleSortChange(e, true)}
                  >
                    <p className="dsf-span-sl">Country</p>
                    {activeSort === "country" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-rpm-filter-arrow"
                        style={{ width: "15px" }}
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.COUNTRY]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.COUNTRY].toggle}
                      onClick={() => toggleDropdown(DROPDOWN_TYPES.COUNTRY)}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.COUNTRY] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.COUNTRY].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={countrySearchTerm}
                          onChange={(e) => setCountrySearchTerm(e.target.value)}
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={countrySelectAll}
                            onChange={handleCountrySelectAll}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getCountryOptions().map((item) => (
                          <div
                            key={item.country}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedCountry.includes(item.country)}
                              onChange={(e) =>
                                handleCountryCheckboxChange(e, item.country)
                              }
                              id={`checkbox-${item.country}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.country}`}>
                              {item.country}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ width: "16%" }} className="bwi-relative">
                  <div
                    id="bwirpmid-fgCode"
                    onClick={(e) => handleSortChange(e, false)}
                    style={{
                      display: "flex",
                      paddingRight: "10px",
                      justifyContent: "space-between",
                    }}
                    className="bwi-delistBOMfg-table-col dsf-text-left"
                  >
                    <p className="dsf-span-sl"> FG Code</p>
                    {activeSort === "fgCode" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                        style={{ width: "15px" }}
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.FG]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.FG].toggle}
                      onClick={() => toggleDropdown(DROPDOWN_TYPES.FG)}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.FG] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.FG].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={fGCodeSearchTerm}
                          onChange={(e) => setFGCodeSearchTerm(e.target.value)}
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={fGCodeSelectAll}
                            onChange={handleFGCodeSelectAll}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getFGCodeOptions().map((item) => (
                          <div
                            key={item.fgCode}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedFGCode.includes(item.fgCode)}
                              onChange={(e) =>
                                handleFGCodeCheckboxChange(e, item.fgCode)
                              }
                              id={`checkbox-${item.fgCode}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.fgCode}`}>
                              {item.fgCode}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ width: "17%" }} className="bwi-relative">
                  <div
                    id="bwirpmid-fgName"
                    onClick={(e) => handleSortChange(e, true)}
                    style={{
                      display: "flex",
                      paddingRight: "10px",
                      justifyContent: "space-between",
                    }}
                    className="bwi-delistBOMfg-table-col dsf-text-left"
                  >
                    <p className="dsf-span-sl"> FG Name</p>
                    {activeSort === "fgName" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.FG_NAME]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.FG_NAME].toggle}
                      onClick={() => toggleDropdown(DROPDOWN_TYPES.FG_NAME)}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.FG_NAME] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.FG_NAME].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={fGNameSearchTerm}
                          onChange={(e) => setFGNameSearchTerm(e.target.value)}
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={fGNameSelectAll}
                            onChange={handleFGNameSelectAll}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getFGNameOptions().map((item) => (
                          <div
                            key={item.fgName}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedFGName.includes(item.fgName)}
                              onChange={(e) =>
                                handleFGNameCheckboxChange(e, item.fgName)
                              }
                              id={`checkbox-${item.fgName}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.fgName}`}>
                              {item.fgName}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ width: "16%" }} className="bwi-relative">
                  <div
                    id="bwirpmid-fgStatus"
                    className="dsf-desc-length-txt dsf-span-ss"
                    onClick={(e) => handleSortChange(e, false)}
                    style={{
                      display: "flex",
                      paddingRight: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    FG Status
                    {activeSort === "fgStatus" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.FG_STATUS]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.FG_STATUS].toggle}
                      onClick={() => toggleDropdown(DROPDOWN_TYPES.FG_STATUS)}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.FG_STATUS] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.FG_STATUS].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={fGStatusSearchTerm}
                          onChange={(e) =>
                            setFGStatusSearchTerm(e.target.value)
                          }
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={fGStatusSelectAll}
                            onChange={handleFGStatusSelectAll}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getFGStatusOptions().map((item) => (
                          <div
                            key={item.fgStatus}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedFGStatus.includes(item.fgStatus)}
                              onChange={(e) =>
                                handleFGStatusCheckboxChange(e, item.fgStatus)
                              }
                              id={`checkbox-${item.fgStatus}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.fgStatus}`}>
                              {item.fgStatus}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ width: "16%" }} className="bwi-relative">
                  <div
                    id="bwirpmid-soldFlag"
                    className="dsf-desc-length-txt dsf-span-ss"
                    onClick={(e) => handleSortChange(e, true)}
                    style={{
                      display: "flex",
                      paddingRight: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    Sold Flag
                    {activeSort === "soldFlag" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.SOLD_FLAG]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.SOLD_FLAG].toggle}
                      onClick={() => toggleDropdown(DROPDOWN_TYPES.SOLD_FLAG)}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.SOLD_FLAG] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.SOLD_FLAG].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={soldFlagSearchTerm}
                          onChange={(e) =>
                            setSoldFlagSearchTerm(e.target.value)
                          }
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={soldFlagSelectAll}
                            onChange={handleSoldFlagSelectAll}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getSoldFlagOptions().map((item) => (
                          <div
                            key={item.soldFlag}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedSoldFlag.includes(item.soldFlag)}
                              onChange={(e) =>
                                handleSoldFlagCheckboxChange(e, item.soldFlag)
                              }
                              id={`checkbox-${item.soldFlag}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.soldFlag}`}>
                              {item.soldFlag}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ width: "15%" }} className="bwi-relative">
                  <div
                    id="bwirpmid-fgValid"
                    className="dsf-desc-length-txt dsf-span-ss"
                    onClick={(e) => handleSortChange(e, true)}
                    style={{
                      display: "flex",
                      paddingRight: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    FG Valid
                    {activeSort === "fgValid" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                    <img
                      src={polygon}
                      className={`bwi-rpm-filter-arrow ${
                        states[DROPDOWN_TYPES.FG_VALID]
                          ? "bwi-arrowup"
                          : "bwi-arrowdown"
                      }`}
                      ref={refs[DROPDOWN_TYPES.FG_VALID].toggle}
                      onClick={() => toggleDropdown(DROPDOWN_TYPES.FG_VALID)}
                    />
                  </div>
                  {states[DROPDOWN_TYPES.FG_VALID] && (
                    <div
                      className="bwi-custom-dropdown"
                      ref={refs[DROPDOWN_TYPES.FG_VALID].dropdown}
                    >
                      <div className="bwi-flex">
                        <img
                          src={searchIcon}
                          disabled={true}
                          alt="autocomplete-dropdown-icon"
                        />
                        <input
                          type="text"
                          placeholder="search"
                          value={fGValidSearchTerm}
                          onChange={(e) => setFGValidSearchTerm(e.target.value)}
                          className="bwi-dropdown-search"
                        />
                      </div>

                      <div className="bwi-filter-dropdown-options">
                        <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                          <input
                            type="checkbox"
                            checked={fGValidSelectAll}
                            onChange={handleFGValidSelectAll}
                            className="select-all-checkbox"
                            id="selectCheckbox"
                          />
                          <label for="selectCheckbox">Select all</label>
                        </div>
                        {getFGValidOptions().map((item) => (
                          <div
                            key={item.fgValid}
                            className="bwi-filter-dropdown-options-checkbox checkbox-group"
                          >
                            <input
                              type="checkbox"
                              name="childCheckbox"
                              checked={selectedFGValid.includes(item.fgValid)}
                              onChange={(e) =>
                                handleFGValidCheckboxChange(e, item.fgValid)
                              }
                              id={`checkbox-${item.fgValid}`}
                              className="bwi-checkbox"
                            />
                            <label for={`checkbox-${item.fgValid}`}>
                              {item.fgValid}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </Row>
              <div className="bwi-delistBOMfg-table-data">
                {showTable && isLoading ? (
                  <ActivityIndicator />
                ) : (
                  filteredFgDelistDBData?.length > 0 &&
                  filteredFgDelistDBData.map((rowData, index) => (
                    <Row
                      className={`bwi-delistBOMfg-table-header ${
                        rowData.fgValid.split("")[1] === "N" || !isEdit
                          ? "dsf-delistBOMfg-txt-disable"
                          : !rowData.isSelected
                          ? "bwi-delistBOMfg-row-not-selected"
                          : "dsf-delistBOMfg-txt-enable"
                      }`}
                      key={index}
                    >
                      <div
                        className={`bwi-delistBOMfg-table-col ${
                          rowData.fgValid.split("")[1] === "N"
                            ? "dsf-delistBOMfg-checkbox-disable"
                            : ""
                        }`}
                        style={{ width: "5%" }}
                      >
                        <Checkbox
                          checked={rowData.isSelected ? true : false}
                          disabled={rowData.fgValid.split("")[1] === "N"}
                          id={`default-checkbox-${index}`}
                          onChange={(e) => onCheckBoxClicked(e, index)}
                          className="bwi-checkbox"
                          disableRipple
                        />
                      </div>
                      <div
                        className="bwi-delistBOMfg-table-col dsf-text-left"
                        style={{ width: "15%" }}
                      >
                        <span className="dsf-desc-length-txt dsf-span-s">
                          {rowData.country}
                        </span>
                      </div>
                      <div
                        style={{ width: "16%" }}
                        className="bwi-delistBOMfg-table-col dsf-text-left"
                      >
                        <span className="dsf-desc-length-txt dsf-span-s">
                          {rowData.fgCode}
                        </span>
                      </div>
                      <div
                        style={{ width: "17%" }}
                        className="bwi-delistBOMfg-table-col dsf-text-left"
                      >
                        <span className="dsf-desc-length-txt dsf-span-s">
                          {rowData.fgName}
                        </span>
                      </div>
                      <div style={{ width: "16%" }}>
                        <span className="dsf-desc-length-txt dsf-span-s">
                          {rowData.fgStatus}
                        </span>
                      </div>
                      <div style={{ width: "16%" }}>
                        <span className="dsf-desc-length-txt dsf-span-s">
                          {rowData.soldFlag}
                        </span>
                      </div>
                      <div style={{ width: "14%" }}>
                        <span
                          className="dsf-desc-length-txt dsf-span-s"
                          style={{ width: "33%" }}
                        >
                          {rowData.fgValid.split("")[0]}
                        </span>
                      </div>
                    </Row>
                  ))
                )}
              </div>
            </div>
          ) : (
            <textarea
              className="dsf-delistBOMfg-body-input-textarea"
              rows="2"
              cols="50"
              placeholder="Paste the codes here"
              value={copiedData}
              onChange={(e) => setCopiedData(e.target.value)}
              disabled={!isEdit}
            ></textarea>
          )}
        </div>
        {!showTable
          ? copiedData.length > 0 && (
              <Button
                variant="secondary"
                className="bw-delistBOMfg-btn bw-delistBOMfg-btn-position bw-delistBOMfg-run-btn"
                disabled={(copiedData.length > 0 ? false : true) || !isEdit}
                onClick={handleDataCheck}
              >
                Run
              </Button>
            )
          : fgDelistDBData.length > 0 && (
              <Row className="bwi-delisBOM-FGDate-row">
                <Col xs={8} style={{ display: "flex" }}>
                  <div
                    style={{ display: "flex" }}
                    className={
                      !fgDelistDBData.some((item) => item.isSelected)
                        ? "bwi-delisbom-date"
                        : ""
                    }
                  >
                    <div>
                      <p className="bwi-delistBOM-dropdown-label dsf-span-sl dsf-font-unileverMedium bwi-delistbom-date-text">
                        Date
                      </p>
                      <div>
                        <InnoflexDatePicker
                          key={`add-date-picker-new-row-0`}
                          launchDate={addFgInputDate}
                          isValueNull={true}
                          onDatePickerChange={(value, isNull, date) =>
                            handleAddDatePickerChange(
                              value,
                              isNull,
                              [],
                              date,
                              0
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      !fgDelistDBData.some((item) => item.isSelected)
                        ? "bwi-delisbom-reference-disabled"
                        : "bwi-delisbom-reference"
                    }
                  >
                    <p className="bwi-delistBOM-dropdown-label dsf-span-sl dsf-font-unileverMedium">
                      Reference
                    </p>
                    <input
                      className="bwi-delisbom-reference-input-text"
                      type="text"
                      placeholder="Add Reference"
                      value={referenceValue}
                      onChange={(e) => setReferenceValue(e.target.value)}
                    />
                  </div>
                </Col>

                <Col
                  xs={4}
                  style={{ textAlign: " -webkit-right" }}
                  className="bwi-delistbom-add-selected-button"
                >
                  <Button
                    variant="secondary"
                    className="bw-delistBOMfg-btn bw-delistBOMfg-run-btn"
                    onClick={okButtonClicked}
                    disabled={
                      !isEdit ||
                      !addFgInputDate ||
                      !referenceValue ||
                      !fgDelistDBData.some((item) => item.isSelected)
                    }
                  >
                    {" "}
                    <img
                      src={addList}
                      alt="addList"
                      style={{ marginRight: "8px", paddingTop: "2px" }}
                    />{" "}
                    Add Selected
                  </Button>
                </Col>
              </Row>
            )}
      </div>
    </>
  );
};

export default FGCheckModal;