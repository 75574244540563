import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, Row } from "react-bootstrap";
import ActivityIndicator from "../../../../components/Loader/loader";
import searchIcon from "../../../../assets/images/Search.svg";
import { Checkbox } from "@mui/material";
import {
  fetchInnoflex,
  fetchProjectWorkFlowStatus,
  saveInnoflexData,
  resetWorkflowDetails,
} from "../../../../service/projectDetails";
import {
  errorText_checkInnoflex,
  helpText_TradeWindow,
  helpText_LaunchDate,
  launchDateValidation,
  helpText_SkipValidation,
} from "../../../../globalConstants";
import { InfoTooltip } from "../../../../components/Tooltip/tooltip";
import InnoflexDatePicker from "../../../../components/DatePicker/datePickerNew";
import {
  dateTimeFormat,
  checkNullDataByObjInArray,
  checkNullDataByObjInNestedArray,
} from "../../../../utils";
import { postUpdatedWorkFlowStatus } from "../../../../slices/projectDetailSlice";
import ModalWarning from "../modalWarning";
import actionPlus from "../../../../assets/images/actionPlus.svg";
import actionMinus from "../../../../assets/images/actionMinus.svg";
import TickMark from "../../../../assets/images/tick.svg";
import ModalWarnReset from "../../TaskflowScreens/modalWarningReset";
import "./checkInnoflex.css";
import ArrowUp from "../../../../assets/images/Arrow up sm.svg";
import ArrowDown from "../../../../assets/images/Arrow down sm.svg";
import polygon from "../../../../assets/images/Polygon-enable.svg";
import polygonClose from "../../../../assets/images/PolygonClose.svg";
import AutoCompleteFilter from "../../../../components/Autocomplete/autocompleteFilter";
import { CheckBox } from "@mui/icons-material";
import { handleSort } from "../../../../utils";

const CheckInnoflex = (props) => {
  const [showModal, setShowModal] = React.useState(props.isShow);
  const [isOkDisabled, setOkDisabled] = useState(false);
  const [isShowError, setShowError] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [innoflexData, setInnoflexData] = useState();
  const [isLaunchDateError, setLaunchDateError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isWarnWindowOpen, setIsWarnWindowOpen] = useState(false);
  const [warnWindowButton, setWarnWindowButton] = useState("");
  const [expandedRows, setExpandedRows] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  const { isEdit, stepStatus, userHasAccess } = props;
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [isResetDisabled, setIsResetDisabled] = useState(true);
  const [activeSort, setActiveSort] = useState();
  const [isSortActive, setIsSortActive] = useState(false);
  const [objReviewDates, setObjReviewDates] = useState(props.innoflexData);
  const [objFiltered, setObjFiltered] = useState(props.innoflexData);
  const [actionIsOpen, setActionIsOpen] = useState(false);
  const [referenceIsOpen, setReferenceIsOpen] = useState(false);
  const [countryIsOpen, setCountryIsOpen] = useState(false);
  const [fGIsOpen, setFGIsOpen] = useState(false);
  const [serachResults, setSearchResults] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [referenceSearchTerm, setReferenceSearchTerm] = useState("");
  const [countrySearchTerm, setCountrySearchTerm] = useState("");
  const [fGSearchTerm, setFGSearchTerm] = useState("");
  const [actionselectAll, setActionSelectAll] = useState(true);
  const [referenceSelectAll, setReferenceSelectAll] = useState(true);
  const [countrySelectAll, setCountrySelectAll] = useState(true);
  const [fGSelectAll, setFGSelectAll] = useState(true);
  const [selectedActions, setSelectedActions] = useState([]);
  const [selectedReferences, setSelectedReferences] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedFG, setSelectedFG] = useState([]);
  const [originalInnoflexData, setOriginalInnoflexData] =
    useState(innoflexData);

  const [filteredInnoflexData, setFilteredInnoflexData] =
    useState(innoflexData);
  const isDataLoaded = useRef(false);
  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const [isFgOpen, setIsFgOpen] = useState(false);
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [isReferOpen, setIsReferOpen] = useState(false);
  const dropdownCountryRef = useRef(null);
  const dropdownFgRef = useRef(null);
  const dropdownActionRef = useRef(null);
  const dropdownReferRef = useRef(null);
  const toggleCountryRef = useRef(null);
  const toggleFgRef = useRef(null);
  const toggleActionRef = useRef(null);
  const toggleReferRef = useRef(null);
  //Redux Start
  const arrProjectDetails = useSelector((state) => state.projectDetails);
  const userDetails = useSelector((state) => state.userDetails);

  const dispatch = useDispatch();
  //Redux End
  //UsEffect for Refer id
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside both the dropdown and toggle button
      if (
        dropdownCountryRef.current &&
        toggleCountryRef.current &&
        !dropdownCountryRef.current.contains(event.target) &&
        !toggleCountryRef.current.contains(event.target)
      ) {
        setIsCountryOpen(false);
      }
      if (
        dropdownFgRef.current &&
        toggleFgRef.current &&
        !dropdownFgRef.current.contains(event.target) &&
        !toggleFgRef.current.contains(event.target)
      ) {
        setIsFgOpen(false);
      }
      if (
        dropdownActionRef.current &&
        toggleActionRef.current &&
        !dropdownActionRef.current.contains(event.target) &&
        !toggleActionRef.current.contains(event.target)
      ) {
        setIsActionOpen(false);
      }
      if (
        dropdownReferRef.current &&
        toggleReferRef.current &&
        !dropdownReferRef.current.contains(event.target) &&
        !toggleReferRef.current.contains(event.target)
      ) {
        setIsReferOpen(false);
      }
    };

    // Use mousedown event for better mobile compatibility
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleCountryDropdown = () => {
    setIsCountryOpen(!isCountryOpen);
  };
  const toggleFgDropdown = () => {
    setIsFgOpen(!isFgOpen);
  };
  const toggleActionDropdown = () => {
    setIsActionOpen(!isActionOpen);
  };
  const toggleReferDropdown = () => {
    setIsReferOpen(!isReferOpen);
  };

  useEffect(() => {
    setIsLoading(true);
    getCheckInnoflexData();
  }, []);

  useEffect(() => {
    if (innoflexData) {
      dateValueCheck();

      if (!selectedFilters.length > 0) {
        setOriginalInnoflexData(innoflexData);
        setFilteredInnoflexData(innoflexData);

        const initialData = innoflexData.checkInnoflex.reduce(
          (acc, item) => {
            if (item?.fgData && Array.isArray(item.fgData)) {
              item.fgData.forEach((fg) => {
                if (fg?.actionName) acc.actionNames.push(fg.actionName);
                if (fg?.reference) acc.references.push(fg.reference);
                if (fg?.country) acc.countries.push(fg.country);
                if (fg?.fgCode) acc.fgCodes.push(fg.fgCode);
              });
            }
            return acc;
          },
          { actionNames: [], references: [], countries: [], fgCodes: [] }
        );

        // Remove duplicates
        const uniqueActionNames = Array.from(new Set(initialData.actionNames));
        const uniqueReferences = Array.from(new Set(initialData.references));
        const uniqueCountries = Array.from(new Set(initialData.countries));
        const uniqueFGCodes = Array.from(new Set(initialData.fgCodes));

        // Initialize states
        const initializeIfEmpty = (prev, uniqueItems) =>
          prev.length === 0 ? uniqueItems : prev;

        setSelectedActions((prev) => initializeIfEmpty(prev, uniqueActionNames));
        setSelectedReferences((prev) =>
          initializeIfEmpty(prev, uniqueReferences)
        );
        // setSelectedFilters((prev) => initializeIfEmpty(prev, uniqueReferences));
        setSelectedCountry((prev) => initializeIfEmpty(prev, uniqueCountries));
        setSelectedFG((prev) => initializeIfEmpty(prev, uniqueFGCodes));

        // Explicitly set "Select All" states
        setActionSelectAll(true);
        setReferenceSelectAll(true);
        setCountrySelectAll(true);
        setFGSelectAll(true);

        isDataLoaded.current = true;
      } else {
        // Reset states if no data
        setSelectedActions([]);
        setSelectedReferences([]);
        setSelectedFilters([]);
        setSelectedCountry([]);
        setSelectedFG([]);

        setActionSelectAll(false);
        setReferenceSelectAll(false);
        setCountrySelectAll(false);
        setFGSelectAll(false);
      }
    }
  }, [innoflexData]);
  const emptyValueCheck = (value) => {
    if (value === null || value === "") {
      return true;
    } else {
      return false;
    }
  };

  const dateValueCheck = () => {
    let counter = 0;
    innoflexData?.checkInnoflex?.map((item) => {
      if (!item.isValidationSkiped) {
        if (
          emptyValueCheck(item.tradeWindowStartDate) ||
          emptyValueCheck(item.tradeWindowEndDate) ||
          emptyValueCheck(item.launchDate)
        ) {
          counter++;
        }
      } else {
        if (emptyValueCheck(item.launchDate)) {
          counter++;
        }
      }
      item.fgData?.map((fgItem) => {
        if (!fgItem.isValidationSkiped) {
          if (
            emptyValueCheck(fgItem.fgTradeWindowStartDate) ||
            emptyValueCheck(fgItem.fgTradeWindowEndDate) ||
            emptyValueCheck(fgItem.launchDate)
          ) {
            counter++;
          }
        } else {
          if (emptyValueCheck(fgItem.launchDate)) {
            counter++;
          }
        }
      });
    });
    setShowError(counter && true);
  };

  const dateLimitCheck = (innoflexData) => {
    let counter = 0;
    let countData = 0;
    let updateInnoflex = Object.assign({}, innoflexData);
    updateInnoflex?.checkInnoflex?.map((item) => {
      let count = 0;
      if (
        (Date.parse(item.launchDate) >= Date.parse(item.tradeWindowStartDate) &&
          Date.parse(item.launchDate) <= Date.parse(item.tradeWindowEndDate)) ||
        item.isValidationSkiped
      ) {
        item.isDateError = false;
      } else {
        item.isDateError = true;
        counter++;
        count++;
      }

      if (item.isValidationSkiped) {
        item.isValidationError = false;
        if (item.launchDate === "") {
          count++;
        }
      } else {
        if (
          item.tradeWindowStartDate === "" ||
          item.tradeWindowEndDate === ""
        ) {
          item.isValidationError = true;
        } else {
          item.isValidationError = false;
        }
      }

      item.fgData?.map((fgItem) => {
        if (
          (Date.parse(fgItem.launchDate) >=
            Date.parse(fgItem.fgTradeWindowStartDate) &&
            Date.parse(fgItem.launchDate) <=
              Date.parse(fgItem.fgTradeWindowEndDate)) ||
          fgItem.isValidationSkiped
        ) {
          fgItem.isDateError = false;
        } else {
          fgItem.isDateError = true;
          counter++;
          count++;
        }

        if (fgItem.isValidationSkiped) {
          fgItem.isValidationError = false;
          if (fgItem.launchDate === "") {
            count++;
          }
        } else {
          if (
            fgItem.fgTradeWindowStartDate === "" ||
            fgItem.fgTradeWindowEndDate === ""
          ) {
            fgItem.isValidationError = true;
          } else {
            fgItem.isValidationError = false;
          }
        }
      });
      if (count > 0) {
        countData++;
      }
    });
    setInnoflexData(updateInnoflex);
    setLaunchDateError(counter && true);
    setErrorCount(countData);
    return counter && true;
  };

  const toggleRowExpansion = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const OkButtonDisabled = (innoflexData) => {
    const skipValidation =
      dateLimitCheck(innoflexData) ||
      !checkNullDataByObjInNestedArray(innoflexData.checkInnoflex, "fgData", [
        "launchDate",
      ]);
    setOkDisabled(skipValidation);
  };

  const getCheckInnoflexData = async () => {
    const res = await fetchInnoflex(props.arrItem.projectId);
    if (res.status === 200) {
      let innoflexData = {};
      innoflexData.projectID = res.data.projectId;
      innoflexData.projectName = res.data.projectName;
      innoflexData.checkInnoflex = [...res.data.checkInnoflex];
      innoflexData.countryList = [...res.data.countryList];
      innoflexData.targetLaunchDate = res.data.targetLaunchDate;
      let checkInnoflex = [];
      let countryArray = [];
      let isAllChecked = true;
      innoflexData.checkInnoflex.forEach((item) => {
        if (countryArray.indexOf(item.country) === -1) {
          countryArray.push(item.country);
        }
      });

      countryArray.forEach((country) => {
        let obj = {};
        obj.country = country;
        obj.fgData = [];
        innoflexData.checkInnoflex.forEach((item) => {
          if (item.country === country) {
            if (item.fgCode === "") {
              obj.launchDate = item.launchDate;
              obj.tradeWindowStartDate = item.tradeWindowStartDate;
              obj.tradeWindowEndDate = item.tradeWindowEndDate;
              obj.isValidationSkiped = item.isValidationSkiped;
              obj.isLaunchDateNull = item.isLaunchDateNull;
              obj.launchType = item.launchType;
              obj.fgCode = item.fgCode;
              obj.fgTradeWindowEndDate = item.fgTradeWindowEndDate;
              obj.fgTradeWindowStartDate = item.fgTradeWindowStartDate;
              if (!item.isValidationSkiped) {
                isAllChecked = false;
              }
            } else {
              obj.fgData.push(item);
            }
          }
        });
        checkInnoflex.push(obj);
      });
      innoflexData.checkInnoflex = checkInnoflex;
      setCheckedAll(isAllChecked);
      setInnoflexData(innoflexData);
      OkButtonDisabled(innoflexData);
    }
    setIsLoading(false);
  };

  const resetInnoflexData = async () => {
    setIsLoading(true);
    const res = await resetWorkflowDetails(
      props.arrItem.projectId,
      props.arrItem.workflow[0].workflowId
    );
    if (res.status === 200) {
      const res1 = await fetchProjectWorkFlowStatus(props.arrItem.projectId);
      if (res1.status === 200) {
        const res = {
          projectName: props.selectedProjectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(res));
        getCheckInnoflexData();
      }
      // props.onComplete(false);
    } else {
      console.log(res.status);
    }
    if (checkedAll) {
      setCheckedAll(false);
    }
    setIsLoading(false);
  };

  const onOkBtnClicked = async () => {
    const shouldUpdateWorkflow = stepStatus === "4" ? true : false;
    setIsLoading(true);
    let updateInnoflex = Object.assign({}, innoflexData);
    let newCheckInnoflex = [];
    updateInnoflex.checkInnoflex.forEach((item) => {
      let obj = {};
      obj.country = item.country;
      obj.launchDate = item.launchDate;
      obj.tradeWindowStartDate = item.tradeWindowStartDate;
      obj.tradeWindowEndDate = item.tradeWindowEndDate;
      obj.isValidationSkiped = item.isValidationSkiped;
      obj.isLaunchDateNull = item.isLaunchDateNull;
      obj.launchType = item.launchType;
      obj.fgCode = item.fgCode;
      obj.fgTradeWindowEndDate = item.fgTradeWindowEndDate;
      obj.fgTradeWindowStartDate = item.fgTradeWindowStartDate;
      newCheckInnoflex.push(obj);

      item.fgData.forEach((fgItem) => {
        if (fgItem.isNewFG) {
          fgItem.isNewFG = false;
        }
        newCheckInnoflex.push(fgItem);
      });
    });
    updateInnoflex.checkInnoflex = newCheckInnoflex;
    const res = await saveInnoflexData(updateInnoflex, shouldUpdateWorkflow);
    if (res.status === 200) {
      const res1 = await fetchProjectWorkFlowStatus(props.arrItem.projectId);
      if (res1.status === 200) {
        const res = {
          projectName: props.selectedProjectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(res));
        getCheckInnoflexData();
      }
    } else {
      console.log(res.status);
    }
    setIsLoading(false);
  };

  const onCheckBoxAllClicked = (e) => {
    let updateInnoflex = Object.assign({}, innoflexData);
    updateInnoflex.checkInnoflex.forEach((item) => {
      item.isValidationSkiped = e.target.checked;
      if (item.fgData && item.fgData.length > 0) {
        item.fgData.forEach((fgItem) => {
          fgItem.isValidationSkiped = e.target.checked;
        });
      }
    });
    setInnoflexData(updateInnoflex);
    setCheckedAll(e.target.checked);
    OkButtonDisabled(innoflexData);
  };

  const onCheckBoxClicked = (e, i, j, node) => {
    let updateInnoflex = Object.assign({}, innoflexData);
    if (node === "country") {
      updateInnoflex.checkInnoflex[i].isValidationSkiped = e.target.checked;
      if (
        updateInnoflex.checkInnoflex[i].fgData &&
        updateInnoflex.checkInnoflex[i].fgData.length > 0
      ) {
        updateInnoflex.checkInnoflex[i].fgData.forEach((fgItem) => {
          fgItem.isValidationSkiped = e.target.checked;
        });
      }
    } else {
      updateInnoflex.checkInnoflex[i].fgData[j].isValidationSkiped =
        e.target.checked;
      let isAllChecked = true;
      updateInnoflex.checkInnoflex[i].fgData.forEach((fgItem) => {
        if (!fgItem.isValidationSkiped) {
          isAllChecked = false;
        }
      });
      updateInnoflex.checkInnoflex[i].isValidationSkiped = isAllChecked;
    }
    let isAllChecked = true;
    updateInnoflex.checkInnoflex.forEach((item) => {
      if (!item.isValidationSkiped) {
        isAllChecked = false;
      }
    });
    setCheckedAll(isAllChecked);
    setInnoflexData(updateInnoflex);
    OkButtonDisabled(innoflexData);
  };

  const handleCountryDatePickerChange = (date, index, fieldName) => {
    let updateInnoflex = Object.assign({}, innoflexData);
    updateInnoflex.checkInnoflex[index][fieldName] = dateTimeFormat(
      date,
      "MM/DD/YYYY"
    );
    if (updateInnoflex.checkInnoflex[index].fgData?.length > 0) {
      if (fieldName === "tradeWindowStartDate") {
        updateInnoflex.checkInnoflex[index].fgData.forEach((fgItem) => {
          fgItem.fgTradeWindowStartDate = dateTimeFormat(date, "MM/DD/YYYY");
        });
      } else if (fieldName === "tradeWindowEndDate") {
        updateInnoflex.checkInnoflex[index].fgData.forEach((fgItem) => {
          fgItem.fgTradeWindowEndDate = dateTimeFormat(date, "MM/DD/YYYY");
        });
      } else {
        updateInnoflex.checkInnoflex[index].fgData.forEach((fgItem) => {
          fgItem[fieldName] = dateTimeFormat(date, "MM/DD/YYYY");
        });
      }
    }
    setInnoflexData(updateInnoflex);
    OkButtonDisabled(innoflexData);
  };

  const handleFGDatePickerChange = (date, i, j, fieldName) => {
    setIsResetDisabled(false);
    let updatedInnoflex = { ...innoflexData };
    updatedInnoflex.checkInnoflex[i].fgData[j][fieldName] = dateTimeFormat(
      date,
      "MM/DD/YYYY"
    );

    let maxDate = "";
    let allDatesFilled = true;
    updatedInnoflex.checkInnoflex[i].fgData.forEach((fgItem) => {
      const updatedFGDate = dateTimeFormat(fgItem[fieldName], "MM/DD/YYYY");
      if (fgItem[fieldName] === "" && !fgItem.isValidationSkiped) {
        allDatesFilled = false;
      }
      if (
        maxDate === "" ||
        dateTimeFormat(updatedFGDate, "YYYY/MM/DD") >
          dateTimeFormat(maxDate, "YYYY/MM/DD")
      ) {
        maxDate = updatedFGDate;
      }
    });
    if (allDatesFilled) {
      if (fieldName === "fgTradeWindowEndDate") {
        updatedInnoflex.checkInnoflex[i].tradeWindowEndDate = maxDate;
      } else if (fieldName === "fgTradeWindowStartDate") {
        updatedInnoflex.checkInnoflex[i].tradeWindowStartDate = maxDate;
      } else {
        updatedInnoflex.checkInnoflex[i][fieldName] = maxDate;
      }
    }

    setInnoflexData(updatedInnoflex);
    OkButtonDisabled(updatedInnoflex);
  };
  const handleSortChange = (event, isSortingDoneOnString) => {
    const itemId = event.target.id.split("-")[1];
    if (
      filteredInnoflexData?.checkInnoflex &&
      filteredInnoflexData?.checkInnoflex?.length > 0
    ) {
      setIsSortActive(true);
      changeActiveSort(itemId, isSortingDoneOnString);
    }
  };
  const changeActiveSort = async (item, isSortingDoneOnString) => {
    let sortedArray = [];
    setActiveSort(item);
    if (!isSortActive) {
      if (item === "country") {
        sortedArray = await handleSort(
          filteredInnoflexData.checkInnoflex,
          "ascending",
          item,
          isSortingDoneOnString
        );
      } else {
        sortedArray = await handleSortInner(
          filteredInnoflexData.checkInnoflex,
          "ascending",
          item,
          isSortingDoneOnString
        );
      }
    } else {
      if (item === "country") {
        sortedArray = await handleSort(
          filteredInnoflexData.checkInnoflex,
          "descending",
          item,
          isSortingDoneOnString
        );
      } else {
        sortedArray = await handleSortInner(
          filteredInnoflexData.checkInnoflex,
          "descending",
          item,
          isSortingDoneOnString
        );
      }
      setIsSortActive(!isSortActive);
    }
    setFilteredInnoflexData((prevObj) => ({
      ...prevObj,
      checkInnoflex: [...sortedArray],
    }));
  };

  const handleSortInner = async (
    arr,
    sortOrder,
    objName,
    isSortingDoneOnString
  ) => {
    const sortedData = arr.map((countryData) => {
      return {
        ...countryData,
        fgData: countryData.fgData.sort((a, b) => {
          if (isSortingDoneOnString) {
            if (sortOrder === "ascending") {
              if (a[objName] === b[objName]) {
                return 1;
              } else {
                return a[objName] > b[objName] ? 1 : -1;
              }
            } else {
              if (a[objName] === b[objName]) {
                return 1;
              } else {
                return a[objName] < b[objName] ? 1 : -1;
              }
            }
          } else {
            if (sortOrder === "ascending") {
              return a[objName] - b[objName];
            } else {
              return b[objName] - a[objName];
            }
          }
        }),
      };
    });
    return sortedData;
  };

  // Helper
  const getAllActionNames = (data) =>
    data?.checkInnoflex?.flatMap((item) =>
      item.fgData.map((fg) => fg.actionName)
    ) || [];
  const getAllReferences = (data) =>
    data?.checkInnoflex?.flatMap((item) =>
      item.fgData.map((fg) => fg.reference)
    ) || [];

  const getAllCountry = (data) =>
    data?.checkInnoflex?.flatMap((item) =>
      item.fgData.map((fg) => fg.country)
    ) || [];
  const getAllFG = (data) =>
    data?.checkInnoflex?.flatMap((item) =>
      item.fgData.map((fg) => fg.fgCode)
    ) || [];

  // Filter data based on selected filters
  const filterDataBasedOnAction = (filters) => {
    // if (filters.length === 0) {
    //   // No filters selected: show all data
    //   setFilteredInnoflexData(originalInnoflexData);
    //   return;
    // }

    const filtered = {
      ...originalInnoflexData,
      checkInnoflex: originalInnoflexData?.checkInnoflex
        ?.map((item) => ({
          ...item,
          fgData: item.fgData.filter((fg) => filters.includes(fg.actionName)), // Include only matching action names
        }))
        .filter((item) => item.fgData.length > 0), // Remove empty groups
    };
    setFilteredInnoflexData(filtered);
  };

  // Handle "Select All" checkbox
  const handleSelectAllAction = (e) => {
    const isChecked = e.target.checked;
    setActionSelectAll(isChecked);

    if (isChecked) {
      const allActionNames = getAllActionNames(originalInnoflexData);
      setSelectedFilters(allActionNames); // Select all filters
      setSelectedActions(allActionNames); // Update dropdown
      setFilteredInnoflexData(originalInnoflexData); // Show all data
    } else {
      setSelectedFilters([]); // Clear filters
      setSelectedActions([]); // Clear dropdown
      setFilteredInnoflexData({ ...originalInnoflexData, checkInnoflex: [] }); // Clear main container
    }
  };

  // Handle individual checkbox changes
  const handleActionCheckboxChange = (e, actionName) => {
    const isChecked = e.target.checked;
    const updatedAction = isChecked
      ? [...selectedActions, actionName]
      : selectedActions.filter((name) => name !== actionName);

    setSelectedFilters(updatedAction);
    setSelectedActions(updatedAction);

    // Update filtered data based on the updated filters
    filterDataBasedOnAction(updatedAction);

    // Check if all items are selected
    const allActionNames = getAllActionNames(originalInnoflexData);
    const uniqueActionNames = Array.from(new Set(allActionNames));
    setActionSelectAll(updatedAction.length === uniqueActionNames.length);
  };

  // Get unique options for the dropdown
  const getActionOptions = ()=>{
    const allAction = Array.isArray(originalInnoflexData?.checkInnoflex)
      ? originalInnoflexData.checkInnoflex.flatMap((item) =>
          Array.isArray(item.fgData) ? item.fgData : []
        )
      : [];
    const uniqueAction = Array.from(
      new Map(allAction.map((item) => [item.actionName, item])).values()
    );

    if (!searchTerm) {
      return uniqueAction;
    }

    return uniqueAction.filter((item) =>
      item.actionName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
  // const getOptions = () => {
  //   const allOptions =
  //     originalInnoflexData?.checkInnoflex?.flatMap((item) => item.fgData) || [];
  //   const searchUniqueOptions = Array.from(
  //     new Map(allOptions.map((item) => [item.actionName, item])).values()
  //   );
  //   if (!searchTerm) {
  //     return searchUniqueOptions;
  //   }
  //   return searchUniqueOptions.filter((item) =>
  //     item.actionName.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  // };


  //Refrence searching and sorting below
  const getReferenceOptions = () => {
    const allReferences = Array.isArray(originalInnoflexData?.checkInnoflex)
      ? originalInnoflexData.checkInnoflex.flatMap((item) =>
          Array.isArray(item.fgData) ? item.fgData : []
        )
      : [];
    const uniqueReferences = Array.from(
      new Map(allReferences.map((item) => [item.reference, item])).values()
    );

    if (!referenceSearchTerm) {
      return uniqueReferences;
    }

    return uniqueReferences.filter((item) =>
      item.reference?.toLowerCase().includes(referenceSearchTerm.toLowerCase())
    );
  };

  const handleSelectAllReferences = (e) => {
    const isChecked = e.target.checked;
    setReferenceSelectAll(isChecked);

    if (isChecked) {
      const allReferences = getAllReferences(originalInnoflexData);
      setSelectedReferences(allReferences);
      setSelectedFilters(allReferences);
      setFilteredInnoflexData(originalInnoflexData);
    } else {
      setSelectedReferences([]);
      setSelectedFilters([]);
      setFilteredInnoflexData({ ...originalInnoflexData, checkInnoflex: [] });
    }
  };

  const handleReferenceCheckboxChange = (e, reference) => {
    const isChecked = e.target.checked;
    const updatedReferences = isChecked
      ? [...selectedReferences, reference]
      : selectedReferences.filter((name) => name !== reference);

    setSelectedReferences(updatedReferences);
    setSelectedFilters(updatedReferences);
    filterDataBasedOnReferences(updatedReferences);

    const allReferences = getAllReferences(originalInnoflexData);
    const uniqueReferences = Array.from(new Set(allReferences));
    setReferenceSelectAll(updatedReferences.length === uniqueReferences.length);
  };

  const filterDataBasedOnReferences = (references) => {
    // if (references.length === 0) {
    //   setFilteredInnoflexData({ ...originalInnoflexData, checkInnoflex: [] });
    //   return;
    // }

    const filtered = {
      ...originalInnoflexData,
      checkInnoflex: originalInnoflexData?.checkInnoflex
        ?.map((item) => ({
          ...item,
          fgData: item.fgData.filter((fg) => references.includes(fg.reference)),
        }))
        .filter((item) => item.fgData.length > 0),
    };
    setFilteredInnoflexData(filtered);
  };

  //Refrence searching and sorting below
  const getCountryOptions = () => {
    const allCountry = Array.isArray(originalInnoflexData?.checkInnoflex)
      ? originalInnoflexData.checkInnoflex.flatMap((item) =>
          Array.isArray(item.fgData) ? item.fgData : []
        )
      : [];
    const uniqueCountry = Array.from(
      new Map(allCountry.map((item) => [item.country, item])).values()
    );

    if (!countrySearchTerm) {
      return uniqueCountry;
    }

    return uniqueCountry.filter((item) =>
      item.country?.toLowerCase().includes(countrySearchTerm.toLowerCase())
    );
  };

  const handleSelectAllCountry = (e) => {
    const isChecked = e.target.checked;
    setCountrySelectAll(isChecked);

    if (isChecked) {
      const allCountry = getAllCountry(originalInnoflexData);
      setSelectedCountry(allCountry);
      setSelectedFilters(allCountry);
      setFilteredInnoflexData(originalInnoflexData);
    } else {
      setSelectedCountry([]);
      setSelectedFilters([]);
      setFilteredInnoflexData({ ...originalInnoflexData, checkInnoflex: [] });
    }
  };

  const handleCountryCheckboxChange = (e, country) => {
    const isChecked = e.target.checked;
    const updatedCountry = isChecked
      ? [...selectedCountry, country]
      : selectedCountry.filter((name) => name !== country);

    setSelectedCountry(updatedCountry);
    setSelectedFilters(updatedCountry);
    filterDataBasedOnCountry(updatedCountry);

    const allCountry = getAllCountry(originalInnoflexData);
    const uniqueCountryNames = Array.from(new Set(allCountry));
    setCountrySelectAll(updatedCountry.length === uniqueCountryNames.length);
  };

  const filterDataBasedOnCountry = (country) => {
    // if (country.length === 0) {
    //   setFilteredInnoflexData({ ...originalInnoflexData, checkInnoflex: [] });
    //   return;
    // }

    const filtered = {
      ...originalInnoflexData,
      checkInnoflex: originalInnoflexData?.checkInnoflex
        ?.map((item) => ({
          ...item,
          fgData: item.fgData.filter((fg) => country.includes(fg.country)),
        }))
        .filter((item) => item.fgData.length > 0),
    };
    setFilteredInnoflexData(filtered);
  };

  //Refrence searching and sorting below
  const getFGOptions = () => {
    const allFG = Array.isArray(originalInnoflexData?.checkInnoflex)
      ? originalInnoflexData.checkInnoflex.flatMap((item) =>
          Array.isArray(item.fgData) ? item.fgData : []
        )
      : [];
    const uniqueFG = Array.from(
      new Map(allFG.map((item) => [item.fgCode, item])).values()
    );

    if (!fGSearchTerm) {
      return uniqueFG;
    }

    return uniqueFG.filter((item) =>
      item.fgCode?.toLowerCase().includes(fGSearchTerm.toLowerCase())
    );
  };

  const handleSelectAllFG = (e) => {
    const isChecked = e.target.checked;
    setFGSelectAll(isChecked);

    if (isChecked) {
      const allFG = getAllFG(originalInnoflexData);
      setSelectedFG(allFG);
      setSelectedFilters(allFG);
      setFilteredInnoflexData(originalInnoflexData);
    } else {
      setSelectedFG([]);
      setSelectedFilters([]);
      setFilteredInnoflexData({ ...originalInnoflexData, checkInnoflex: [] });
    }
  };

  const handleFGCheckboxChange = (e, fgCode) => {
    const isChecked = e.target.checked;
    const updatedFG = isChecked
      ? [...selectedFG, fgCode]
      : selectedFG.filter((name) => name !== fgCode);

    setSelectedFG(updatedFG);
    setSelectedFilters(updatedFG);
    filterDataBasedOnFG(updatedFG);

    const allFG = getAllFG(originalInnoflexData);
    const uniqueFGCode = Array.from(new Set(allFG));
    setFGSelectAll(updatedFG.length === uniqueFGCode.length);
  };

  const filterDataBasedOnFG = (fgCode) => {
    // if (fgCode.length === 0) {
    //   setFilteredInnoflexData({ ...originalInnoflexData, checkInnoflex: [] });
    //   return;
    // }

    const filtered = {
      ...originalInnoflexData,
      checkInnoflex: originalInnoflexData?.checkInnoflex
        ?.map((item) => ({
          ...item,
          fgData: item.fgData.filter((fg) => fgCode.includes(fg.fgCode)),
        }))
        .filter((item) => item.fgData.length > 0),
    };
    setFilteredInnoflexData(filtered);
  };

  const onWarnModalOpen = (modalOpen, button) => {
    if (button === "okButton") {
      onOkBtnClicked();
    } else {
      setIsResetModalOpen(modalOpen);
    }
  };

  const handleResetModalConfirm = () => {
    getCheckInnoflexData();
    setIsResetModalOpen(false);
  };

  const handleResetModalClose = () => {
    setIsResetModalOpen(false);
  };

  const handleWarnModalOk = () => {
    if (warnWindowButton === "okButton") {
      onOkBtnClicked();
    } else {
      resetInnoflexData();
    }
  };

  const resetInnoflexUIData = () => {
    let updateInnoflex = Object.assign({}, innoflexData);

    updateInnoflex.checkInnoflex.forEach((item) => {
      item.tradeWindowStartDate = "";
      item.tradeWindowEndDate = "";
      item.isValidationSkiped = false;
      item.fgData.forEach((fgItem) => {
        fgItem.fgTradeWindowStartDate = "";
        fgItem.fgTradeWindowEndDate = "";
        fgItem.isValidationSkiped = false;
      });
      if (checkedAll) {
        setCheckedAll(false);
      }
    });
    OkButtonDisabled(updateInnoflex);
  };

  return (
    <>
      {isWarnWindowOpen && (
        <ModalWarning
          show={true}
          onOk={handleWarnModalOk}
          onWarnModal={onWarnModalOpen}
        />
      )}
      {isResetModalOpen && (
        <ModalWarnReset
          show={true}
          onOk={handleResetModalConfirm}
          onWarnModal={handleResetModalClose}
          setIsLoading={setIsLoading}
          setIsResetDisabled={setIsResetDisabled}
        />
      )}
      <div className="bwi-modal bwi-modal-checkInnoflex">
        <div className="bwi-landing-modal-header bwi-modal-checkInnoflex-header">
          <div className="bwi-modal-checkInnoflex-header-title">
            <div>
              <span className="bwi-modal-title dsf-span-ml dsf-font-unileverMedium">
                Input Trade Window Dates for each Country and their Finished
                Goods
              </span>
              <br />
              <span className="bwi-modal-sub-title dsf-span-s">
                Please select the trading window dates for each country's & FG’s
                delist date. If you don't have the dates, you may skip this
                step. Where needed contact the marketing project lead.
              </span>
            </div>
          </div>
          {isShowError ? (
            <p className="dsf-modal-err-msg dsf-span-s">
              {errorText_checkInnoflex}
            </p>
          ) : (
            ""
          )}
          {isLaunchDateError ? (
            <p className="dsf-modal-err-msg dsf-span-s">
              {launchDateValidation}
            </p>
          ) : (
            ""
          )}
        </div>
        <div
          className={`bwi-modal-checkInnoflex-body ${
            innoflexData &&
            innoflexData.checkInnoflex &&
            innoflexData.checkInnoflex.length > 4
              ? "bwi-modal-checkInnoflex-body-content-scroll"
              : ""
          }`}
        >
          <div className="bwi-nested-innoflex-row bwi-nested-innoflex-row-header">
            <div className="bwi-nested-innoflex-flex12  bwi-relative">
              <div
                id="bwirpmid-country"
                onClick={(e) => handleSortChange(e, true)}
                className="bwi-country bwi-country-header"
              >
                <p className="dsf-span-sl">Country</p>
                {activeSort === "country" && (
                  <img
                    src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                    className="bwi-rpm-filter-arrow"
                  />
                )}
                <img
                  src={polygon}
                  className={`bwi-rpm-filter-arrow ${
                    isCountryOpen ? "bwi-arrowup" : "bwi-arrowdown"
                  }`}
                  ref={toggleCountryRef}
                  onClick={toggleCountryDropdown}
                />
              </div>
              {isCountryOpen && (
                <div className="bwi-custom-dropdown" ref={dropdownCountryRef}>
                  <div className="bwi-flex">
                    <img
                      src={searchIcon}
                      disabled={true}
                      alt="autocomplete-dropdown-icon"
                    />
                    <input
                      type="text"
                      placeholder="search"
                      value={countrySearchTerm}
                      onChange={(e) => setCountrySearchTerm(e.target.value)}
                      className="bwi-dropdown-search"
                    />
                  </div>

                  <div className="bwi-filter-dropdown-options">
                    <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                      <input
                        type="checkbox"
                        checked={countrySelectAll}
                        onChange={handleSelectAllCountry}
                        className="select-all-checkbox"
                        id="selectCheckbox"
                      />
                      <label for="selectCheckbox">Select all</label>
                    </div>
                    {getCountryOptions().map((item) => (
                      <div
                        key={item.country}
                        className="bwi-filter-dropdown-options-checkbox checkbox-group"
                      >
                        <input
                          type="checkbox"
                          name="childCheckbox"
                          checked={selectedCountry.includes(item.country)}
                          onChange={(e) =>
                            handleCountryCheckboxChange(e, item.country)
                          }
                          id={`checkbox-${item.country}`}
                          className="bwi-checkbox"
                        />
                        <label for={`checkbox-${item.country}`}>
                          {item.country}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="bwi-nested-innoflex-flex6 bwi-relative">
              <div
                id="bwirpmid-fgCode"
                onClick={(e) => handleSortChange(e, false)}
                className="bwi-fgcodes"
              >
                <p className="dsf-span-sl"> FGs</p>
                {activeSort === "fgCode" && (
                  <img
                    src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                    className="bwi-rpm-filter-arrow"
                  />
                )}
                <img
                  src={polygon}
                  className={`bwi-rpm-filter-arrow ${
                    isFgOpen ? "bwi-arrowup" : "bwi-arrowdown"
                  }`}
                  ref={toggleFgRef}
                  onClick={toggleFgDropdown}
                />
              </div>
              {isFgOpen && (
                <div className="bwi-custom-dropdown" ref={dropdownFgRef}>
                  <div className="bwi-flex">
                    <img
                      src={searchIcon}
                      disabled={true}
                      alt="autocomplete-dropdown-icon"
                    />
                    <input
                      type="text"
                      placeholder="search"
                      value={fGSearchTerm}
                      onChange={(e) => setFGSearchTerm(e.target.value)}
                      className="bwi-dropdown-search"
                    />
                  </div>

                  <div className="bwi-filter-dropdown-options">
                    <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                      <input
                        type="checkbox"
                        checked={fGSelectAll}
                        onChange={handleSelectAllFG}
                        className="select-all-checkbox"
                        id="selectCheckbox"
                      />
                      <label for="selectCheckbox">Select all</label>
                    </div>
                    {getFGOptions().map((item) => (
                      <div
                        key={item.fgCode}
                        className="bwi-filter-dropdown-options-checkbox checkbox-group"
                      >
                        <input
                          type="checkbox"
                          name="childCheckbox"
                          checked={selectedFG.includes(item.fgCode)}
                          onChange={(e) =>
                            handleFGCheckboxChange(e, item.fgCode)
                          }
                          id={`checkbox-${item.fgCode}`}
                          className="bwi-checkbox"
                        />
                        <label for={`checkbox-${item.fgCode}`}>
                          {item.fgCode}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="bwi-nested-innoflex-flex12 bwi-relative">
              <div
                id="bwirpmid-actionName"
                className="bwi-action"
                onClick={(e) => handleSortChange(e, true)}
              >
                <p className="dsf-span-sl">Action</p>

                {activeSort === "actionName" && (
                  <img
                    src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                    className="bwi-rpm-filter-arrow"
                  />
                )}
                <img
                  src={polygon}
                  className={`bwi-rpm-filter-arrow ${
                    isActionOpen ? "bwi-arrowup" : "bwi-arrowdown"
                  }`}
                  ref={toggleActionRef}
                  onClick={toggleActionDropdown}
                />
              </div>
              {isActionOpen && (
                <div className="bwi-custom-dropdown" ref={dropdownActionRef}>
                  <div className="bwi-flex">
                    <img
                      src={searchIcon}
                      disabled={true}
                      alt="autocomplete-dropdown-icon"
                    />
                    <input
                      type="text"
                      placeholder="search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="bwi-dropdown-search"
                    />
                  </div>

                  <div className="bwi-filter-dropdown-options">
                    <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                      <input
                        type="checkbox"
                        checked={actionselectAll}
                        onChange={handleSelectAllAction}
                        className="select-all-checkbox"
                        id="selectCheckbox"
                      />
                      <label for="selectCheckbox">Select all</label>
                    </div>
                    {getActionOptions().map((item) => (
                      <div
                        key={item.actionName}
                        className="bwi-filter-dropdown-options-checkbox checkbox-group"
                      >
                        <input
                          type="checkbox"
                          name="childCheckbox"
                          checked={selectedActions.includes(item.actionName)}
                          onChange={(e) =>
                            handleActionCheckboxChange(e, item.actionName)
                          }
                          id={`checkbox-${item.actionName}`}
                          className="bwi-checkbox"
                        />
                        <label for={`checkbox-${item.actionName}`}>
                          {item.actionName}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="bwi-nested-innoflex-flex12 bwi-reference-main bwi-relative">
              <div
                id="bwirpmid-reference"
                className="bwi-reference"
                onClick={(e) => handleSortChange(e, true)}
              >
                <p className="dsf-span-sl">Reference</p>
                {activeSort === "reference" && (
                  <img
                    src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                    className="bwi-rpm-filter-arrow"
                  />
                )}
                <img
                  src={polygon}
                  className={`bwi-rpm-filter-arrow ${
                    isReferOpen ? "bwi-arrowup" : "bwi-arrowdown"
                  }`}
                  ref={toggleReferRef}
                  onClick={toggleReferDropdown}
                />
              </div>
              {isReferOpen && (
                <div className="bwi-custom-dropdown" ref={dropdownReferRef}>
                  <div className="bwi-flex">
                    <img
                      src={searchIcon}
                      disabled={true}
                      alt="autocomplete-dropdown-icon"
                    />
                    <input
                      type="text"
                      placeholder="search"
                      value={referenceSearchTerm}
                      onChange={(e) => setReferenceSearchTerm(e.target.value)}
                      className="bwi-dropdown-search"
                    />
                  </div>

                  <div className="bwi-filter-dropdown-options">
                    <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                      <input
                        type="checkbox"
                        checked={referenceSelectAll}
                        onChange={handleSelectAllReferences}
                        className="select-all-checkbox"
                        id="referenceSelectCheckbox"
                      />
                      <label for="referenceSelectCheckbox">Select all</label>
                    </div>

                    {getReferenceOptions().map((item) => (
                      <div
                        key={item.reference}
                        className="bwi-filter-dropdown-options-checkbox checkbox-group"
                      >
                        <input
                          type="checkbox"
                          name="childCheckbox"
                          checked={selectedReferences.includes(item.reference)}
                          onChange={(e) =>
                            handleReferenceCheckboxChange(e, item.reference)
                          }
                          className="bwi-checkbox"
                          id={`checkbox-${item.reference}`}
                        />
                        <label for={`checkbox-${item.reference}`}>
                          {item.reference}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="bwi-nested-innoflex-flex12 bwi-nested-innoflex-flex-center">
              <p className="dsf-span-sl">Delist Date</p>
              <InfoTooltip
                name="info"
                Tooltiptext={helpText_LaunchDate}
                positionClass={"tooltip-position-top"}
              />
            </div>
            <div className="bwi-nested-innoflex-skip-validation bwi-nested-innoflex-flex8 bwi-right">
              <div>
                <Checkbox
                  id={`default-checkbox`}
                  disabled={isEdit ? false : true}
                  className="dsf-InventoryCM-checkbox"
                  checked={checkedAll}
                  onClick={onCheckBoxAllClicked}
                  disableRipple
                />
              </div>
              <div className="bwi-nested-innoflex-flex-center">
                <p className="dsf-span-sl">Skip Trade Window Dates</p>
                <InfoTooltip
                  name="info"
                  Tooltiptext={helpText_SkipValidation}
                  positionClass={"tooltip-position-top"}
                />
              </div>
            </div>
            <div className="bwi-nested-innoflex-flex25">
              <div className="bwi-flex-items">
                <p className="dsf-span-sl">Trade Window Start Date</p>
                <InfoTooltip
                  name="info"
                  Tooltiptext={helpText_TradeWindow}
                  positionClass={"tooltip-position-top"}
                />
              </div>
              <div className="bwi-flex-items">
                <p className="dsf-span-sl">Trade Window End Date</p>
                <InfoTooltip
                  name="info"
                  Tooltiptext={helpText_TradeWindow}
                  positionClass={"tooltip-position-top"}
                />
              </div>
            </div>
          </div>
          <div className="bwi-nested-innoflex-data-row">
            {isLoading ? (
              <>
                <div className="bwi-loader-div">
                  <ActivityIndicator />
                </div>
              </>
            ) : (
              // innoflexData &&
              // innoflexData.checkInnoflex &&
              filteredInnoflexData &&
              filteredInnoflexData.checkInnoflex &&
              filteredInnoflexData?.checkInnoflex?.map((item, i) => {
                if (!item.fgData || item.fgData.length === 0) {
                  return null;
                }
                const isExpanded = expandedRows[i] || false;
                return (
                  <div key={i}>
                    <div className="bwi-nested-innoflex-row">
                      <div className="bwi-nested-innoflex-flex12 bwi-empty-space">
                        <p className="dsf-span-s ">{item.country}</p>
                      </div>
                      <div className="bwi-nested-innoflex-flex6">
                        <p className="dsf-span-s ">{item.fgCode}</p>
                      </div>
                      <div className="bwi-nested-innoflex-flex12">
                        <p className="dsf-span-s ">{item.actionName}</p>
                      </div>
                      <div className="bwi-nested-innoflex-flex12">
                        <p className="dsf-span-s">{item.reference}</p>
                      </div>
                      <div className="bwi-nested-innoflex-flex12 bwi-nested-innoflex-flex-center bwi-checkinnoflex-datepicker bwi-checkInnoflex-launchdatepicker-left">
                        <InnoflexDatePicker
                          key={`${item.country}-launch-date-${i}`}
                          width="100%"
                          launchDate={item.launchDate}
                          isValueNull={isEdit ? true : false}
                          isError={
                            item.isValidationError
                              ? false
                              : item.launchDate === ""
                          }
                          onDatePickerChange={(value, isNull, date) =>
                            handleCountryDatePickerChange(date, i, "launchDate")
                          }
                        />
                      </div>

                      <div className="bwi-nested-innoflex-skip-validation bwi-nested-innoflex-flex8">
                        <div>
                          <Checkbox
                            id={`default-checkbox`}
                            disabled={isEdit ? false : true}
                            className="dsf-InventoryCM-checkbox"
                            disableRipple
                            checked={item.isValidationSkiped}
                            onChange={(e) =>
                              onCheckBoxClicked(e, i, 0, "country")
                            }
                          />
                        </div>
                      </div>
                      <div className="bwi-nested-innoflex-flex25">
                        <div className=" bwi-checkinnoflex-datepicker bwi-checkInnoflex-startdatepicker-left">
                          <InnoflexDatePicker
                            key={`${item.country}-start-date-${i}`}
                            launchDate={item.tradeWindowStartDate}
                            width="60.3%"
                            isValueNull={isEdit ? true : false}
                            disablePast={false}
                            disableFuture={false}
                            isError={item.isValidationError}
                            onDatePickerChange={(value, isNull, date) =>
                              handleCountryDatePickerChange(
                                date,
                                i,
                                "tradeWindowStartDate"
                              )
                            }
                          />
                        </div>
                        <div className="bwi-nested-innoflex-flex-center bwi-checkinnoflex-datepicker bwi-checkInnoflex-enddatepicker-left">
                          <InnoflexDatePicker
                            key={`${item.country}-end-date-${i}`}
                            width="60.3%"
                            launchDate={item.tradeWindowEndDate}
                            isValueNull={isEdit ? true : false}
                            isError={item.isValidationError}
                            onDatePickerChange={(value, isNull, date) =>
                              handleCountryDatePickerChange(
                                date,
                                i,
                                "tradeWindowEndDate"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {item.fgData && item.fgData.length > 0 && (
                      <div className="bwi-nested-innoflex-children">
                        {item.fgData.map((child, j) => (
                          <div key={j} className="bwi-nested-innoflex-row">
                            <div className="bwi-nested-innoflex-flex12 dsf-span-s">
                              {child.country}
                            </div>
                            <div className="bwi-nested-innoflex-flex6 dsf-span-s bwi-flag-align bwi-tick-space">
                              <span className="bwi-nested-innoflex-arrow bwi-tick-parent">
                                {child.isNewFG ? (
                                  <img
                                    className="bwi-tick-img"
                                    src={TickMark}
                                    alt="TickMark"
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                              <p
                                className={`dsf-span-s  ${
                                  child.isNewFG ? "bwi-text-highlight" : ""
                                }`}
                              >
                                {child.fgCode}
                              </p>
                            </div>
                            <div className="bwi-relative-container bwi-nested-innoflex-flex12 dsf-span-s dsf-span-s">
                              <div className="bwi-ellipsis">
                                {child.actionName}
                              </div>
                              <span className="bwi-review-tooltip bwi-action-tooltip">
                                {child.actionName}
                              </span>
                            </div>
                            <div className="bwi-relative-container bwi-nested-innoflex-flex12 ">
                              <div className=" dsf-span-s bwi-ellipsis">
                                {child.reference}
                              </div>
                              <span className="bwi-review-tooltip">
                                {child.reference}
                              </span>
                            </div>
                            <div className="bwi-nested-innoflex-flex12 bwi-nested-innoflex-flex-center bwi-checkinnoflex-datepicker bwi-checkInnoflex-launchdatepicker-left">
                              <InnoflexDatePicker
                                key={`${child.country}-launch-date-${j}`}
                                width="100%"
                                launchDate={child.launchDate}
                                isValueNull={
                                  isEdit ||
                                  child.userHasEditAccess ===
                                    userDetails.email?.toLowerCase()
                                    ? true
                                    : false
                                }
                                isError={
                                  child.isValidationError
                                    ? false
                                    : child.launchDate === ""
                                }
                                onDatePickerChange={(value, isNull, date) =>
                                  handleFGDatePickerChange(
                                    date,
                                    i,
                                    j,
                                    "launchDate"
                                  )
                                }
                              />
                            </div>
                            <div className="bwi-nested-innoflex-skip-validation bwi-nested-innoflex-flex8">
                              <div>
                                <Checkbox
                                  id={`default-checkbox`}
                                  disabled={isEdit ? false : true}
                                  className="dsf-InventoryCM-checkbox"
                                  disableRipple
                                  checked={child.isValidationSkiped}
                                  onChange={(e) =>
                                    onCheckBoxClicked(e, i, j, "fg")
                                  }
                                />
                              </div>
                            </div>
                            <div className="bwi-nested-innoflex-flex25">
                              <div className=" bwi-checkinnoflex-datepicker bwi-checkInnoflex-startdatepicker-left">
                                <InnoflexDatePicker
                                  key={`${child.country}-start-date-${j}`}
                                  launchDate={child.fgTradeWindowStartDate}
                                  width="60.3%"
                                  isValueNull={isEdit ? true : false}
                                  disablePast={false}
                                  disableFuture={false}
                                  isError={child.isValidationError}
                                  onDatePickerChange={(value, isNull, date) =>
                                    handleFGDatePickerChange(
                                      date,
                                      i,
                                      j,
                                      "fgTradeWindowStartDate"
                                    )
                                  }
                                />
                              </div>
                              <div className="bwi-nested-innoflex-flex-center bwi-checkinnoflex-datepicker bwi-checkInnoflex-enddatepicker-left">
                                <InnoflexDatePicker
                                  key={`${child.country}-end-date-${j}`}
                                  width="60.3%"
                                  launchDate={child.fgTradeWindowEndDate}
                                  isValueNull={isEdit ? true : false}
                                  isError={child.isValidationError}
                                  onDatePickerChange={(value, isNull, date) =>
                                    handleFGDatePickerChange(
                                      date,
                                      i,
                                      j,
                                      "fgTradeWindowEndDate"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className="bwi-display-flex-between bwi-modal-checkinnoflex-footer">
          <div>
            {innoflexData &&
              innoflexData.checkInnoflex &&
              innoflexData.checkInnoflex.length > 0 && (
                <span className="bwi-modal-sub-title dsf-span-s">
                  {innoflexData.checkInnoflex.length - errorCount} /{" "}
                  {innoflexData.checkInnoflex.length} Completed
                </span>
              )}
          </div>
          <div className="bwi-modal-footer">
            <Button
              className="bwi-checkinnoflex-footer-btn bwi-reset-button"
              variant="secondary"
              onClick={() => onWarnModalOpen(true, "resetButton")}
              disabled={!(isEdit || userHasAccess) || isResetDisabled}
            >
              Reset
            </Button>
            <Button
              className="bwi-checkinnoflex-footer-btn bwi-submit-button"
              variant="secondary"
              disabled={(!isEdit || isOkDisabled) && !userHasAccess}
              onClick={() => onWarnModalOpen(true, "okButton")}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckInnoflex;