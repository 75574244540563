const moment = require("moment-timezone");

/*
 **this method is used for formatting the date with specifed format.
 ** Param: date:mandatory
 ** param:format:optional (default is 'DD/MM/YYYY')
 */
export const dateTimeFormat = (date, format) => {
  if (date) return moment(date).format(format ?? "DD/MM/YYYY");
  else return date;
};

export const dateComparator = (filterLocalDateAtMidnight, cellValue) => {
  var cellDate = moment(cellValue, "MM/DD/YYYY");

  if (moment(filterLocalDateAtMidnight, "MM/DD/YYYY").isSame(cellDate)) {
    return 0;
  }
  if (cellDate.isBefore(moment(filterLocalDateAtMidnight, "MM/DD/YYYY"))) {
    return -1;
  }
  if (cellDate.isAfter(moment(filterLocalDateAtMidnight, "MM/DD/YYYY"))) {
    return 1;
  }
  return 0;
};

export const datefilterParams = {
  comparator: dateComparator,
  inRangeFloatingFilterDateFormat: "DD/MMM/YYYY",
  inRangeInclusive: true,
  suppressAndOrCondition: true,
  filterOptions: ["equals", "greaterThan", "lessThan", "inRange"],
  buttons: ["clear"],
};

export const functionfilterParams = {
  filterOptions: [
    "empty",
    {
      displayKey: "Marketing",
      displayName: "Marketing",
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Marketing",
      numberOfInputs: 0,
    },
    {
      displayKey: "Procurement",
      displayName: "Procurement",
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Procurement",
      numberOfInputs: 0,
    },
    {
      displayKey: "MaterialPlanning",
      displayName: "Material Planning",
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Material Planning",
      numberOfInputs: 0,
    },
    {
      displayKey: "DemandPlanning",
      displayName: "Demand Planning",
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Demand Planning",
      numberOfInputs: 0,
    },
    {
      displayKey: "E2EPlanning",
      displayName: "E2E Planning",
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "E2E Planning",
      numberOfInputs: 0,
    },
    {
      displayKey: "P&I",
      displayName: "P&I",
      predicate: (_, cellValue) => cellValue != null && cellValue === "P&I",
      numberOfInputs: 0,
    },
  ],
  buttons: ["clear"],
  suppressMenu: true,
  floatingFilter: true,
  sortable: true,
  resizable: true,
  cellStyle: { textAlign: "left" },
  maxNumConditions: 1,
};

export const statusfilterParams = {
  filterOptions: [
    "empty",
    {
      displayKey: "Completed",
      displayName: "Completed",
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Completed",
      numberOfInputs: 0,
    },
    {
      displayKey: "Pastdeadline",
      displayName: "Past deadline",
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Past deadline",
      numberOfInputs: 0,
    },
    {
      displayKey: "Lessthan3daystodeadline",
      displayName: "Less than 3 days to deadline",
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Less than 3 days to deadline",
      numberOfInputs: 0,
    },
    {
      displayKey: "Open",
      displayName: "Open",
      predicate: (_, cellValue) => cellValue != null && cellValue === "Open",
      numberOfInputs: 0,
    },
  ],
  buttons: ["clear"],
  suppressMenu: true,
  floatingFilter: true,
  sortable: true,
  resizable: true,
  cellStyle: { textAlign: "left" },
  maxNumConditions: 1,
};

export const matchString = (arr, objProp, matchingStr) => {
  const filteredArr = arr.filter((item) => item[objProp] === matchingStr);
  return filteredArr.length > 0;
};

export const calculateSum = (array, property) => {
  let sum = 0;
  const total = array.reduce((accumulator, object) => {
    return (sum = sum + parseFloat(object[property]));
  }, []);

  if (!total.toString().includes("null") && !total.toString().includes("NaN")) {
    return total;
  } else {
    return 0;
  }
};

export const checkNullDataInArray = (arr) => {
  // let isBlankPresent=false;
  let blankValueCount = 0;
  arr.forEach((item) => {
    // if((Object.values(item).length === 0) || (Object.values(item) === 'undefined') || (Object.values(item) === null)){
    //   isBlankPresent = true;
    // }
    let arrBlankValue = Object.values(item).filter(
      (obj) =>
        obj.length === 0 || obj === "undefined" || obj === null || obj === ""
    );
    blankValueCount += arrBlankValue.length;
  });
  return blankValueCount >= 1 ? true : false;
};
export const checkNullDataByObjInArray = (arr, objList) => {
  let blankValueCount = 0;
  arr.forEach((item) => {
    objList.forEach((obj) => {
      if (item[obj].length === 0) {
        blankValueCount++;
      }
    });
  });
  return blankValueCount >= 1 ? false : true;
};

export const checkNullDataByObjInNestedArray = (arr, field, objList) => {
  let blankValueCount = 0;
  arr.forEach((item) => {
    objList.forEach((obj) => {
      if (item[obj].length === 0) {
        blankValueCount++;
      }
      if (item[field]?.length > 0) {
        item[field].forEach((nestedItem) => {
          if (nestedItem[obj].length === 0) {
            blankValueCount++;
          }
        });
      }
    });
  });
  return blankValueCount >= 1 ? false : true;
};

export const isTaskInfoNull = (arr) => {
  let blankValueCount = 0;
  arr.forEach((item) => {
    if (
      item.departmentAdminEmail === null ||
      item.departmentAdminEmail === ""
    ) {
      blankValueCount = blankValueCount + 1;
    }
  });
  return blankValueCount >= 1 ? true : false;
};

/*
 **this method is used for validate whether the file you browse has any data or not
 */
export const getFormContentLength = (formData) => {
  const formDataEntries = [...formData.entries()];

  const contentLength = formDataEntries.reduce((acc, [key, value]) => {
    if (typeof value === "string") return acc + value.length;
    if (typeof value === "object") return acc + value.size;

    return acc;
  }, 0);

  return contentLength;
};

export const handleSort = async (
  arr,
  sortOrder,
  objName,
  isSortingDoneOnString
) => {
  const sortedData = [...arr].sort((a, b) => {
    if (isSortingDoneOnString === true) {
      if (sortOrder === "ascending") {
        if (a[objName] === b[objName]) {
          return 1;
        } else {
          return a[objName] > b[objName] ? 1 : -1;
        }
      } else {
        if (a[objName] === b[objName]) {
          return 1;
        } else {
          return a[objName] < b[objName] ? 1 : -1;
        }
      }
    } else if (isSortingDoneOnString === "date") {
      if (sortOrder === "ascending") {
        return (
          moment(a[objName], "MM/DD/YYYY hh:mm:ss A") -
          moment(b[objName], "MM/DD/YYYY hh:mm:ss A")
        );
      } else {
        return (
          moment(b[objName], "MM/DD/YYYY hh:mm:ss A") -
          moment(a[objName], "MM/DD/YYYY hh:mm:ss A")
        );
      }
    } else if (isSortingDoneOnString === "email") {
      if (sortOrder === "ascending") {
        return a[objName]?.toLowerCase().localeCompare(b[objName]?.toLowerCase());
      } else {
        return b[objName]?.toLowerCase().localeCompare(a[objName]?.toLowerCase());
      }
    } else {
      if (sortOrder === "ascending") {
        return a[objName] - b[objName];
      } else {
        return b[objName] - a[objName];
      }
    }
  });
  return sortedData;
};

export const validateUnileverMailId = (mailId) => {
  // const regex= /[\w]*@*[a-z]*\.*[\w]{1,}(\.)*(com)*(@gmail\.com)/g;
  const regex1 = /^[a-z0-9](\.?[a-z0-9]){1,}@unilever\.com$/;
  // const regex= /\.?[^@"(),:;<>{[\]}][a-z0-9]*\.?[^@"(),:;<>{[\]}][\w][a-z0-9]@unilever\.com$/;
  // const regex= /[\w][^@"(),:;<>{[\]}]?[a-z]*\.?[\w][^@"(),:;<>{[\]}]?[a-z]{1,}@unilever\.com$/;
  const reg = /^[\w][a-zA-Z0-9_!#$%^&'*/=?`"{ "|.+-]{1,}@unilever\.com$/;
  const isMatched = mailId.match(reg);
  return isMatched;
};

export const formatNumber = (num, precision = 2) => {
  const map = [
    { suffix: "T", threshold: 1e12 },
    { suffix: "B", threshold: 1e9 },
    { suffix: "M", threshold: 1e6 },
    { suffix: "K", threshold: 1e3 },
    { suffix: "", threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  let formatted = 0;
  if (found) {
    formatted = (num / found.threshold).toFixed(precision) + found.suffix;
  } else {
    formatted = parseFloat(num).toFixed(precision);
  }
  // return formatted;
  return formatted === "NaN" ? "-" : formatted;
};