import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import CustomDropdown from "../../../../components/Dropdown/dropdown";
import TooltipDropdown from "../../../../components/Dropdown/tooltipDropdown";
import CustomOptionsDropdown from "../../../../components/Dropdown/customOptionsDropdown";
import { Checkbox } from "@mui/material";
import FGCheckModal from "./fgCheckModal";
import FGSelectionModal from "../../../../components/Autocomplete/autocompleteList";
import ModalWarning from "../modalWarning";
import ActivityIndicator from "../../../../components/Loader/loader";
import searchIcon from "../../../../assets/images/Search.svg";
import deleteList from "../../../../assets/images/deleteSelected.svg";
import { delistBOMActionTypeList } from "../../../../globalConstants";
import { postUpdatedWorkFlowStatus } from "../../../../slices/projectDetailSlice";
import ArrowUp from "../../../../assets/images/Arrow up sm.svg";
import ArrowDown from "../../../../assets/images/Arrow down sm.svg";
import {
  fetchDelistBOMCountry,
  fetchDelistBOMData,
  saveDelistBOMData,
  fetchProjectWorkFlowStatus,
  resetWorkflowDetails,
} from "../../../../service/projectDetails";
import "./delistBOM.css";
import { dateTimeFormat, handleSort, handleDateSort } from "../../../../utils";
import polygon from "../../../../assets/images/Polygon-enable.svg";

const DelistBOM = (props) => {
  const { isEdit, stepStatus } = props;
  const [delistBOMCountryList, setDelistBOMCountryList] = useState([]);
  const [dropdownCountryValue, setDropdownCountryValue] = useState("");
  const [dropdownActionValue, setDropdownActionValue] = useState("");
  const [dropdownLaunchValue, setDropdownLaunchValue] = useState("");
  const [dropdownCombinations, setDropdownCombinations] = useState("");
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isFGSearchReset, setIsFGSearchReset] = useState(false);
  const [isFGSearchResetEnable, setIsFGSearchResetEnable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectedLoading, setIsSelectedLoading] = useState(false);
  const [showRightAddButton, setShowRightAddButton] = useState(false);
  const [checkboxSelectedAll, setCheckboxSelectedAll] = useState(false);
  const [delistBOMData, setDelistBOMData] = useState({});
  const [delistBOMDataInitial, setDelistBOMDataInitial] = useState({});
  const [objSelectedData, setObjSelectedData] = useState([]);
  const [objUnSelectedData, setObjUnSelectedData] = useState([]);
  const [objRemovedData, setObjRemovedData] = useState({});
  const [isWarnWindowOpen, setIsWarnWindowOpen] = useState(false);
  const [warnWindowButton, setWarnWindowButton] = useState("");
  const [functionSelected, setFunctionSelected] = useState("Search FG Codes");
  const [actionIsOpen, setActionIsOpen] = useState(false);
  const [defaulSubmit, setDefaulSubmit] = useState(true);
  const dropdownRefAction = useRef(null);
  const [activeSort, setActiveSort] = useState();
  const [isSortActive, setIsSortActive] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [countryIsOpen, setCountryIsOpen] = useState(false);
  const [countrySearchTerm, setCountrySearchTerm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [countrySelectAll, setCountrySelectAll] = useState(true);
  const [fGCodeIsOpen, setFGCodeIsOpen] = useState(false);
  const [fGCodeSearchTerm, setFGCodeSearchTerm] = useState("");
  const [selectedFGCode, setSelectedFGCode] = useState([]);
  const [fGCodeSelectAll, setFGCodeSelectAll] = useState(true);
  const [fGNameIsOpen, setFGNameIsOpen] = useState(false);
  const [fGNameSearchTerm, setFGNameSearchTerm] = useState("");
  const [selectedFGName, setSelectedFGName] = useState([]);
  const [fGNameSelectAll, setFGNameSelectAll] = useState(true);
  const [actionsIsOpen, setActionsIsOpen] = useState(false);
  const [actionsSearchTerm, setActionsSearchTerm] = useState("");
  const [selectedActions, setSelectedActions] = useState([]);
  const [actionsSelectAll, setActionsSelectAll] = useState(true);
  const [delistDateIsOpen, setDelistDateIsOpen] = useState(false);
  const [delistDateSearchTerm, setDelistDateSearchTerm] = useState("");
  const [selectedDelistDate, setSelectedDelistDate] = useState([]);
  const [delistDateSelectAll, setDelistDateSelectAll] = useState(true);
  const [referenceIsOpen, setReferenceIsOpen] = useState(false);
  const [referenceSearchTerm, setReferenceSearchTerm] = useState("");
  const [selectedReference, setSelectedReference] = useState([]);
  const [referenceSelectAll, setReferenceSelectAll] = useState(true);
  const [originalObjSelectedData, setOriginalObjSelectedData] =
    useState(objSelectedData);
  const [filteredObjSelectedData, setFilteredObjSelectedData] =
    useState(objSelectedData);

  const isDataLoaded = useRef(false);
  const dropdownCountryRef = useRef(null);
  //Redux Start
  const arrProjectDetails = useSelector((state) => state.projectDetails);
  const dispatch = useDispatch();
  //Redux End

  //Click outside left Box
  //Define dropdown configurations
  const DROPDOWN_TYPES = {
    COUNTRY: "country",
    FG: "fg",
    FG_NAME: "fgName",
    ACTION: "action",
    DELIST: "delist",
    REFER: "refer",
  };

  const DropdownManager = () => {
    // Create a single state object for all dropdowns

    const [dropdownStates, setDropdownStates] = useState({
      [DROPDOWN_TYPES.COUNTRY]: false,
      [DROPDOWN_TYPES.FG]: false,
      [DROPDOWN_TYPES.FG_NAME]: false,
      [DROPDOWN_TYPES.ACTION]: false,
      [DROPDOWN_TYPES.DELIST]: false,
      [DROPDOWN_TYPES.REFER]: false,
    });

    // Create refs object
    const refs = {
      [DROPDOWN_TYPES.COUNTRY]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
      [DROPDOWN_TYPES.FG]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
      [DROPDOWN_TYPES.FG_NAME]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
      [DROPDOWN_TYPES.ACTION]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
      [DROPDOWN_TYPES.DELIST]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
      [DROPDOWN_TYPES.REFER]: {
        dropdown: useRef(null),
        toggle: useRef(null),
      },
    };

    //UsEffect for Refer id
    // Single handler for all dropdowns
    const handleClickOutside = useCallback((event) => {
      Object.entries(refs).forEach(([key, { dropdown, toggle }]) => {
        if (
          dropdown.current &&
          toggle.current &&
          !dropdown.current.contains(event.target) &&
          !toggle.current.contains(event.target)
        ) {
          setDropdownStates((prev) => ({
            ...prev,
            [key]: false,
          }));
        }
      });
    }, []);

    // Single useEffect for all click outside listeners
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }, [handleClickOutside]);

    // Single toggle function for all dropdowns
    const toggleDropdown = useCallback((dropdownType) => {
      setDropdownStates((prev) => ({
        ...prev,
        [dropdownType]: !prev[dropdownType],
      }));
    }, []);

    return {
      states: dropdownStates,
      refs,
      toggleDropdown,
    };
  };
  const { states, refs, toggleDropdown } = DropdownManager();

  useEffect(() => {
    if (objSelectedData) {
      if (!selectedFilters.length > 0 && objSelectedData) {
        // Initialize accumulator objects for each category
        const countryOptions = [];
        const fgCodeOptions = [];
        const fgNameOptions = [];
        const actionOptions = [];
        const delistDateOptions = [];
        const referenceOptions = [];

        // Loop through objSelectedData to populate options
        objSelectedData.forEach((item) => {
          if (item && Array.isArray(item)) {
            item.forEach((fg) => {
              if (fg?.country) countryOptions.push(fg.country);
              if (fg?.skuCode) fgCodeOptions.push(fg.skuCode);
              if (fg?.skuName) fgNameOptions.push(fg.skuName);
              if (fg?.action) actionOptions.push(fg.action);
              if (fg?.launchDate) delistDateOptions.push(fg.launchDate);
              if (fg?.reference) referenceOptions.push(fg.reference);
            });
          } else {
            if (item?.country) countryOptions.push(item.country);
            if (item?.skuCode) fgCodeOptions.push(item.skuCode);
            if (item?.skuName) fgNameOptions.push(item.skuName);
            if (item?.action) actionOptions.push(item.action);
            if (item?.launchDate) delistDateOptions.push(item.launchDate);
            if (item?.reference) referenceOptions.push(item.reference);
          }
        });

        // Remove duplicates
        const uniqueCountryNames = Array.from(new Set(countryOptions));
        const uniqueFGCode = Array.from(new Set(fgCodeOptions));
        const uniqueFGName = Array.from(new Set(fgNameOptions));
        const uniqueAction = Array.from(new Set(actionOptions));
        const uniqueDelistDate = Array.from(new Set(delistDateOptions));
        const uniqueReference = Array.from(new Set(referenceOptions));

        // Set state values
        setSelectedCountry(uniqueCountryNames);
        setSelectedFGCode(uniqueFGCode);
        setSelectedFGName(uniqueFGName);
        setSelectedActions(uniqueAction);
        setSelectedDelistDate(uniqueDelistDate);
        setSelectedReference(uniqueReference);

        setCountrySelectAll(true);
        setFGCodeSelectAll(true);
        setFGNameSelectAll(true);
        setActionsSelectAll(true);
        setDelistDateSelectAll(true);
        setReferenceSelectAll(true);

        // Update data states
        isDataLoaded.current = true;
        setOriginalObjSelectedData(objSelectedData);
        setFilteredObjSelectedData(objSelectedData);
        // setSelectedFilters(objSelectedData);
        

        // Optionally set all data for each category if needed
        // setSelectedCountry(getAllCountryNames(objSelectedData));
        // setSelectedFGCode(getAllFGCode(objSelectedData));
        // setSelectedFGName(getAllFGName(objSelectedData));
        // setSelectedActions(getAllAction(objSelectedData));
        // setSelectedDelistDate(getAllDelistDate(objSelectedData));
        // setSelectedReference(getAllReference(objSelectedData));
      } else {
        // Reset states if no data or selectedFilters exist
        setSelectedCountry([]);
        setSelectedFGCode([]);
        setSelectedFGName([]);
        setSelectedActions([]);
        setSelectedDelistDate([]);
        setSelectedReference([]);
        setCountrySelectAll(false);
        setFGCodeSelectAll(false);
        setFGNameSelectAll(false);
        setActionsSelectAll(false);
        setDelistDateSelectAll(false);
        setReferenceSelectAll(false);
      }
    }
  }, [objSelectedData]);

  // Helper: Get all Reference from the data
  const getAllReference = (data) =>
    data?.flatMap((item) => item?.reference) || [];
  const getAllDelistDate = (data) =>
    data?.flatMap((item) => item?.launchDate) || [];
  const getAllAction = (data) => data?.flatMap((item) => item?.action) || [];
  const getAllFGName = (data) => data?.flatMap((item) => item?.skuName) || [];
  const getAllFGCode = (data) => data?.flatMap((item) => item?.skuCode) || [];
  const getAllCountryNames = (data) =>
    data?.flatMap((item) => item?.country) || [];
  /*Actions on Component Load and API call start */
  useEffect(() => {
    getDelistBOMCountryData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefAction.current &&
        !dropdownRefAction.current.contains(event.target)
      ) {
        setActionIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRefAction]);

  // Filter data based on selected filters
  const filterBasedOnCountryData = (filters) => {
    // if (filters.length === 0) {
    //   // No filters selected: show all data
    //   setFilteredObjSelectedData(originalObjSelectedData);
    //   return;
    // }
    const filtered = originalObjSelectedData?.filter((fg) =>
      filters.includes(fg.country)
    );
    setFilteredObjSelectedData(filtered);
  };

  // Handle "Select All" checkbox
  const handleCountrySelectAll = (e) => {
    const isChecked = e.target.checked;
    setCountrySelectAll(isChecked);

    if (isChecked) {
      const allCountryNames = getAllCountryNames(originalObjSelectedData);
      setSelectedFilters(allCountryNames);
      setSelectedCountry(allCountryNames);
      setFilteredObjSelectedData(originalObjSelectedData);
    } else {
      setSelectedFilters([]);
      setSelectedCountry([]);
      setFilteredObjSelectedData({
        ...originalObjSelectedData,
        objSelectedData: [],
      });
    }
  };

  // Handle individual checkbox changes
  const handleCountryCheckboxChange = (e, country) => {
    const isChecked = e.target.checked;
    const updatedFilters = isChecked
      ? [...selectedCountry, country]
      : selectedCountry.filter((name) => name !== country);

    setSelectedFilters(updatedFilters);
    setSelectedCountry(updatedFilters);

    // Update filtered data based on the updated filters
    filterBasedOnCountryData(updatedFilters);

    // Check if all items are selected
    const allCountryNames = getAllCountryNames(originalObjSelectedData);
    const uniqueCountryNames = Array.from(new Set(allCountryNames));
    setCountrySelectAll(updatedFilters.length === uniqueCountryNames.length);
  };

  // Get unique options for the dropdown
  const getCountryOptions = () => {
    const allOptions = originalObjSelectedData?.flatMap((item) => item) || [];
    const searchUniqueOptions = Array.from(
      new Map(allOptions.map((item) => [item.country, item])).values()
    );
    if (!countrySearchTerm) {
      return searchUniqueOptions;
    }
    return searchUniqueOptions.filter((item) =>
      item.country.toLowerCase().includes(countrySearchTerm.toLowerCase())
    );
  };
  // Filter data based on selected filters
  const filterBasedOnFGCodeData = (filters) => {
    // if (filters.length === 0) {
    //   // No filters selected: show all data
    //   setFilteredObjSelectedData(originalObjSelectedData);
    //   return;
    // }
    const filtered = originalObjSelectedData?.filter((fg) =>
      filters.includes(fg.skuCode)
    );
    setFilteredObjSelectedData(filtered);
  };

  // Handle "Select All" checkbox
  const handleFGCodeSelectAll = (e) => {
    const isChecked = e.target.checked;
    setFGCodeSelectAll(isChecked);

    if (isChecked) {
      const allFGCode = getAllFGCode(originalObjSelectedData);
      setSelectedFilters(allFGCode);
      setSelectedFGCode(allFGCode);
      setFilteredObjSelectedData(originalObjSelectedData);
    } else {
      setSelectedFilters([]);
      setSelectedFGCode([]);
      setFilteredObjSelectedData({
        ...originalObjSelectedData,
        objSelectedData: [],
      });
    }
  };

  // Handle individual checkbox changes
  const handleFGCodeCheckboxChange = (e, skuCode) => {
    const isChecked = e.target.checked;
    const updatedFilters = isChecked
      ? [...selectedFGCode, skuCode]
      : selectedFGCode.filter((name) => name !== skuCode);

    setSelectedFilters(updatedFilters);
    setSelectedFGCode(updatedFilters);

    // Update filtered data based on the updated filters
    filterBasedOnFGCodeData(updatedFilters);

    // Check if all items are selected
    const allFGCode = getAllFGCode(originalObjSelectedData);
    const uniqueFGCode = Array.from(new Set(allFGCode));
    setFGCodeSelectAll(updatedFilters.length === uniqueFGCode.length);
  };

  // Get unique options for the dropdown
  const getFGCodeOptions = () => {
    const allOptions = originalObjSelectedData?.flatMap((item) => item) || [];
    const searchUniqueOptions = Array.from(
      new Map(allOptions.map((item) => [item.skuCode, item])).values()
    );
    if (!fGCodeSearchTerm) {
      return searchUniqueOptions;
    }
    return searchUniqueOptions.filter((item) =>
      item.skuCode.toLowerCase().includes(fGCodeSearchTerm.toLowerCase())
    );
  };

  // FG Name Filter data based on selected filters
  const filterBasedOnFGNameData = (filters) => {
    // if (filters.length === 0) {
    //   // No filters selected: show all data
    //   setFilteredObjSelectedData(originalObjSelectedData);
    //   return;
    // }
    const filtered = originalObjSelectedData?.filter((fg) =>
      filters.includes(fg.skuName)
    );
    setFilteredObjSelectedData(filtered);
  };

  // Handle "Select All" checkbox
  const handleFGNameSelectAll = (e) => {
    const isChecked = e.target.checked;
    setFGNameSelectAll(isChecked);

    if (isChecked) {
      const allFGName = getAllFGName(originalObjSelectedData);
      setSelectedFilters(allFGName);
      setSelectedFGName(allFGName);
      setFilteredObjSelectedData(originalObjSelectedData);
    } else {
      setSelectedFilters([]);
      setSelectedFGName([]);
      setFilteredObjSelectedData({
        ...originalObjSelectedData,
        objSelectedData: [],
      });
    }
  };

  // Handle individual checkbox changes
  const handleFGNameCheckboxChange = (e, skuName) => {
    const isChecked = e.target.checked;
    const updatedFilters = isChecked
      ? [...selectedFGName, skuName]
      : selectedFGName.filter((name) => name !== skuName);

    setSelectedFilters(updatedFilters);
    setSelectedFGName(updatedFilters);

    // Update filtered data based on the updated filters
    filterBasedOnFGNameData(updatedFilters);

    // Check if all items are selected
    const allFGName = getAllFGName(originalObjSelectedData);
    const uniqueFGName = Array.from(new Set(allFGName));
    setFGNameSelectAll(updatedFilters.length === uniqueFGName.length);
  };

  // Get unique options for the dropdown
  const getFGNameOptions = () => {
    const allOptions = originalObjSelectedData?.flatMap((item) => item) || [];
    const searchUniqueOptions = Array.from(
      new Map(allOptions.map((item) => [item.skuName, item])).values()
    );
    if (!fGNameSearchTerm) {
      return searchUniqueOptions;
    }
    return searchUniqueOptions.filter((item) =>
      item.skuName.toLowerCase().includes(fGNameSearchTerm.toLowerCase())
    );
  };

  // Action Filter data based on selected filters
  const filterBasedOnActionData = (filters) => {
    // if (filters.length === 0) {
    //   // No filters selected: show all data
    //   setFilteredObjSelectedData(originalObjSelectedData);
    //   return;
    // }
    const filtered = originalObjSelectedData?.filter((fg) =>
      filters.includes(fg.action)
    );
    setFilteredObjSelectedData(filtered);
  };

  // Handle "Select All" checkbox
  const handleActionSelectAll = (e) => {
    const isChecked = e.target.checked;
    setActionsSelectAll(isChecked);

    if (isChecked) {
      const allAction = getAllAction(originalObjSelectedData);
      setSelectedFilters(allAction); // Select all filters
      setSelectedActions(allAction); // Update dropdown
      setFilteredObjSelectedData(originalObjSelectedData); // Show all data
    } else {
      setSelectedFilters([]); // Clear filters
      setSelectedActions([]); // Clear dropdown
      setFilteredObjSelectedData({
        ...originalObjSelectedData,
        objSelectedData: [],
      }); // Clear main container
    }
  };

  // Handle individual checkbox changes
  const handleActionCheckboxChange = (e, action) => {
    const isChecked = e.target.checked;
    const updatedFilters = isChecked
      ? [...selectedActions, action]
      : selectedActions.filter((name) => name !== action);

    setSelectedFilters(updatedFilters);
    setSelectedActions(updatedFilters);

    // Update filtered data based on the updated filters
    filterBasedOnActionData(updatedFilters);

    // Check if all items are selected
    const allAction = getAllAction(originalObjSelectedData);
    const uniqueAction = Array.from(new Set(allAction));
    setActionsSelectAll(updatedFilters.length === uniqueAction.length);
  };

  // Get unique options for the dropdown
  const getActionOptions = () => {
    const allOptions = originalObjSelectedData?.flatMap((item) => item) || [];
    const searchUniqueOptions = Array.from(
      new Map(allOptions.map((item) => [item.action, item])).values()
    );
    if (!actionsSearchTerm) {
      return searchUniqueOptions;
    }
    return searchUniqueOptions.filter((item) =>
      item.action.toLowerCase().includes(actionsSearchTerm.toLowerCase())
    );
  };

  // Delist Date Filter data based on selected filters
  const filterBasedOnDelistDateData = (filters) => {
    // if (filters.length === 0) {
    //   // No filters selected: show all data
    //   setFilteredObjSelectedData(originalObjSelectedData);
    //   return;
    // }

    const filtered = originalObjSelectedData?.filter((fg) =>
      filters.includes(fg.launchDate)
    );
    setFilteredObjSelectedData(filtered);
  };

  // Handle "Select All" checkbox
  const handleDelistDateSelectAll = (e) => {
    const isChecked = e.target.checked;
    setDelistDateSelectAll(isChecked);

    if (isChecked) {
      const allDelistDate = getAllDelistDate(originalObjSelectedData);
      setSelectedFilters(allDelistDate); // Select all filters
      setSelectedDelistDate(allDelistDate); // Update dropdown
      setFilteredObjSelectedData(originalObjSelectedData); // Show all data
    } else {
      setSelectedFilters([]); // Clear filters
      setSelectedDelistDate([]); // Clear dropdown
      setFilteredObjSelectedData({
        ...originalObjSelectedData,
        objSelectedData: [],
      }); // Clear main container
    }
  };

  // Handle individual checkbox changes
  const handleDelistDateCheckboxChange = (e, launchDate) => {
    const isChecked = e.target.checked;
    const updatedFilters = isChecked
      ? [...selectedDelistDate, launchDate]
      : selectedDelistDate.filter((name) => name !== launchDate);

    setSelectedFilters(updatedFilters);
    setSelectedDelistDate(updatedFilters);

    // Update filtered data based on the updated filters
    filterBasedOnDelistDateData(updatedFilters);

    // Check if all items are selected
    const allDelistDate = getAllDelistDate(originalObjSelectedData);
    const uniqueDelistDate = Array.from(new Set(allDelistDate));
    setDelistDateSelectAll(updatedFilters.length === uniqueDelistDate.length);
  };

  // Get unique options for the dropdown
  const getDelistDateOptions = () => {
    const allOptions = originalObjSelectedData?.flatMap((item) => item) || [];
    const searchUniqueOptions = Array.from(
      new Map(allOptions.map((item) => [item.launchDate, item])).values()
    );
    if (!delistDateSearchTerm) {
      return searchUniqueOptions;
    }
    return searchUniqueOptions.filter((item) =>
      item.launchDate.toLowerCase().includes(delistDateSearchTerm.toLowerCase())
    );
  };

  // Delist Date Filter data based on selected filters
  const filterBasedOnReference = (filters) => {
    // if (filters.length === 0) {
    //   // No filters selected: show all data
    //   setFilteredObjSelectedData(originalObjSelectedData);
    //   return;
    // }
    const filtered = originalObjSelectedData?.filter((fg) =>
      filters.includes(fg.reference)
    );
    setFilteredObjSelectedData(filtered);
  };

  // Handle "Select All" checkbox
  const handleReferenceSelectAll = (e) => {
    const isChecked = e.target.checked;
    setReferenceSelectAll(isChecked);

    if (isChecked) {
      const allReference = getAllReference(originalObjSelectedData);
      setSelectedFilters(allReference); // Select all filters
      setSelectedReference(allReference); // Update dropdown
      setFilteredObjSelectedData(originalObjSelectedData); // Show all data
    } else {
      setSelectedFilters([]); // Clear filters
      setSelectedReference([]); // Clear dropdown
      setFilteredObjSelectedData({
        ...originalObjSelectedData,
        objSelectedData: [],
      }); // Clear main container
    }
  };

  // Handle individual checkbox changes
  const handleReferenceCheckboxChange = (e, reference) => {
    const isChecked = e.target.checked;
    const updatedFilters = isChecked
      ? [...selectedReference, reference]
      : selectedReference.filter((name) => name !== reference);

    setSelectedFilters(updatedFilters);
    setSelectedReference(updatedFilters);

    // Update filtered data based on the updated filters
    filterBasedOnReference(updatedFilters);

    // Check if all items are selected
    const allReference = getAllReference(originalObjSelectedData);
    const uniqueReference = Array.from(new Set(allReference));
    setReferenceSelectAll(updatedFilters.length === uniqueReference.length);
  };

  // Get unique options for the dropdown
  const getReferenceOptions = () => {
    const allOptions = originalObjSelectedData?.flatMap((item) => item) || [];
    const searchUniqueOptions = Array.from(
      new Map(allOptions.map((item) => [item.reference, item])).values()
    );
    if (!referenceSearchTerm) {
      return searchUniqueOptions;
    }
    return searchUniqueOptions.filter((item) =>
      item.reference.toLowerCase().includes(referenceSearchTerm.toLowerCase())
    );
  };
  const getDelistBOMCountryData = async () => {
    setIsSelectedLoading(true);
    const res = await fetchDelistBOMCountry(props.arrItem.projectId);
    if (res.status === 200) {
      const formattedCountryList = res.data.countries.map((countries) => ({
        value: countries,
        label: countries,
      }));
      const selectedFG = res.data.skuLists.map((item) => {
        return {
          ...item,
          isCheckoxSelected: false,
        };
      });

      setDelistBOMCountryList(formattedCountryList);
      setObjSelectedData(selectedFG);
      setIsSelectedLoading(false);
    }
  };

  const getDelistBOMData = async (key) => {
    let res = await fetchDelistBOMData(
      props.arrItem.projectId,
      dropdownCountryValue,
      dropdownActionValue,
      dropdownLaunchValue
    );
    if (res.status === 200) {
      const selectedData = res.data.filter((data) => data.isSelected);
      let unselectedData = res.data.filter((data) => !data.isSelected);
      unselectedData = unselectedData.map((item) => {
        return {
          ...item,
          isDisable: false,
        };
      });
      setDelistBOMData({
        ...delistBOMData,
        [key]: {
          selectedData,
          unselectedData,
        },
      });
      setDelistBOMDataInitial({
        ...delistBOMDataInitial,
        [key]: {
          selectedData,
          unselectedData,
        },
      });
      setDataLeft(unselectedData);
    } else {
      setDataLeft([]);
      console.log("error", res);
    }
  };
  /*Actions on Component Load and API calls end */

  /*Dropdown Change Start */
  const handleDropdownCountryChange = (value) => {
    if (dropdownCountryValue !== value) {
      setIsLoading(true);
      setDropdownCountryValue(value);
      const combinationSelected = `${value}_${dropdownActionValue}`;
      setDropdownCombinations(combinationSelected);
    }
  };

  const handleDropdownActionChange = (value) => {
    setDropdownActionValue(value);
    const launchType = delistBOMActionTypeList.find(
      (item) => item.label === value
    )?.launchType;
    setDropdownLaunchValue(launchType);
    setDropdownCountryValue("");
  };

  useEffect(() => {
    if (objSelectedData?.length > 0) {
      const unSelectedData = objSelectedData.map((item) => {
        return {
          ...item,
          isCheckoxSelected: false,
        };
      });

      setCheckboxSelectedAll(false);
      setObjSelectedData(unSelectedData);
    }
  }, [functionSelected]);

  useEffect(() => {
    if (dropdownCombinations?.length > 0) {
      if (delistBOMData && delistBOMData[dropdownCombinations]) {
        setObjUnSelectedData(
          delistBOMData[dropdownCombinations].unselectedData
        );
        setDataLeft(delistBOMData[dropdownCombinations].unselectedData);
        setIsLoading(false);
      } else {
        getDelistBOMData(dropdownCombinations);
      }
    }
  }, [dropdownCombinations]);
  /*Dropdown Change End */

  /*Get Delist BOM Data Start */
  const setDataLeft = (items) => {
    if (items) {
      let updatedData = Object.assign([], items);
      let objRemoved = Object.assign({}, objRemovedData);
      let selectedData = Object.assign([], objSelectedData);

      if (Object.keys(objRemoved).length > 0) {
        Object.keys(objRemoved).forEach((key) => {
          const country = key.split("_")[0];
          const action = key.split("_")[1];
          if (country === dropdownCountryValue) {
            if (action === dropdownActionValue && objRemoved[key].length > 0) {
              objRemoved[key].forEach((item) => {
                if (
                  updatedData.every((data) => data.skuCode !== item.skuCode)
                ) {
                  updatedData.unshift({
                    ...item,
                    isDisable: false,
                  });
                }
              });
            } else {
              objRemoved[key].forEach((item) => {
                if (
                  updatedData.every((data) => data.skuCode !== item.skuCode)
                ) {
                  if (item.isReselected) {
                    updatedData.unshift({
                      ...item,
                      isDisable: true,
                    });
                  } else {
                    updatedData.unshift({
                      ...item,
                      isDisable: false,
                    });
                  }
                } else {
                  updatedData.forEach((data) => {
                    if (data.skuCode === item.skuCode) {
                      if (item.isReselected) {
                        data.isDisable = true;
                      } else {
                        data.isDisable = false;
                      }
                    }
                  });
                }
              });
            }
          }
        });
      }

      if (selectedData?.length > 0) {
        selectedData.forEach((item) => {
          if (item.country === dropdownCountryValue) {
            if (item.action === dropdownActionValue) {
              const isItemPresent = updatedData.some(
                (data) => data.skuCode === item.skuCode
              );
              if (isItemPresent) {
                updatedData = updatedData.filter(
                  (data) => data.skuCode !== item.skuCode
                );
              }
            } else {
              const isItemPresent = updatedData.some(
                (data) => data.skuCode === item.skuCode
              );
              if (isItemPresent) {
                updatedData.forEach((data) => {
                  if (data.skuCode === item.skuCode) {
                    data.isDisable = true;
                    data.isSelected = false;
                  }
                });
              }
            }
          }
        });
      }

      updatedData = updatedData.map((item) => {
        return {
          ...item,
          label: `${item.skuCode} - ${item.skuName}`,
          value: `${item.skuCode} - ${item.skuName}`,
        };
      });

      setObjUnSelectedData(updatedData);
    }
    setIsLoading(false);
  };

  /*Get Delist BOM Data End */

  /*Left Side Data Change Logic Start */

  const handleDataAdd = async (items) => {
    let updateSelected = Object.assign([], objSelectedData);
    let updateUnSelected = Object.assign([], objUnSelectedData);
    let objRemoved = Object.assign({}, objRemovedData);

    let selectedData = items;
    if (selectedData.length > 0) {
      selectedData.forEach((item) => {
        const selectedCountry =
          item.country?.length > 0 ? item.country : dropdownCountryValue;
        const selectedAction =
          item.action?.length > 0 ? item.action : dropdownActionValue;
        updateUnSelected = updateUnSelected.filter(
          (data) => data.skuCode !== item.skuCode
        );

        const isItemPresent = updateSelected.some(
          (data) =>
            data.skuCode === item.skuCode &&
            data.country === selectedCountry &&
            data.action === selectedAction
        );
        if (!isItemPresent) {
          const key = `${selectedCountry}_${selectedAction}`;
          if (objRemoved[key]) {
            const isPresent = objRemoved[key].some(
              (data) => data.skuCode === item.skuCode
            );
            if (isPresent) {
              objRemoved[key].forEach((data) => {
                if (data.skuCode === item.skuCode) {
                  data.isReselected = true;
                }
              });
            }
          }
          updateSelected.unshift({
            skuCode: item.skuCode,
            skuName: item.skuName,
            isSelected: true,
            isCheckoxSelected: false,
            country: selectedCountry,
            action: selectedAction,
            launchDate: item.launchDate,
            reference: item.reference,
            isNewFG: true,
          });
          setDefaulSubmit(false);
        } else {
          updateSelected.forEach((data) => {
            if (
              data.skuCode === item.skuCode &&
              data.skuName === item.skuName &&
              data.country === item.country
            ) {
              Object.assign(data, {
                launchDate: item.launchDate,
                reference: item.reference,
              });
            }
          });
        }
      });
      setObjSelectedData(updateSelected);
      setObjUnSelectedData(updateUnSelected);
      setObjRemovedData(objRemoved);
      setIsFGSearchReset(true);
    }
  };

  /*Left Side Data Change Logic End */

  /*Right Side Data Change Logic Start */

  const onCheckBoxAllClicked = (e) => {
    if (objSelectedData?.length > 0) {
      setCheckboxSelectedAll(!checkboxSelectedAll);
      setShowRightAddButton(!checkboxSelectedAll);
      if (objSelectedData?.length > 0) {
        const updatedObjSelectedData = Object.assign([], objSelectedData);
        updatedObjSelectedData.map((item) => {
          item.isCheckoxSelected = !checkboxSelectedAll;
        });
        setObjSelectedData(updatedObjSelectedData);
      }
    }
  };

  const onCheckBoxClicked = async (e, index) => {
    if (objSelectedData?.length > 0) {
      const updatedObjSelectedData = Object.assign([], objSelectedData);
      updatedObjSelectedData[index].isCheckoxSelected =
        !updatedObjSelectedData[index].isCheckoxSelected;
      setObjSelectedData(updatedObjSelectedData);

      const isSelectedAll = updatedObjSelectedData.every(
        (item) => item.isCheckoxSelected
      );
      setCheckboxSelectedAll(isSelectedAll);

      const isAnySelected = updatedObjSelectedData.some(
        (item) => item.isCheckoxSelected
      );
      setShowRightAddButton(isAnySelected);
    }
  };

  const handleDataRemoved = async (func) => {
    let updateSelected = Object.assign([], objSelectedData);
    const updateUnSelected = Object.assign([], objUnSelectedData);
    const objRemoved = Object.assign({}, objRemovedData);

    let selectedData = updateSelected;

    if (func !== "reset") {
      selectedData = updateSelected.filter((item) => item.isCheckoxSelected);
    }

    if (selectedData.length > 0) {
      selectedData.forEach((item) => {
        updateSelected = updateSelected.filter(
          (data) =>
            data.skuCode !== item.skuCode ||
            data.country !== item.country ||
            data.action !== item.action
        );
        if (
          dropdownActionValue.length > 0 &&
          dropdownCountryValue.length > 0 &&
          item.country === dropdownCountryValue
        ) {
          if (item.action === dropdownActionValue) {
            updateUnSelected.unshift({
              skuCode: item.skuCode,
              skuName: item.skuName,
              label: `${item.skuCode} - ${item.skuName}`,
              value: `${item.skuCode} - ${item.skuName}`,
              isSelected: false,
              isDisable: false,
            });
          } else {
            const isPresent = updateUnSelected.some(
              (data) => data.skuCode === item.skuCode
            );
            if (!isPresent) {
              updateUnSelected.unshift({
                skuCode: item.skuCode,
                skuName: item.skuName,
                label: `${item.skuCode} - ${item.skuName}`,
                value: `${item.skuCode} - ${item.skuName}`,
                isSelected: false,
                isDisable: false,
              });
            } else {
              updateUnSelected.forEach((data) => {
                if (data.skuCode === item.skuCode) {
                  if (data.isDisable) {
                    data.isDisable = false;
                  } else {
                    data.isDisable = true;
                  }
                }
              });
            }
          }
        }
        const key = `${item.country}_${item.action}`;
        if (objRemoved[key]) {
          const isItemPresent = objRemoved[key].some(
            (data) => data.skuCode === item.skuCode
          );
          if (!isItemPresent) {
            objRemoved[key].push({
              skuCode: item.skuCode,
              skuName: item.skuName,
              isSelected: false,
            });
          } else {
            objRemoved[key].forEach((data) => {
              if (data.skuCode === item.skuCode) {
                data.isReselected = false;
              }
            });
          }
        } else {
          objRemoved[key] = [
            {
              skuCode: item.skuCode,
              skuName: item.skuName,
              isSelected: false,
              isReselected: false,
            },
          ];
        }
      });

      const isAnySelected = updateSelected.some(
        (item) => item.isCheckoxSelected
      );
      setShowRightAddButton(isAnySelected);

      setObjSelectedData(updateSelected);
      setObjUnSelectedData(updateUnSelected);
      setObjRemovedData(objRemoved);
      setCheckboxSelectedAll(false);
    }
  };
  /*Right Side Data Change Logic End */

  /*FG Check Modal Start */
  const handleResetFGSearch = (isReset) => {
    setIsFGSearchResetEnable(isReset);
    setIsFGSearchReset(false);
  };
  /*FG Check Modal End */

  /*Submit and Reset Button Start */
  const onWarnModalOpen = (modalOpen, button) => {
    if (stepStatus !== "4") {
      setIsWarnWindowOpen(modalOpen);
      setWarnWindowButton(button);
    } else {
      if (button === "okButton") {
        onOkBtnClicked();
      } else {
        handleDataRemoved("reset");
      }
    }
  };

  const handleWarnModalOk = () => {
    if (warnWindowButton === "okButton") {
      onOkBtnClicked();
    } else {
      resetDelistBOMData();
    }
  };

  const resetDelistBOMData = async () => {
    setIsLoading(true);
    setIsSelectedLoading(true);
    const res = await resetWorkflowDetails(
      props.arrItem.projectId,
      props.arrItem.workflow[1].workflowId
    );
    if (res.status === 200) {
      const res1 = await fetchProjectWorkFlowStatus(props.arrItem.projectId);
      if (res1.status === 200) {
        const res = {
          projectName: props.selectedProjectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(res));
        setObjSelectedData([]);
      }
    } else {
      console.log(res);
    }
    setIsLoading(false);
    setIsSelectedLoading(false);
  };

  const onOkBtnClicked = async () => {
    setIsLoading(true);
    setIsSelectedLoading(true);
    // const usermailId = JSON.parse(localStorage.getItem("userDetails")).username;
    let delistBOMDataArr = {};
    delistBOMDataArr.projectId = props.arrItem.projectId;
    delistBOMDataArr.projectName = props.arrItem.projectName;

    if (objSelectedData?.length > 0 || Object.keys(objRemovedData).length > 0) {
      let payloadFinal = [];
      let payload = [];

      // Form payload from objSelectedData
      if (objSelectedData?.length > 0) {
        payload = objSelectedData.reduce((acc, item) => {
          const {
            country,
            action,
            skuCode,
            skuName,
            isSelected,
            launchDate,
            reference,
            isNewFG,
          } = item;
          const existingItem = acc.find(
            (obj) => obj.country === country && obj.actionCode === action
          );

          if (existingItem) {
            existingItem.skucodeandname.push({
              skuCode,
              skuName,
              isSelected,
              country: "",
              action: "",
              launchDate: launchDate === null ? "" : launchDate,
              isNewFG: isNewFG,
              reference: reference,
            });
          } else {
            const launch = delistBOMActionTypeList.filter(
              (data) => data.label === action
            )[0].launchType;
            acc.push({
              country,
              actionCode: action,
              launchType: `${launch}_manualScreen`,
              skucodeandname: [
                {
                  skuCode,
                  skuName,
                  isSelected,
                  country: "",
                  action: "",
                  launchDate: launchDate === null ? "" : launchDate,
                  isNewFG: isNewFG,
                  reference: reference,
                },
              ],
            });
          }

          return acc;
        }, []);
        payloadFinal = [...payload];
      }

      if (Object.keys(objRemovedData).length > 0) {
        for (const key in objRemovedData) {
          if (objRemovedData.hasOwnProperty(key)) {
            const [country, action] = key.split("_");
            const existingEntry = payload.find(
              (item) => item.country === country && item.actionCode === action
            );
            if (!existingEntry) {
              const launch = delistBOMActionTypeList.filter(
                (data) => data.label === action
              )[0].launchType;
              payloadFinal.push({
                country,
                actionCode: action,
                launchType: `${launch}_manualScreen`,
                skucodeandname: [],
              });
            }
          }
        }
      }

      delistBOMDataArr.delistbom = payloadFinal;

      const res = await saveDelistBOMData(
        props.arrItem.projectId,
        delistBOMDataArr
      );
      if (res.status === 200) {
        const res1 = await fetchProjectWorkFlowStatus(props.arrItem.projectId);
        if (res1.status === 200) {
          const res = {
            projectName: props.selectedProjectName,
            workflow: res1.data,
          };
          dispatch(postUpdatedWorkFlowStatus(res));
        }
        props.onComplete(false);
      } else {
        console.log("error", res);
      }
      setIsLoading(false);
      setIsSelectedLoading(false);
    }
  };

  const handleSortChange = (event, isSortingDoneOnString) => {
    const itemId = event.target.id.split("-")[1];
    if (filteredObjSelectedData && filteredObjSelectedData?.length > 0) {
      setIsSortActive(true);
      changeActiveSort(itemId, isSortingDoneOnString);
    }
  };

  const changeActiveSort = async (item, isSortingDoneOnString) => {
    let sortedArray = [];
    setActiveSort(item);
    if (!isSortActive) {
      sortedArray = await handleSort(
        filteredObjSelectedData,
        "ascending",
        item,
        isSortingDoneOnString
      );
    } else {
      sortedArray = await handleSort(
        filteredObjSelectedData,
        "descending",
        item,
        isSortingDoneOnString
      );
      setIsSortActive(!isSortActive);
    }
    setFilteredObjSelectedData(sortedArray);
  };
  /*Submit and Reset Button End */

  return (
    <>
      {isWarnWindowOpen && (
        <ModalWarning
          show={true}
          onOk={handleWarnModalOk}
          onWarnModal={onWarnModalOpen}
        />
      )}
      <div className="bwi-modal bwi-modal-delistBOM bwi-modal-fg-container">
        <div className="bwi-landing-modal-header bwi-modal-delistBOM-header">
          <span className="bwi-modal-title dsf-span-ml dsf-font-unileverMedium">
            Existing Finished Goods impacted by renovation
          </span>
          <br />
          <span className="bwi-modal-sub-title dsf-span-s">
            Please enter or upload Finished Goods that either will be delisted
            (SKU code discontinued) or have BOM changes (SKU code continued, but
            raw and pack changes)
          </span>
        </div>
        <div className="bwi-modal-delistBOM-body">
          <Row className="bwi-delistBOM-dropdown-row ">
            <Col
              xs={3}
              className="bwi-header-delistBOM-col bwi-header-delistBOM-col-act"
            >
              <p className="bwi-delistBOM-dropdown-label dsf-span-sl dsf-font-unileverMedium">
                Action
              </p>
              <div
                className="bwi-delistBOM-dropdown-div"
                ref={dropdownRefAction}
              >
                <TooltipDropdown
                  options={
                    delistBOMActionTypeList ? delistBOMActionTypeList : []
                  }
                  isOpen={actionIsOpen}
                  setIsOpen={setActionIsOpen}
                  listClassName="bwi-delistBOM-action-dropdown"
                  onSelectionChange={(value) =>
                    handleDropdownActionChange(value)
                  }
                />
              </div>
            </Col>
            <Col
              xs={9}
              className="bwi-header-delistBOM-col bwi-modal-delistBOM-col-function"
            >
              <ul
                className={`nav bwi-delistBOM-function-nav-ul ${
                  dropdownActionValue.length === 0
                    ? "bwi-delistBOM-data-disable"
                    : ""
                }`}
              >
                <li
                  key="Search FG Codes"
                  className={`dsf-font-unileverMedium ${
                    functionSelected === "Search FG Codes" ? "active" : ""
                  }
                `}
                  onClick={() => {
                    setFunctionSelected("Search FG Codes");
                    setIsFGSearchResetEnable(false);
                    setDropdownCountryValue("");
                  }}
                >
                  Search FG Codes
                </li>
                <li
                  key="Paste FG Codes in Bulk"
                  className={`dsf-font-unileverMedium ${
                    functionSelected === "Paste FG Codes in Bulk"
                      ? "active"
                      : ""
                  }
                  `}
                  onClick={() => {
                    setFunctionSelected("Paste FG Codes in Bulk");
                    setDropdownCountryValue("");
                  }}
                >
                  Paste FG Codes in Bulk
                </li>
              </ul>
            </Col>
          </Row>
          <Row
            className={`bwi-delistBOM-dropdown-row ${
              dropdownActionValue.length === 0
                ? "bwi-delistBOM-data-disable"
                : ""
            }`}
            style={{ paddingBottom: "10px" }}
          >
            <Col
              xs={5}
              className="bwi-delistBOM-list-header-left dsf-text-left"
            >
              {functionSelected === "Paste FG Codes in Bulk" ? (
                <div
                  className="bwi-display-flex-between"
                  style={{ alignContent: "end" }}
                >
                  <span className="bwi-delistBOM-list-span">
                    You can copy the desired codes and Paste the codes below
                  </span>
                  {isFGSearchResetEnable && (
                    <div
                      className="bwi-delistBOM-list-span bwi-delistBOM-search-again"
                      onClick={() => setIsFGSearchReset(true)}
                    >
                      <img
                        src={searchIcon}
                        alt="searchIcon"
                        style={{ marginRight: "2px", marginBottom: "2px" }}
                      />
                      Search again
                    </div>
                  )}
                </div>
              ) : (
                <div className="bwi-display-flex" style={{ height: "18px" }}>
                  <span className="bwi-delistBOM-list-span">
                    Country <span className="dsf-label-mandatory-field">*</span>
                    {""}
                    {dropdownCountryValue.length > 0 ? " : " : " "}
                  </span>
                  <CustomOptionsDropdown
                    options={
                      delistBOMCountryList?.length > 0
                        ? delistBOMCountryList
                        : []
                    }
                    selectedValues={dropdownCountryValue}
                    maxDropdownHeight="140px"
                    width="170px"
                    disable={dropdownActionValue.length === 0}
                    listClassName="bwi-delistBOM-country-dropdown-list"
                    onSelectionChange={(value) =>
                      handleDropdownCountryChange(value)
                    }
                  />
                </div>
              )}
            </Col>
            <Col
              xs={5}
              className="bwi-delistBOM-list-header-right dsf-text-left"
            >
              <div>
                <span className="bwi-delistBOM-list-span">Selected FGs</span>
              </div>
            </Col>
          </Row>
          <Row className={`bwi-delistBOM-dropdown-row bwi-fg-left-container`}>
            <div className="bwi-delistBOM-list-left bwi-left-box bwi-relative">
              <div className="bwi-delistBOM-list-div bwi-delistBOM-list-left-div">
                <div
                  className={`bwi-delistBOM-list-left-container ${
                    isLoading ||
                    dropdownActionValue.length === 0 ||
                    (functionSelected === "Search FG Codes" &&
                      dropdownCountryValue.length === 0)
                      ? "bwi-delistBOM-align-center"
                      : ""
                  }`}
                >
                  {functionSelected === "Paste FG Codes in Bulk" ? (
                    !isLoading && (
                      <FGCheckModal
                        handleSelectedFGData={handleDataAdd}
                        projectId={props.arrItem.projectId}
                        action={dropdownActionValue}
                        isEdit={isEdit}
                        isReset={isFGSearchReset}
                        handleResetSearchEnable={handleResetFGSearch}
                      />
                    )
                  ) : dropdownActionValue.length === 0 ? (
                    <div className="bwi-delistBOM-list-left-data">
                      <span
                        className="bwi-delistBOM-list-left-data-span"
                        style={{ opacity: "0.8" }}
                      >
                        Please select any action type !!
                      </span>
                    </div>
                  ) : dropdownCountryValue.length === 0 ? (
                    <div className="bwi-delistBOM-list-left-data">
                      <span
                        className="bwi-delistBOM-list-left-data-span"
                        style={{ opacity: "0.8" }}
                      >
                        Please select any country !!
                      </span>
                    </div>
                  ) : isLoading ? (
                    <div className="bwi-loader-div">
                      <ActivityIndicator />
                    </div>
                  ) : (
                    <FGSelectionModal
                      options={objUnSelectedData}
                      selectedValues={""}
                      maxLength={10}
                      maxDropdownHeight="100%"
                      width="100%"
                      isOpen={true}
                      setIsOpen={handleResetFGSearch}
                      disable={isEdit}
                      listClassName="dsf-delistingProj-category-list"
                      onSelectionChange={handleDataAdd}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="bwi-delistBOM-list-right bwi-right-box">
              <div className="bwi-delistBOM-list-div bwi-delistBOM-list-right-div bwi-relative">
                <div className="bwi-delistBOM-list-right-container">
                  <div className="bwi-delistBOM-list-right-data bwi-delistbom-overflow">
                    <div>
                      <Row
                        className={`bwi-delistBOM-list-right-header ${
                          dropdownActionValue.length === 0
                            ? "bwi-delistBOM-table-data-disable"
                            : ""
                        }`}
                      >
                        <div
                          style={{ width: "5%", display: "flex" }}
                          className={`bwi-delistBOM-list-right-col`}
                        >
                          {dropdownActionValue.length > 0 && (
                            <Checkbox
                              checked={
                                objSelectedData?.length > 0 &&
                                checkboxSelectedAll
                              }
                              id={`bwi-default-checkbox`}
                              disabled={!isEdit}
                              onClick={(e) => onCheckBoxAllClicked(e)}
                              className="bwi-checkbox"
                              disableRipple
                            />
                          )}
                        </div>
                        <div style={{ width: "14%" }} className="bwi-relative">
                          <div
                            id="bwirpmid-country"
                            style={{
                              display: "flex",
                              paddingRight: "20px",
                              justifyContent: "space-between",
                            }}
                            onClick={(e) => handleSortChange(e, true)}
                            className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                          >
                            <p className="dsf-span-sl">Country</p>
                            {activeSort === "country" && (
                              <img
                                src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                                className="bwi-delist-filter-arrow"
                              />
                            )}
                            <img
                              src={polygon}
                              className={`bwi-rpm-filter-arrow ${
                                states[DROPDOWN_TYPES.COUNTRY]
                                  ? "bwi-arrowup"
                                  : "bwi-arrowdown"
                              }`}
                              ref={refs[DROPDOWN_TYPES.COUNTRY].toggle}
                              onClick={() =>
                                toggleDropdown(DROPDOWN_TYPES.COUNTRY)
                              }
                            />
                          </div>
                          {states[DROPDOWN_TYPES.COUNTRY] && (
                            <div
                              className="bwi-custom-dropdown"
                              ref={refs[DROPDOWN_TYPES.COUNTRY].dropdown}
                            >
                              <div className="bwi-flex">
                                <img
                                  src={searchIcon}
                                  disabled={true}
                                  alt="autocomplete-dropdown-icon"
                                />
                                <input
                                  type="text"
                                  placeholder="search"
                                  value={countrySearchTerm}
                                  onChange={(e) =>
                                    setCountrySearchTerm(e.target.value)
                                  }
                                  className="bwi-dropdown-search"
                                />
                              </div>

                              <div className="bwi-filter-dropdown-options">
                                <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                                  <input
                                    type="checkbox"
                                    checked={countrySelectAll}
                                    onChange={handleCountrySelectAll}
                                    className="select-all-checkbox"
                                    id="selectCheckbox"
                                  />
                                  <label for="selectCheckbox">Select all</label>
                                </div>
                                {getCountryOptions().map((item) => (
                                  <div
                                    key={item.country}
                                    className="bwi-filter-dropdown-options-checkbox checkbox-group"
                                  >
                                    <input
                                      type="checkbox"
                                      name="childCheckbox"
                                      checked={selectedCountry.includes(
                                        item.country
                                      )}
                                      onChange={(e) =>
                                        handleCountryCheckboxChange(
                                          e,
                                          item.country
                                        )
                                      }
                                      id={`checkbox-${item.country}`}
                                      className="bwi-checkbox"
                                    />
                                    <label for={`checkbox-${item.country}`}>
                                      {item.country}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>

                        <div style={{ width: "14%" }} className="bwi-relative">
                          <div
                            id="bwirpmid-skuCode"
                            onClick={(e) => handleSortChange(e, false)}
                            style={{
                              display: "flex",
                              paddingRight: "20px",
                              justifyContent: "space-between",
                            }}
                            className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                          >
                            <p className="dsf-span-sl"> FG Code</p>
                            {activeSort === "skuCode" && (
                              <img
                                src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                                className="bwi-delist-filter-arrow"
                              />
                            )}
                            <img
                              src={polygon}
                              className={`bwi-rpm-filter-arrow ${
                                states[DROPDOWN_TYPES.FG]
                                  ? "bwi-arrowup"
                                  : "bwi-arrowdown"
                              }`}
                              ref={refs[DROPDOWN_TYPES.FG].toggle}
                              onClick={() => toggleDropdown(DROPDOWN_TYPES.FG)}
                            />
                          </div>
                          {states[DROPDOWN_TYPES.FG] && (
                            <div
                              className="bwi-custom-dropdown"
                              ref={refs[DROPDOWN_TYPES.FG].dropdown}
                            >
                              <div className="bwi-flex">
                                <img
                                  src={searchIcon}
                                  disabled={true}
                                  alt="autocomplete-dropdown-icon"
                                />
                                <input
                                  type="text"
                                  placeholder="search"
                                  value={fGCodeSearchTerm}
                                  onChange={(e) =>
                                    setFGCodeSearchTerm(e.target.value)
                                  }
                                  className="bwi-dropdown-search"
                                />
                              </div>

                              <div className="bwi-filter-dropdown-options">
                                <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                                  <input
                                    type="checkbox"
                                    checked={fGCodeSelectAll}
                                    onChange={handleFGCodeSelectAll}
                                    className="select-all-checkbox"
                                    id="selectCheckbox"
                                  />
                                  <label for="selectCheckbox">Select all</label>
                                </div>
                                {getFGCodeOptions().map((item) => (
                                  <div
                                    key={item.skuCode}
                                    className="bwi-filter-dropdown-options-checkbox checkbox-group"
                                  >
                                    <input
                                      type="checkbox"
                                      name="childCheckbox"
                                      checked={selectedFGCode.includes(
                                        item.skuCode
                                      )}
                                      onChange={(e) =>
                                        handleFGCodeCheckboxChange(
                                          e,
                                          item.skuCode
                                        )
                                      }
                                      id={`checkbox-${item.skuCode}`}
                                      className="bwi-checkbox"
                                    />
                                    <label for={`checkbox-${item.skuCode}`}>
                                      {item.skuCode}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                        <div style={{ width: "20%" }} className="bwi-relative">
                          <div
                            id="bwirpmid-skuName"
                            onClick={(e) => handleSortChange(e, true)}
                            style={{
                              display: "flex",
                              paddingRight: "20px",
                              justifyContent: "space-between",
                            }}
                            className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                          >
                            <p className="dsf-span-sl"> FG Name</p>
                            {activeSort === "skuName" && (
                              <img
                                src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                                className="bwi-delist-filter-arrow"
                              />
                            )}
                            <img
                              src={polygon}
                              className={`bwi-rpm-filter-arrow ${
                                states[DROPDOWN_TYPES.FG_NAME]
                                  ? "bwi-arrowup"
                                  : "bwi-arrowdown"
                              }`}
                              ref={refs[DROPDOWN_TYPES.FG_NAME].toggle}
                              onClick={() =>
                                toggleDropdown(DROPDOWN_TYPES.FG_NAME)
                              }
                            />
                          </div>

                          {states[DROPDOWN_TYPES.FG_NAME] && (
                            <div
                              className="bwi-custom-dropdown"
                              ref={refs[DROPDOWN_TYPES.FG_NAME].dropdown}
                            >
                              <div className="bwi-flex">
                                <img
                                  src={searchIcon}
                                  disabled={true}
                                  alt="autocomplete-dropdown-icon"
                                />
                                <input
                                  type="text"
                                  placeholder="search"
                                  value={fGNameSearchTerm}
                                  onChange={(e) =>
                                    setFGNameSearchTerm(e.target.value)
                                  }
                                  className="bwi-dropdown-search"
                                />
                              </div>

                              <div className="bwi-filter-dropdown-options">
                                <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                                  <input
                                    type="checkbox"
                                    checked={fGNameSelectAll}
                                    onChange={handleFGNameSelectAll}
                                    className="select-all-checkbox"
                                    id="selectCheckbox"
                                  />
                                  <label for="selectCheckbox">Select all</label>
                                </div>
                                {getFGNameOptions().map((item) => (
                                  <div
                                    key={item.skuName}
                                    className="bwi-filter-dropdown-options-checkbox checkbox-group"
                                  >
                                    <input
                                      type="checkbox"
                                      name="childCheckbox"
                                      checked={selectedFGName.includes(
                                        item.skuName
                                      )}
                                      onChange={(e) =>
                                        handleFGNameCheckboxChange(
                                          e,
                                          item.skuName
                                        )
                                      }
                                      id={`checkbox-${item.skuName}`}
                                      className="bwi-checkbox"
                                    />
                                    <label for={`checkbox-${item.skuName}`}>
                                      {item.skuName}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                        <div style={{ width: "16%" }} className="bwi-relative">
                          <div
                            id="bwirpmid-action"
                            onClick={(e) => handleSortChange(e, true)}
                            style={{
                              display: "flex",
                              paddingRight: "20px",
                              justifyContent: "space-between",
                            }}
                            className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                          >
                            <p className="dsf-span-sl"> Action</p>
                            {activeSort === "action" && (
                              <img
                                src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                                className="bwi-delist-filter-arrow"
                              />
                            )}
                            <img
                              src={polygon}
                              className={`bwi-rpm-filter-arrow ${
                                states[DROPDOWN_TYPES.ACTION]
                                  ? "bwi-arrowup"
                                  : "bwi-arrowdown"
                              }`}
                              ref={refs[DROPDOWN_TYPES.ACTION].toggle}
                              onClick={() =>
                                toggleDropdown(DROPDOWN_TYPES.ACTION)
                              }
                            />
                          </div>
                          {states[DROPDOWN_TYPES.ACTION] && (
                            <div
                              className="bwi-custom-dropdown"
                              ref={refs[DROPDOWN_TYPES.ACTION].dropdown}
                            >
                              <div className="bwi-flex">
                                <img
                                  src={searchIcon}
                                  disabled={true}
                                  alt="autocomplete-dropdown-icon"
                                />
                                <input
                                  type="text"
                                  placeholder="search"
                                  value={actionsSearchTerm}
                                  onChange={(e) =>
                                    setActionsSearchTerm(e.target.value)
                                  }
                                  className="bwi-dropdown-search"
                                />
                              </div>

                              <div className="bwi-filter-dropdown-options">
                                <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                                  <input
                                    type="checkbox"
                                    checked={actionsSelectAll}
                                    onChange={handleActionSelectAll}
                                    className="select-all-checkbox"
                                    id="selectCheckbox"
                                  />
                                  <label for="selectCheckbox">Select all</label>
                                </div>
                                {getActionOptions().map((item) => (
                                  <div
                                    key={item.action}
                                    className="bwi-filter-dropdown-options-checkbox checkbox-group"
                                  >
                                    <input
                                      type="checkbox"
                                      name="childCheckbox"
                                      checked={selectedActions.includes(
                                        item.action
                                      )}
                                      onChange={(e) =>
                                        handleActionCheckboxChange(
                                          e,
                                          item.action
                                        )
                                      }
                                      id={`checkbox-${item.action}`}
                                      className="bwi-checkbox"
                                    />
                                    <label for={`checkbox-${item.action}`}>
                                      {item.action}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                        <div style={{ width: "14%" }} className="bwi-relative">
                          <div
                            id="bwirpmid-launchDate"
                            onClick={(e) => handleSortChange(e, true)}
                            style={{
                              display: "flex",
                              paddingRight: "20px",
                              justifyContent: "space-between",
                            }}
                            className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                          >
                            <p className="dsf-span-sl"> DelistDate</p>
                            {activeSort === "launchDate" && (
                              <img
                                src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                                className="bwi-delist-filter-arrow"
                              />
                            )}
                            <img
                              src={polygon}
                              className={`bwi-rpm-filter-arrow ${
                                states[DROPDOWN_TYPES.DELIST]
                                  ? "bwi-arrowup"
                                  : "bwi-arrowdown"
                              }`}
                              ref={refs[DROPDOWN_TYPES.DELIST].toggle}
                              onClick={() =>
                                toggleDropdown(DROPDOWN_TYPES.DELIST)
                              }
                            />
                          </div>
                          {states[DROPDOWN_TYPES.DELIST] && (
                            <div
                              className="bwi-custom-dropdown"
                              ref={refs[DROPDOWN_TYPES.DELIST].dropdown}
                            >
                              <div className="bwi-flex">
                                <img
                                  src={searchIcon}
                                  disabled={true}
                                  alt="autocomplete-dropdown-icon"
                                />
                                <input
                                  type="text"
                                  placeholder="search"
                                  value={delistDateSearchTerm}
                                  onChange={(e) =>
                                    setDelistDateSearchTerm(e.target.value)
                                  }
                                  className="bwi-dropdown-search"
                                />
                              </div>

                              <div className="bwi-filter-dropdown-options">
                                <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                                  <input
                                    type="checkbox"
                                    checked={delistDateSelectAll}
                                    onChange={handleDelistDateSelectAll}
                                    className="select-all-checkbox"
                                    id="selectCheckbox"
                                  />
                                  <label for="selectCheckbox">Select all</label>
                                </div>
                                {getDelistDateOptions().map((item) => (
                                  <div
                                    key={item.launchDate}
                                    className="bwi-filter-dropdown-options-checkbox checkbox-group"
                                  >
                                    <input
                                      type="checkbox"
                                      name="childCheckbox"
                                      checked={selectedDelistDate.includes(
                                        item.launchDate
                                      )}
                                      onChange={(e) =>
                                        handleDelistDateCheckboxChange(
                                          e,
                                          item.launchDate
                                        )
                                      }
                                      id={`checkbox-${item.launchDate}`}
                                      className="bwi-checkbox"
                                    />
                                    <label for={`checkbox-${item.launchDate}`}>
                                      {dateTimeFormat(
                                        item.launchDate,
                                        "MM/DD/YYYY"
                                      )}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                        <div style={{ width: "15%" }} className="bwi-relative">
                          <div
                            id="bwirpmid-reference"
                            onClick={(e) => handleSortChange(e, true)}
                            style={{
                              display: "flex",
                              paddingRight: "20px",
                              justifyContent: "space-between",
                            }}
                            className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                          >
                            <p className="dsf-span-sl"> Reference </p>
                            {activeSort === "reference" && (
                              <img
                                src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                                className="bwi-delist-filter-arrow"
                              />
                            )}
                            <img
                              src={polygon}
                              className={`bwi-rpm-filter-arrow ${
                                states[DROPDOWN_TYPES.REFER]
                                  ? "bwi-arrowup"
                                  : "bwi-arrowdown"
                              }`}
                              ref={refs[DROPDOWN_TYPES.REFER].toggle}
                              onClick={() =>
                                toggleDropdown(DROPDOWN_TYPES.REFER)
                              }
                            />
                          </div>
                          {states[DROPDOWN_TYPES.REFER] && (
                            <div
                              className="bwi-custom-dropdown"
                              ref={refs[DROPDOWN_TYPES.REFER].dropdown}
                            >
                              <div className="bwi-flex">
                                <img
                                  src={searchIcon}
                                  disabled={true}
                                  alt="autocomplete-dropdown-icon"
                                />
                                <input
                                  type="text"
                                  placeholder="search"
                                  value={referenceSearchTerm}
                                  onChange={(e) =>
                                    setReferenceSearchTerm(e.target.value)
                                  }
                                  className="bwi-dropdown-search"
                                />
                              </div>

                              <div className="bwi-filter-dropdown-options">
                                <div className="bwi-filter-dropdown-options-checkbox checkbox-group">
                                  <input
                                    type="checkbox"
                                    checked={referenceSelectAll}
                                    onChange={handleReferenceSelectAll}
                                    className="select-all-checkbox"
                                    id="selectCheckbox"
                                  />
                                  <label for="selectCheckbox">Select all</label>
                                </div>
                                {getReferenceOptions().map((item) => (
                                  <div
                                    key={item.reference}
                                    className="bwi-filter-dropdown-options-checkbox checkbox-group"
                                  >
                                    <input
                                      type="checkbox"
                                      name="childCheckbox"
                                      checked={selectedReference.includes(
                                        item.reference
                                      )}
                                      onChange={(e) =>
                                        handleReferenceCheckboxChange(
                                          e,
                                          item.reference
                                        )
                                      }
                                      id={`checkbox-${item.reference}`}
                                      className="bwi-checkbox"
                                    />
                                    <label for={`checkbox-${item.reference}`}>
                                      {item.reference}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </Row>
                      <div
                        className={`bwi-delistBOM-list-right-data bwi-delistBOM-list-data ${
                          isSelectedLoading ? "bwi-delistBOM-align-center" : ""
                        }`}
                      >
                        {isSelectedLoading ? (
                          <div className="bwi-loader-div">
                            <ActivityIndicator />
                          </div>
                        ) : (
                          filteredObjSelectedData?.length > 0 &&
                          filteredObjSelectedData.map((item, index) => {
                            return (
                              <Row
                                key={index}
                                className={`bwi-delistBOM-list-right-row  ${
                                  !item.isCheckoxSelected
                                    ? "bwi-delistBOM-list-row-not-selected"
                                    : ""
                                } ${
                                  dropdownActionValue.length === 0
                                    ? "bwi-delistBOM-table-data-disable"
                                    : ""
                                }`}
                              >
                                <div
                                  className="bwi-delistBOM-list-right-col"
                                  style={{ width: "5%", display: "flex" }}
                                >
                                  <Checkbox
                                    checked={item.isCheckoxSelected}
                                    id={`bwi-default-checkbox`}
                                    disabled={!isEdit}
                                    onClick={(e) => onCheckBoxClicked(e, index)}
                                    className="bwi-checkbox"
                                    disableRipple
                                  />
                                </div>
                                <div
                                  style={{ width: "14%" }}
                                  className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                                >
                                  {item.country}
                                </div>
                                <div
                                  style={{ width: "14%" }}
                                  className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                                >
                                  {item.skuCode}
                                </div>
                                <div
                                  style={{ width: "20%" }}
                                  className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                                >
                                  {item.skuName}
                                </div>
                                <div
                                  style={{ width: "16%" }}
                                  className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                                >
                                  {item.action}
                                </div>
                                <div
                                  style={{ width: "14%" }}
                                  className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                                >
                                  {dateTimeFormat(
                                    item.launchDate,
                                    "MM/DD/YYYY"
                                  )}
                                </div>
                                <div
                                  style={{ width: "15%" }}
                                  className="bwi-delistBOM-list-right-col "
                                >
                                  {item.reference}
                                </div>
                              </Row>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  variant="secondary"
                  className="bw-delistBOMfg-btn bwi-delistBOMfg-btn-position bw-delistBOMfg-right-btn bwi-delistbom-delete-button"
                  onClick={() => handleDataRemoved("dataSelected")}
                  disabled={!isEdit || !showRightAddButton}
                >
                  {" "}
                  <img
                    src={deleteList}
                    alt="buttonSelected"
                    className="bwi-delistbom-delete-icon"
                  />{" "}
                  Delete Selected
                </Button>
              </div>
            </div>
          </Row>
        </div>
        <div className="bwi-modal-delistBOM-footer">
          <div className="bwi-footer-prevbtn-div"></div>
          <div className="bwi-modal-footer">
            <Button
              className="bwi-delistBOM-footer-btn bwi-reset-button"
              variant="secondary"
              disabled={!isEdit || objSelectedData?.length === 0}
              onClick={() => {
                onWarnModalOpen(true, "resetButton");
              }}
            >
              Reset
            </Button>
            <Button
              className=" bwi-delistBOM-footer-btn bwi-submit-button"
              variant="secondary"
              disabled={
                !isEdit ||
                (objSelectedData?.length === 0
                  ? objRemovedData && Object.keys(objRemovedData).length === 0
                    ? true
                    : false
                  : false) ||
                defaulSubmit
              }
              onClick={() => onWarnModalOpen(true, "okButton")}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DelistBOM;