import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, Row, FormCheck } from "react-bootstrap";
import supplierPartial from "../../../../assets/images/landingPage/supplierPartial.svg";
import downloadIcon from "../../../../assets/images/download.svg";
import supplierPartialActive from "../../../../assets/images/landingPage/supplierPartialActive.svg";
import supplierTick from "../../../../assets/images/landingPage/supplierTick.svg";
import supplierTickActive from "../../../../assets/images/landingPage/supplierTickActive.svg";
import ActivityIndicator from "../../../../components/Loader/loader";
import FileUploaderInventory from "../../../../components/FileUploder/fileUploaderInventory";
import {
  fetchInventoryCMDetails,
  sendToCMOps,
  saveInventoryCMDetails,
  fetchProjectWorkFlowStatus,
  resetInventoryCMDetails,
} from "../../../../service/projectDetails";
import { postUpdatedWorkFlowStatus } from "../../../../slices/projectDetailSlice";
import {
  dateTimeFormat,
  validateUnileverMailId,
  handleSort,
} from "../../../../utils";
import { noDataFoundText } from "../../../../globalConstants";
import UserDetailsService from "../../../../service/userDetailsService";
import templateInv from "../../../../assets/Templates/InventoryAtCM-Template.xlsx";
import templateRPM from "../../../../assets/Templates/RPMTemplate.xlsx";
import { Checkbox } from "@mui/material";
import ModalWarning from "../modalWarning";
import TooltipDropdown from "../../../../components/Dropdown/tooltipDropdown";
import "./inventoryCM.css";
import ArrowUp from "../../../../assets/images/Arrow up sm.svg";
import ArrowDown from "../../../../assets/images/Arrow down sm.svg";

const InventoryCM = (props) => {
  const { isEdit, stepStatus, arrItem } = props;
  const [isLoadedFirstTime, setIsLoadedFirstTime] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [invCountCheck, setInvCountCheck] = useState(false);
  const [rpmCountCheck, setRPMCountCheck] = useState(false);
  const [functionSelected, setFunctionSelected] = useState("");
  const [isInventoryCMSkipped, setIsInventoryCMSkipped] = useState(false);
  const [isRPMSkipped, setIsRPMSkipped] = useState(false);
  const [objSupplierStock, setObjSupplierStock] = useState({});
  const [objInventory, setObjInventory] = useState([]);
  const [objRPM, setObjRPM] = useState([]);
  const [objSelected, setObjSelected] = useState([]);
  const [selectedSupplierInv, setSelectedSupplierInv] = useState("");
  const [selectedSupplierRPM, setSelectedSupplierRPM] = useState("");
  const [activeTab, setActiveTab] = useState("");
  const [activeTabInv, setActiveTabInv] = useState("");
  const [activeTabRPM, setActiveTabRPM] = useState("");
  const [isResetClicked, setIsResetClicked] = useState(true);
  const [isOkBtnEnableInv, setIsOkBtnEnableInv] = useState(true);
  const [isOkBtnEnableRPM, setIsOkBtnEnableRPM] = useState(true);
  const [recipient, setRecipient] = useState("");
  const [message, setMessage] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isSendCmOpsEnabled, setSendCmOpsEnabled] = useState(true);
  const [isWarnWindowOpen, setIsWarnWindowOpen] = useState(false);
  const [actionIsOpen, setActionIsOpen] = useState(false);
  const [supplierPlantDropdown, setSupplierPlantDropdown] = useState([]);
  const [selectedSupplierPlant, setSelectedSupplierPlant] = useState({});
  const [activeSort, setActiveSort] = useState();
  const [isSortActive, setIsSortActive] = useState(false);
  const dropdownRefAction = useRef(null);

  const userDetails = useSelector((state) => state.userDetails);

  const isBrowseEnable =
    userDetails.roleName === "BWInnovationP&I" ||
    userDetails.roleName === "BWInnovationMaterialPlanner"
      ? true
      : false;

  const usermailId = JSON.parse(localStorage.getItem("userDetails")).username;
  const dispatch = useDispatch();

  useEffect(() => {
    // setIsLoading(true);
    getInventoryCmDetails();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getInventoryCmDetails = async () => {
    setIsLoading(true);
    let indexInv = 0;
    let indexRPM = 0;

    if (selectedSupplierInv) {
      indexInv = objInventory.findIndex(
        (item) => item.supplierCode === selectedSupplierInv.supplierCode
      );
    }
    if (selectedSupplierRPM) {
      indexRPM = objRPM.findIndex(
        (item) => item.supplierCode === selectedSupplierRPM.supplierCode
      );
    }

    const res = await fetchInventoryCMDetails(arrItem.projectId);
    if (res.status === 200) {
      setObjSupplierStock(Object.assign({}, res.data));
      setObjInventory(Object.assign([], res.data.inventorySupplierList));
      setObjRPM(Object.assign([], res.data.rpmSupplierList));
      setObjSelected(Object.assign([], res.data.rpmSupplierList));
      if (functionSelected === "CM Suppliers") {
        setSupplierPositionInv(res.data, indexInv);
        setSupplierPositionRPM(res.data, indexRPM);
      } else {
        setSupplierPositionRPM(res.data, indexRPM);
        setSupplierPositionInv(res.data, indexInv);
      }
      if (isLoadedFirstTime) {
        if (res.data.inventorySupplierList.length === 0) {
          setFunctionSelected("RPM Suppliers");
        } else {
          setFunctionSelected("CM Suppliers");
        }
        if (arrItem.workflow[5].status === "6") {
          okBtnEnableCheck(res.data, true);
        }
        setIsLoadedFirstTime(false);
      }
    }
    setIsLoading(false);
  };

  const setSupplierPositionInv = (objData, index) => {
    if (objData?.inventorySupplierList.length > 0) {
      onChangeTab(objData?.inventorySupplierList[index], index, "CM Suppliers");
      const supplierPosition = objData?.inventorySupplierList.findIndex(
        (item) => item.supplierCode === selectedSupplierInv.supplierCode
      );
      const totalSuppliers = objData?.inventorySupplierList.length;
      if (isResetClicked) {
        if (supplierPosition === totalSuppliers - 1) {
          setActiveTabInv(objData?.inventorySupplierList[0].supplierCode);
          setSelectedSupplierInv(objData?.inventorySupplierList[0]);
        } else if (supplierPosition < totalSuppliers - 1) {
          setActiveTabInv(
            objData?.inventorySupplierList[supplierPosition + 1].supplierCode
          );
          setSelectedSupplierInv(
            objData?.inventorySupplierList[supplierPosition + 1]
          );
        } else if (supplierPosition === -1) {
          setActiveTabInv(objData?.inventorySupplierList[0].supplierCode);
          setSelectedSupplierInv(objData?.inventorySupplierList[0]);
        }
      } else {
        setIsResetClicked(true);
      }
    } else {
      setInvCountCheck(true);
    }
  };

  const setSupplierPositionRPM = (objData, index) => {
    if (objData?.rpmSupplierList.length > 0) {
      onChangeTab(objData?.rpmSupplierList[index], index, "RPM Suppliers");
      const supplierPosition = objData?.rpmSupplierList.findIndex(
        (item) => item.supplierCode === selectedSupplierRPM.supplierCode
      );
      const totalSuppliers = objData?.rpmSupplierList.length;
      // if (isResetClicked) {
      //   if (supplierPosition === totalSuppliers - 1) {
      //     setActiveTabRPM(objData?.rpmSupplierList[0].supplierCode);
      //     setSelectedSupplierRPM(objData?.rpmSupplierList[0]);
      //   } else if (supplierPosition < totalSuppliers - 1) {
      //     setActiveTabRPM(
      //       objData?.rpmSupplierList[supplierPosition + 1].supplierCode
      //     );
      //     setSelectedSupplierRPM(
      //       objData?.rpmSupplierList[supplierPosition + 1]
      //     );
      //   } else if (supplierPosition === -1) {
      //     setActiveTabRPM(objData?.rpmSupplierList[0].supplierCode);
      //     setSelectedSupplierRPM(objData?.rpmSupplierList[0]);
      //   }
      // } else {
      //   setIsResetClicked(true);
      // }
    } else {
      setRPMCountCheck(true);
    }
  };

  const okBtnEnableCheck = (obj, isCalledFirstTime) => {
    let allRPMActionCompleted = true;
    let allCMActionCompleted = true;

    if (
      obj &&
      obj?.inventorySupplierList &&
      obj?.inventorySupplierList.length > 0
    ) {
      obj?.inventorySupplierList.forEach((item) => {
        if (item.isMailSent === 0 || item.isFileUploaded === 0) {
          allCMActionCompleted = false;
        }
      });
    }

    if (obj && obj?.rpmSupplierList && obj?.rpmSupplierList.length > 0) {
      obj?.rpmSupplierList.forEach((item) => {
        if (
          item.isSkipped === false &&
          (item.isMailSent === 0 || item.isFileUploaded === 0)
        ) {
          allRPMActionCompleted = false;
        }
      });
    }

    if (isCalledFirstTime) {
      if (!allCMActionCompleted) {
        setIsInventoryCMSkipped(true);
      }
      if (!allRPMActionCompleted) {
        setIsRPMSkipped(true);
      }
    } else {
      if (allCMActionCompleted || isInventoryCMSkipped) {
        setIsOkBtnEnableInv(true);
      } else {
        setIsOkBtnEnableInv(false);
      }
      if (allRPMActionCompleted || isRPMSkipped) {
        setIsOkBtnEnableRPM(true);
      } else {
        setIsOkBtnEnableRPM(false);
      }
    }
  };

  useEffect(() => {
    if (functionSelected === "CM Suppliers") {
      setObjSelected(objInventory);
      activeTabInv && setActiveTab(activeTabInv);
    } else {
      setObjSelected(objRPM);
      activeTabRPM && setActiveTab(activeTabRPM);
    }
  }, [functionSelected, activeTabInv, activeTabRPM]);

  useEffect(() => {
    okBtnEnableCheck(objSupplierStock, false);
  }, [objSelected, isRPMSkipped, isInventoryCMSkipped]);

  useEffect(() => {
    prepareRPMSupplierDropdown();
  }, [objSelected]);

  useEffect(() => {
    if (selectedSupplierPlant && Object.keys(selectedSupplierPlant)?.length) {
      const _selectedSupplierPlant = selectedSupplierPlant?.value.split("_");
      const _selectedRPMSupplier = objSelected?.find(
        (item) =>
          item?.supplierCode === _selectedSupplierPlant[0] &&
          item?.plantCode === _selectedSupplierPlant[1]
      );
      setSelectedSupplierRPM(_selectedRPMSupplier);
    }
  }, [selectedSupplierPlant]);

  const prepareRPMSupplierDropdown = () => {
    if (functionSelected === "RPM Suppliers") {
      const arrDropdown = [];
      objSelected.forEach((item) => {
        const dropdownLabel = `${item?.supplierName} + ${item?.plantName}`;
        const dropdownValue = `${item?.supplierCode}_${item?.plantCode}`;
        const obj = {
          label: dropdownLabel,
          value: dropdownValue,
          isSkipped: item?.isSkipped,
        };
        arrDropdown.push(obj);
      });
      setSupplierPlantDropdown(arrDropdown);
      if (
        selectedSupplierPlant &&
        Object.keys(selectedSupplierPlant)?.length === 0
      ) {
        setSelectedSupplierPlant(arrDropdown?.[0]);
      } else {
        if (
          selectedSupplierPlant &&
          Object.keys(selectedSupplierPlant)?.length
        ) {
          const _selectedSupplierPlant = { ...selectedSupplierPlant };
          const _selectedSupplierPlant1 =
            _selectedSupplierPlant?.value.split("_");
          const _selectedRPMSupplier = objSelected?.find(
            (item) =>
              item?.supplierCode === _selectedSupplierPlant1[0] &&
              item?.plantCode === _selectedSupplierPlant1[1]
          );
          _selectedSupplierPlant.isSkipped = _selectedRPMSupplier?.isSkipped;
          setSelectedSupplierPlant(_selectedSupplierPlant);
          setSelectedSupplierRPM(_selectedRPMSupplier);
        }
      }
    }
  };
  const onSupplierPlantCheckBoxClicked = (value) => {
    const _objSelected = [...objSelected];
    const _supplierPlantDropdown = [...supplierPlantDropdown];
    const filterSupplierPlantDropdown = _supplierPlantDropdown?.find(
      (item) => item?.value === selectedSupplierPlant?.value
    );
    filterSupplierPlantDropdown.isSkipped =
      !filterSupplierPlantDropdown?.isSkipped;
    const _selectedSupplierPlant = selectedSupplierPlant?.value.split("_");
    const _selectedRPMSupplier = _objSelected?.find(
      (item) =>
        item?.supplierCode === _selectedSupplierPlant[0] &&
        item?.plantCode === _selectedSupplierPlant[1]
    );
    _selectedRPMSupplier.isSkipped = !_selectedRPMSupplier.isSkipped;
    setObjSelected(_objSelected);
    setSupplierPlantDropdown(_supplierPlantDropdown);
  };
  const onChangeTab = (item, index, supplier) => {
    if (supplier === "CM Suppliers") {
      setActiveTabInv(item.supplierCode);
      setSelectedSupplierInv(item);
    } else {
      setActiveTabRPM(item.supplierCode);
      prepareRPMSupplierDropdown();
    }
  };

  /* Send Button Click Logic Start */

  const handleRecipientChange = (event) => {
    setRecipient(event.target.value);
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const handleRecipientValueOnEnter = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
    if (event.type === "blur") {
      if (validateUnileverMailId(event.target.value)) {
        setValidationMessage("");
        setRecipient(event.target.value);
        getUserDetails(event.target.value);
      } else {
        setValidationMessage("Please provide valid Unilever Mail id");
      }
    }
  };

  const handleMessageValueOnEnter = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
    if (event.type === "blur") {
      setMessage(event.target.value);
    }
  };

  useEffect(() => {
    dataCheck();
  }, [message, recipient]);

  const dataCheck = () => {
    setSendCmOpsEnabled(emptyValueCheck(recipient) || emptyValueCheck(message));
  };

  const emptyValueCheck = (value) => {
    if (value === null || value === "") {
      return true;
    } else {
      return false;
    }
  };

  const getUserDetails = async (recepientMailId) => {
    const userDetails = await UserDetailsService.fetchUsersByMailId(
      recepientMailId
    );
    if (userDetails.status === 200) {
      setValidationMessage("");
      setRecipient(recepientMailId);
    } else {
      setValidationMessage("The Unilever mail id does not exist");
    }
  };

  const onSendBtnClicked = async () => {
    setIsLoading(true);
    let ValidateFGDetails;
    const inventoryDetail = {};
    inventoryDetail.projectId = objSupplierStock.projectId;
    inventoryDetail.projectName = objSupplierStock.projectName;
    inventoryDetail.projectDescription = objSupplierStock.projectDescription;
    inventoryDetail.projectFamily = objSupplierStock.projectFamily;
    inventoryDetail.deadline = objSupplierStock.deadline;
    inventoryDetail.appLink = objSupplierStock.appLink;
    inventoryDetail.projectIdDisplay = objSupplierStock.projectIdDisplay;
    inventoryDetail.cmOpsContact = recipient;
    inventoryDetail.cmOpsMessage = message;
    inventoryDetail.isMailSent = 1;
    if (functionSelected === "CM Suppliers") {
      ValidateFGDetails = 1;
      inventoryDetail.supplierCode = selectedSupplierInv.supplierCode;
      inventoryDetail.supplierName = selectedSupplierInv.supplierName;

      inventoryDetail.isFileUploaded = selectedSupplierInv.isFileUploaded;
      inventoryDetail.inventoryAtCMDetail =
        selectedSupplierInv.inventorySupplierDetail;
    } else {
      ValidateFGDetails = 0;
      inventoryDetail.supplierCode = selectedSupplierRPM.supplierCode;
      inventoryDetail.supplierName = selectedSupplierRPM.supplierName;

      inventoryDetail.isFileUploaded = selectedSupplierRPM.isFileUploaded;
      inventoryDetail.inventoryAtCMDetail =
        selectedSupplierRPM.rpmSupplierDetail;
    }

    const res = await sendToCMOps(inventoryDetail, ValidateFGDetails);
    if (res.status === 200) {
      const res1 = await fetchProjectWorkFlowStatus(arrItem.projectId);
      if (res1.status === 200) {
        const res = {
          projectName: props.selectedProjectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(res));
      }
    } else {
      console.log("error is send to CMOps", res.data);
    }
    setRecipient("");
    setMessage("");
    getInventoryCmDetails();
  };

  /* Send Button Click Logic Start */

  /* Download Excel Click Logic Start */
  const onPreviewExcelBtnClicked = () => {
    const link = document.createElement("a");
    link.href = functionSelected === "CM Suppliers" ? templateInv : templateRPM;
    link.download =
      functionSelected === "CM Suppliers" ? "InventoryTemplate" : "RPMTemplate";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  /* Download Excel Click Logic End */

  /* Reset Button Click Logic Start*/
  const onResetBtnClicked = async () => {
    setIsLoading(true);
    const obj = Object.assign({}, objSupplierStock);
    if (
      obj &&
      ((functionSelected === "CM Suppliers" &&
        obj.inventorySupplierList &&
        obj.inventorySupplierList.length > 0) ||
        (functionSelected === "RPM Suppliers" &&
          obj.rpmSupplierList &&
          obj.rpmSupplierList.length > 0))
    ) {
      const supplierCode =
        functionSelected === "CM Suppliers"
          ? selectedSupplierInv.supplierCode
          : selectedSupplierRPM.supplierCode;
      const plantCode =
        functionSelected === "CM Suppliers"
          ? ""
          : selectedSupplierRPM.plantCode;
      const res = await resetInventoryCMDetails(
        supplierCode,
        arrItem.projectId,
        plantCode
      );
      if (res.status === 200) {
        if (functionSelected === "CM Suppliers") {
          const selectedSuppliers = obj.inventorySupplierList.filter(
            (item) => item.supplierCode === selectedSupplierInv.supplierCode
          );
          if (selectedSuppliers.length > 0) {
            selectedSuppliers[0].cmOpsContact = "";
            selectedSuppliers[0].cmOpsMessage = "";
            selectedSuppliers[0].isMailSent = 0;
            selectedSuppliers[0].isFileUploaded = 0;
            setObjSupplierStock(Object.assign({}, obj));
            setObjInventory(Object.assign([], obj.inventorySupplierList));
            setObjSelected(Object.assign([], obj.inventorySupplierList));
          }
        } else {
          const selectedSuppliers = obj.rpmSupplierList.filter(
            (item) => item.supplierCode === selectedSupplierRPM.supplierCode
          );
          if (selectedSuppliers.length > 0) {
            selectedSuppliers[0].cmOpsContact = "";
            selectedSuppliers[0].cmOpsMessage = "";
            selectedSuppliers[0].isMailSent = 0;
            selectedSuppliers[0].isFileUploaded = 0;
            setObjSupplierStock(Object.assign({}, obj));
            setObjRPM(Object.assign([], obj.rpmSupplierList));
            setObjSelected(Object.assign([], obj.rpmSupplierList));
          }
        }
        const res1 = await fetchProjectWorkFlowStatus(arrItem.projectId);
        const resData = {
          projectName: props.selectedProjectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(resData));
      } else {
        console.log("error in reset CMOps", res.data);
      }
      setRecipient("");
      setMessage("");
      setIsResetClicked(false);
    }
  };

  const handleFunctionSelection = (functionSelected) => {
    setFunctionSelected(functionSelected);
    setValidationMessage("");
    setRecipient("");
    setMessage("");
  };

  useEffect(() => {
    !isResetClicked && getInventoryCmDetails();
  }, [isResetClicked]);

  /* Reset Button Click Logic End */

  /*File Upload Logic Start */
  const onHandleFileUpload = async (res) => {
    setIsLoading(true);
    if (res.status === 200) {
      let index = 0;
      setObjSupplierStock(Object.assign({}, res.data));
      setObjInventory(Object.assign([], res.data.inventorySupplierList));
      setObjRPM(Object.assign([], res.data.rpmSupplierList));
      setObjSelected(Object.assign([], res.data.rpmSupplierList));
      if (functionSelected === "CM Suppliers") {
        index = objInventory.findIndex(
          (item) => item.supplierCode === selectedSupplierInv.supplierCode
        );
        setSupplierPositionInv(res.data, index);
      } else {
        index = objRPM.findIndex(
          (item) =>
            item.supplierCode === selectedSupplierRPM.supplierCode &&
            item?.plantCode === selectedSupplierRPM?.plantCode
        );
        setSupplierPositionRPM(res.data, index);
      }
    }
    setIsLoading(false);
  };
  /*File Upload Logic End */

  /* Save Button Click Logic Start */
  const onWarnModalOpen = (modalOpen) => {
    if (stepStatus === "2") {
      setIsWarnWindowOpen(modalOpen);
    } else {
      onOkBtnClicked();
    }
  };

  const onOkBtnClicked = async () => {
    setIsLoading(true);
    const payload = {
      projectId: arrItem.projectId,
      isInventorySkip: isInventoryCMSkipped || isRPMSkipped,
      inventorySupplierList: [],
      projectDescription: "",
      projectFamily: "",
      projectIdDisplay: "",
      projectName: "",
      rpmSupplierList: objSelected,
    };
    const res = await saveInventoryCMDetails(payload);
    if (res.status === 200) {
      const res1 = await fetchProjectWorkFlowStatus(arrItem.projectId);
      if (res1.status === 200) {
        const res = {
          projectName: props.selectedProjectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(res));
      }
      props.onComplete(false);
    } else {
      console.log("error in save CMOps", res.data);
    }
    setIsLoading(false);
    props.onComplete(false);
  };

  /* Save Button Click Logic End */
  const handleDropdownSupplierPlantChange = (value, objSelectedOption) => {
    setSelectedSupplierPlant(objSelectedOption);
    setActionIsOpen(false);
  };
  const handleClickOutside = (event) => {
    if (
      dropdownRefAction.current &&
      !dropdownRefAction.current.contains(event.target)
    ) {
      setActionIsOpen(false);
    }
  };

  const handleSortChange = (event, isSortingDoneOnString, tabSelected) => {
    const itemId = event.target.id.split("-")[1];
    if (tabSelected === "CM Suppliers") {
      if (
        selectedSupplierInv &&
        selectedSupplierInv?.inventorySupplierDetail?.length > 0
      ) {
        setIsSortActive(true);
        changeActiveSort(
          itemId,
          isSortingDoneOnString,
          selectedSupplierInv?.inventorySupplierDetail,
          tabSelected
        );
      }
    } else {
      if (
        selectedSupplierRPM &&
        selectedSupplierRPM?.rpmSupplierDetail?.length > 0
      ) {
        console.log(" in selectedSupplierRPM", selectedSupplierRPM);
        setIsSortActive(true);
        changeActiveSort(
          itemId,
          isSortingDoneOnString,
          selectedSupplierRPM?.rpmSupplierDetail,
          tabSelected
        );
      }
    }
  };

  const changeActiveSort = async (
    item,
    isSortingDoneOnString,
    objList,
    tabSelected
  ) => {
    console.log("objList", objList);
    let sortedArray = [];
    setActiveSort(item);
    if (!isSortActive) {
      sortedArray = await handleSort(
        objList,
        "ascending",
        item,
        isSortingDoneOnString
      );
    } else {
      sortedArray = await handleSort(
        objList,
        "descending",
        item,
        isSortingDoneOnString
      );
      setIsSortActive(!isSortActive);
    }
    if (tabSelected === "CM Suppliers") {
      setSelectedSupplierInv((prevObj) => ({
        ...prevObj,
        inventorySupplierDetail: [...sortedArray],
      }));
    } else {
      setSelectedSupplierRPM((prevObj) => ({
        ...prevObj,
        rpmSupplierDetail: [...sortedArray],
      }));
    }
  };

  return (
    <>
      <div className="bwi-modal bwi-modal-inventorycm">
        <div className="bwi-landing-modal-header bwi-modal-inventorycm-header">
          <div className="bwi-modal-inventorycm-header-title-div">
            <span className="bwi-modal-title dsf-span-ml">
              Delisted Finished Goods & RPMs
            </span>
            <br />
            <span className="bwi-modal-sub-title dsf-span-s">
              Below FGs or RPMs will be delisted at the different suppliers.
              Please check with the suppliers what FG/RPM is unique to the
              production of the following materials.
            </span>
          </div>
        </div>
        <div className="bwi-modal-inventorycm-body">
          <div className="bwi-skipinventory-div">
            <Row className="bwi-inventorycm-row">
              <ul className="nav bwi-inventorycm-nav-ul">
                <li
                  key="CM Suppliers"
                  className={`${
                    functionSelected === "CM Suppliers" ? "active" : ""
                  }`}
                  onClick={() => handleFunctionSelection("CM Suppliers")}
                >
                  CM Suppliers
                </li>
                <li
                  key="RPM Suppliers"
                  className={`${
                    functionSelected === "RPM Suppliers" ? "active" : ""
                  }`}
                  onClick={() => handleFunctionSelection("RPM Suppliers")}
                >
                  RPM Suppliers
                </li>
              </ul>
              <Col className="bwi-checkbox-col">
                <Checkbox
                  checked={
                    functionSelected === "CM Suppliers"
                      ? isInventoryCMSkipped
                      : isRPMSkipped
                  }
                  disabled={
                    !isEdit ||
                    (functionSelected === "CM Suppliers" &&
                      objInventory.length === 0) ||
                    (functionSelected === "RPM Suppliers" &&
                      objRPM.length === 0)
                  }
                  onChange={() => {
                    if (functionSelected === "CM Suppliers") {
                      setIsInventoryCMSkipped(!isInventoryCMSkipped);
                    } else {
                      setIsRPMSkipped(!isRPMSkipped);
                    }
                  }}
                  className="bwi-checkbox"
                  disableRipple
                />
                <span className="dsf-size12 dsf-font-weight-600">
                  Check this box to skip the below step
                </span>
              </Col>
            </Row>
          </div>
          {functionSelected === "RPM Suppliers" && (
            <div className="bwi-delistbom-select-supplier-div">
              {/* //style={{ display: "flex", marginTop: "10px" }}> */}
              <p className="bwi-delistBOM-dropdown-label dsf-span-sl dsf-font-unileverMedium">
                Select Supplier / Plant Combination
              </p>
              <div
                className="bwi-delistbom-rpm-supplier-dropdown"
                ref={dropdownRefAction}
              >
                <TooltipDropdown
                  options={supplierPlantDropdown}
                  isOpen={actionIsOpen}
                  setIsOpen={setActionIsOpen}
                  listClassName="bwi-delistBOM-action-dropdown"
                  onSelectionChange={(value, objSelectedOption) =>
                    handleDropdownSupplierPlantChange(value, objSelectedOption)
                  }
                  value={selectedSupplierPlant}
                />
              </div>
              <div>
                <Col className="bwi-checkbox-col">
                  <Checkbox
                    key={selectedSupplierPlant?.value}
                    checked={selectedSupplierPlant?.isSkipped ?? false}
                    onChange={(val) => {
                      onSupplierPlantCheckBoxClicked(val);
                    }}
                    className="bwi-checkbox"
                    disableRipple
                  />
                  <span className="dsf-size12 dsf-font-weight-600">
                    Check this box to skip selected Supplier
                  </span>
                </Col>
              </div>
            </div>
          )}
          {functionSelected !== "RPM Suppliers" && (
            <div className="bwi-invcm-supplier-container">
              <div className="bwi-invcm-supplier-arrow-steps clearfix">
                {objSelected &&
                  objSelected.length > 0 &&
                  objSelected.map((item, index) => {
                    const displayName = `${item.supplierCode}_${item.supplierName}`;
                    let isAllValuesPresent = true;
                    if (
                      functionSelected === "CM Suppliers" &&
                      item.inventorySupplierDetail
                    ) {
                      item.inventorySupplierDetail.forEach((element) => {
                        if (
                          !element.fgCode ||
                          !element.fgName ||
                          !element.rpmCode ||
                          !element.rpmName ||
                          !element.inventoryRPMVolume ||
                          !element.inventoryRPMValue ||
                          !element.rpmSnapshotWeek ||
                          !element.rpmConsumptionPerTon
                        ) {
                          isAllValuesPresent = false;
                        }
                      });
                    }
                    if (
                      functionSelected === "RPM Suppliers" &&
                      item.rpmSupplierDetail
                    ) {
                      item.rpmSupplierDetail.forEach((element) => {
                        if (
                          !element.dateOfNextRPMProd ||
                          !element.rpmCode ||
                          !element.rpmName ||
                          !element.inventoryRPMVolume ||
                          !element.inventoryRPMValue ||
                          !element.rpmSnapshotWeek
                        ) {
                          isAllValuesPresent = false;
                        }
                      });
                    }
                    return (
                      <div
                        key={item.supplierCode}
                        className={`bwi-invcm-supplier-step ${
                          activeTab === item.supplierCode
                            ? "bwi-invcm-supplier-current"
                            : ""
                        }`}
                        onClick={() =>
                          onChangeTab(item, index, functionSelected)
                        }
                      >
                        <div
                          className={`bwi-invcm-supplier-span ${
                            index === 0 ? "bwi-invcm-supplier-span-first" : ""
                          }
                      ${
                        index === objSelected.length - 1
                          ? "bwi-invcm-supplier-span-last"
                          : ""
                      } `}
                        >
                          {displayName.length > 17
                            ? `${displayName.substring(0, 16)}...`
                            : displayName}
                          {"  "}
                          {(item.isMailSent === 1 ||
                            item.isFileUploaded === 1) &&
                            (isAllValuesPresent ? (
                              activeTab === item.supplierCode ? (
                                <img
                                  className="bwi-invcm-supplier-status-icon"
                                  src={supplierTickActive}
                                ></img>
                              ) : (
                                <img
                                  className="bwi-invcm-supplier-status-icon"
                                  src={supplierTick}
                                ></img>
                              )
                            ) : activeTab === item.supplierCode ? (
                              <img
                                className="bwi-invcm-supplier-status-icon"
                                src={supplierPartialActive}
                              ></img>
                            ) : (
                              <img
                                className="bwi-invcm-supplier-status-icon"
                                src={supplierPartial}
                              ></img>
                            ))}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}

          {(
            functionSelected === "CM Suppliers"
              ? !selectedSupplierInv?.isMailSent
              : !selectedSupplierRPM?.isMailSent
          ) ? (
            <>
              <Row className="bwi-inventorycm-mail-label">
                <Col xs={3} className="bwi-inventorycm-mail-label">
                  <label>
                    {functionSelected === "CM Suppliers"
                      ? "Recipient in CM Ops"
                      : "Recipient in E2E planner"}
                  </label>
                </Col>
                <Col
                  xs={6}
                  className="bwi-inventorycm-mail-label bwi-inventorycm-mail-label-msg"
                >
                  <label>Message</label>
                </Col>
              </Row>

              <Row
                className="bwi-inventorycm-mail-input"
                style={{ width: "104%" }}
              >
                <Col xs={3} className="bwi-inventorycm-mailid-text">
                  <input
                    type="text"
                    name="recipient "
                    value={recipient}
                    disabled={
                      !isEdit ||
                      (functionSelected === "CM Suppliers" &&
                        objInventory.length === 0) ||
                      (functionSelected === "RPM Suppliers" &&
                        objRPM.length === 0)
                    }
                    className={`bwi-inventorycm-textField ${
                      validationMessage.length > 0
                        ? "errorBorder"
                        : "successBorder"
                    }`}
                    onChange={(e) => handleRecipientChange(e)}
                    onBlur={(e) => handleRecipientValueOnEnter(e)}
                    onKeyDown={(e) => handleRecipientValueOnEnter(e)}
                  />
                  <span
                    className="dsf-size11 dsf-font-weight-400 errorMsg"
                    style={{ textAlign: "left" }}
                  >
                    {validationMessage}
                  </span>
                </Col>
                <Col xs={5} className="bwi-inventorycm-mailmsg-text">
                  <input
                    type="text"
                    name="message"
                    value={message}
                    disabled={
                      !isEdit ||
                      (functionSelected === "CM Suppliers" &&
                        objInventory.length === 0) ||
                      (functionSelected === "RPM Suppliers" &&
                        objRPM.length === 0)
                    }
                    className="bwi-inventorycm-textField"
                    style={{ width: "660px" }}
                    onChange={(e) => handleMessageChange(e)}
                    onBlur={(e) => handleMessageValueOnEnter(e)}
                    onKeyDown={(e) => handleMessageValueOnEnter(e)}
                  />
                </Col>
                <div className="bwi-inventorycm-download-btn-col">
                  <Button
                    className="bwi-inventorycm-download-btn"
                    variant="secondary"
                    disabled={isSendCmOpsEnabled || !isEdit}
                    onClick={() => onSendBtnClicked()}
                  >
                    {functionSelected === "CM Suppliers"
                      ? "Send to CM Ops"
                      : "Send to E2E Planner"}
                  </Button>
                  {functionSelected === "CM Suppliers" && (
                    <div
                      className="bwi-inv-download-div"
                      onClick={() => onPreviewExcelBtnClicked()}
                      style={{ width: "170px" }}
                    >
                      <img
                        src={downloadIcon}
                        alt="inv-download-Image"
                        className="bwi-inv-download-Img"
                      />
                      <span
                        className="download-text"
                        style={{ fontSize: "12px" }}
                      >
                        Download Template
                      </span>
                    </div>
                  )}
                </div>
                {functionSelected !== "CM Suppliers" && (
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Button
                      className="bwi-inventoryrpm-footer-btn bwi-submit-button"
                      variant="secondary"
                      onClick={() => onPreviewExcelBtnClicked()}
                    >
                      Download Project Supplier Stock
                    </Button>
                  </div>
                )}
              </Row>
            </>
          ) : (
            <>
              <p className="dsf-span-s bwi-inventorycm-upload-label">
                Browse and Upload Excel file containing data from{" "}
                {functionSelected === "CM Suppliers" ? "CM Ops" : "E2E Planner"}
              </p>

              <Row
                className={`${
                  functionSelected === "CM Suppliers"
                    ? "bwi-inventorycm-fileUpload-row"
                    : "bwi-inventorycm-rpmsupplier-fileUpload-row"
                }`}
              >
                {functionSelected === "CM Suppliers" ? (
                  <Col className="bwi-inventorycm-fileUpload-col">
                    <FileUploaderInventory
                      key={selectedSupplierInv.supplierCode}
                      supplierCode={selectedSupplierInv.supplierCode}
                      projectId={arrItem.projectId}
                      projectName={props.selectedProjectName}
                      usermailId={usermailId}
                      onStartFileUpload={() => setIsLoading(true)}
                      handlefileUpload={onHandleFileUpload}
                      BrowseDisabled={invCountCheck || !isBrowseEnable}
                      isRPMSupplier={false}
                      plantCode=""
                    />
                  </Col>
                ) : (
                  <Col xs={8} className="bwi-inventorycm-fileUpload-col">
                    <FileUploaderInventory
                      key={selectedSupplierRPM.supplierCode}
                      supplierCode={selectedSupplierRPM.supplierCode}
                      projectId={arrItem.projectId}
                      projectName={props.selectedProjectName}
                      usermailId={usermailId}
                      onStartFileUpload={() => setIsLoading(true)}
                      handlefileUpload={onHandleFileUpload}
                      BrowseDisabled={rpmCountCheck || !isBrowseEnable}
                      isRPMSupplier={true}
                      plantCode={selectedSupplierRPM?.plantCode}
                    />
                  </Col>
                )}
                {functionSelected !== "CM Suppliers" && (
                  <Col
                    xs={4}
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <Button
                      className="bwi-inventoryrpm-footer-btn bwi-submit-button"
                      variant="secondary"
                      onClick={() => onPreviewExcelBtnClicked()}
                    >
                      Download Project Supplier Stock
                    </Button>
                  </Col>
                )}
              </Row>
            </>
          )}
          {isLoading ? (
            <ActivityIndicator className="bwi-inventory-loader" />
          ) : null}

          {!isLoading &&
          (functionSelected === "CM Suppliers"
            ? invCountCheck
            : rpmCountCheck) ? (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <span className="no-data-found">No data found</span>
            </div>
          ) : null}
          <div className="bwi-inventorycm-table-div">
            {functionSelected === "CM Suppliers" ? (
              <div className="scrollable-container">
                <Row className="bwi-inventorycm-table-row">
                  <div className="flex-fill" style={{ width: "6%" }}>
                    <span className="dsf-size12 dsf-font-weight-700">
                      Supplier Code
                    </span>
                  </div>
                  <div className="flex-fill" style={{ width: "13%" }}>
                    <span className="dsf-size12 dsf-font-weight-700 bwi-font-break-all">
                      Supplier
                    </span>
                  </div>
                  <div
                    id="bwirpmid-fgCode"
                    onClick={(e) => handleSortChange(e, false, "CM Suppliers")}
                    style={{ width: "6%", display: "flex" }}
                    className="flex-fill"
                  >
                    <p className="dsf-size12 dsf-font-weight-700"> FG Code</p>
                    {activeSort === "fgCode" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                  </div>
                  <div
                    id="bwirpmid-fgName"
                    onClick={(e) => handleSortChange(e, true, "CM Suppliers")}
                    style={{ width: "13%", display: "flex" }}
                    className="flex-fill"
                  >
                    <p className="dsf-size12 dsf-font-weight-700">FG Name</p>
                    {activeSort === "fgName" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                  </div>
                  <div
                    id="bwirpmid-rpmCode"
                    onClick={(e) => handleSortChange(e, false, "CM Suppliers")}
                    style={{ width: "6%", display: "flex" }}
                    className="flex-fill"
                  >
                    <p className="dsf-size12 dsf-font-weight-700">RPM Code</p>
                    {activeSort === "rpmCode" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                  </div>
                  <div
                    id="bwirpmid-rpmName"
                    onClick={(e) => handleSortChange(e, true, "CM Suppliers")}
                    style={{ width: "13%", display: "flex" }}
                    className="flex-fill"
                  >
                    <p className="dsf-size12 dsf-font-weight-700"> RPM Name</p>
                    {activeSort === "rpmName" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                  </div>
                  <div
                    id="bwirpmid-inventoryRPMVolume"
                    onClick={(e) => handleSortChange(e, false, "CM Suppliers")}
                    style={{ width: "6%", display: "flex" }}
                    className="flex-fill"
                  >
                    <p className="dsf-size12 dsf-font-weight-700">
                      {" "}
                      Inventory RPM (tonnes)
                    </p>
                    {activeSort === "inventoryRPMVolume" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                  </div>
                  <div
                    id="bwirpmid-inventoryRPMValue"
                    onClick={(e) => handleSortChange(e, false, "CM Suppliers")}
                    style={{ width: "6%", display: "flex" }}
                    className="flex-fill"
                  >
                    <p className="dsf-size12 dsf-font-weight-700">
                      {" "}
                      Inventory RPM (€k)
                    </p>
                    {activeSort === "inventoryRPMValue" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                  </div>
                  <div
                    id="bwirpmid-rpmSnapshotWeek"
                    onClick={(e) => handleSortChange(e, "date", "CM Suppliers")}
                    style={{ width: "6%", display: "flex" }}
                    className="flex-fill"
                  >
                    <p className="dsf-size12 dsf-font-weight-700">
                      {" "}
                      Week of RPM Inv. Snapshot
                    </p>
                    {activeSort === "rpmSnapshotWeek" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                  </div>
                  <div
                    id="bwirpmid-rpmConsumptionPerTon"
                    onClick={(e) => handleSortChange(e, false, "CM Suppliers")}
                    style={{ width: "7%", display: "flex" }}
                    className="flex-fill"
                  >
                    <p className="dsf-size12 dsf-font-weight-700">
                      Consumption RPM per 1 ton FG
                    </p>
                    {activeSort === "rpmConsumptionPerTon" && (
                      <img
                        src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                        className="bwi-delist-filter-arrow"
                      />
                    )}
                  </div>
                </Row>
                {!isLoading &&
                  selectedSupplierInv?.inventorySupplierDetail &&
                  selectedSupplierInv?.inventorySupplierDetail.map(
                    (item, index) => {
                      return (
                        <Row className="bwi-inventorycm-table-row" key={index}>
                          <div className="flex-fill" style={{ width: "6%" }}>
                            <span className="dsf-span-s">
                              {selectedSupplierInv.supplierCode}
                            </span>
                          </div>
                          <div className="flex-fill" style={{ width: "13%" }}>
                            <span className="dsf-span-s bwi-font-break-all">
                              {selectedSupplierInv.supplierName}
                            </span>
                          </div>
                          <div className="flex-fill" style={{ width: "6%" }}>
                            <span className="dsf-span-s">{item.fgCode}</span>
                          </div>
                          <div className="flex-fill" style={{ width: "13%" }}>
                            <span className="dsf-span-s bwi-font-break-all">
                              {item.fgName}
                            </span>
                          </div>
                          <div className="flex-fill" style={{ width: "6%" }}>
                            <span className="dsf-span-s">{item.rpmCode}</span>
                          </div>
                          <div className="flex-fill" style={{ width: "13%" }}>
                            <span className="dsf-span-s bwi-font-break-all">
                              {item.rpmName}
                            </span>
                          </div>
                          <div className="flex-fill" style={{ width: "6%" }}>
                            <span className="dsf-span-s">
                              {item.inventoryRPMVolume}
                            </span>
                          </div>
                          <div className="flex-fill" style={{ width: "6%" }}>
                            <span className="dsf-span-s">
                              {item.inventoryRPMValue}
                            </span>
                          </div>
                          <div className="flex-fill" style={{ width: "6%" }}>
                            <span className="dsf-span-s">
                              {item.rpmSnapshotWeek}
                            </span>
                          </div>
                          <div className="flex-fill" style={{ width: "7%" }}>
                            <span className="dsf-span-s">
                              {item.rpmConsumptionPerTon}
                            </span>
                          </div>
                        </Row>
                      );
                    }
                  )}
              </div>
            ) : (
              <div className="rpm-supplier-container-div">
                <div className="rpm-supplier-container-table">
                  <Row className="bwi-inventorycm-table-row">
                    <div
                      id="bwirpmid-dateTemplateSnapshot"
                      onClick={(e) =>
                        handleSortChange(e, "date", "RPM Suppliers")
                      }
                      style={{ width: "6%", display: "flex" }}
                      className="flex-fill"
                    >
                      <p className="dsf-size12 dsf-font-weight-700">
                        {" "}
                        Date Template Snapshot
                      </p>
                      {activeSort === "dateTemplateSnapshot" && (
                        <img
                          src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                          className="bwi-delist-filter-arrow"
                        />
                      )}
                    </div>
                    <div className="flex-fill" style={{ width: "4%" }}>
                      <span className="dsf-size12 dsf-font-weight-700">
                        Supplier Code
                      </span>
                    </div>
                    <div className="flex-fill" style={{ width: "5%" }}>
                      <span className="dsf-size12 dsf-font-weight-700">
                        Supplier Name
                      </span>
                    </div>
                    <div className="flex-fill" style={{ width: "4%" }}>
                      <span className="dsf-size12 dsf-font-weight-700">
                        Plant Code
                      </span>
                    </div>
                    <div className="flex-fill" style={{ width: "5%" }}>
                      <span className="dsf-size12 dsf-font-weight-700">
                        Plant Name
                      </span>
                    </div>
                    <div
                      id="bwirpmid-rpmCode"
                      onClick={(e) =>
                        handleSortChange(e, false, "RPM Suppliers")
                      }
                      style={{ width: "4%", display: "flex" }}
                      className="flex-fill"
                    >
                      <p className="dsf-size12 dsf-font-weight-700">
                        {" "}
                        RPM Code
                      </p>
                      {activeSort === "rpmCode" && (
                        <img
                          src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                          className="bwi-delist-filter-arrow"
                        />
                      )}
                    </div>
                    <div
                      id="bwirpmid-rpmName"
                      onClick={(e) =>
                        handleSortChange(e, true, "RPM Suppliers")
                      }
                      style={{ width: "6%", display: "flex" }}
                      className="flex-fill"
                    >
                      <p className="dsf-size12 dsf-font-weight-700">
                        {" "}
                        RPM Name
                      </p>
                      {activeSort === "rpmName" && (
                        <img
                          src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                          className="bwi-delist-filter-arrow"
                        />
                      )}
                    </div>
                    <div
                      id="bwirpmid-relatedFGs"
                      onClick={(e) =>
                        handleSortChange(e, false, "RPM Suppliers")
                      }
                      style={{ width: "7%", display: "flex" }}
                      className="flex-fill"
                    >
                      <p className="dsf-size12 dsf-font-weight-700">
                        {" "}
                        Related FGs
                      </p>
                      {activeSort === "relatedFGs" && (
                        <img
                          src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                          className="bwi-delist-filter-arrow"
                        />
                      )}
                    </div>
                    <div
                      id="bwirpmid-confirmedPOVolume"
                      onClick={(e) =>
                        handleSortChange(e, false, "RPM Suppliers")
                      }
                      style={{ width: "6%", display: "flex" }}
                      className="flex-fill"
                    >
                      <p className="dsf-size12 dsf-font-weight-700">
                        {" "}
                        Confirmed PO Volume
                      </p>
                      {activeSort === "confirmedPOVolume" && (
                        <img
                          src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                          className="bwi-delist-filter-arrow"
                        />
                      )}
                    </div>
                    <div
                      id="bwirpmid-uom"
                      onClick={(e) =>
                        handleSortChange(e, true, "RPM Suppliers")
                      }
                      style={{ width: "4%", display: "flex" }}
                      className="flex-fill"
                    >
                      <p className="dsf-size12 dsf-font-weight-700"> UOM </p>
                      {activeSort === "uom" && (
                        <img
                          src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                          className="bwi-delist-filter-arrow"
                        />
                      )}
                    </div>
                    <div
                      id="bwirpmid-relatedPONumbers"
                      onClick={(e) =>
                        handleSortChange(e, false, "RPM Suppliers")
                      }
                      style={{ width: "5%", display: "flex" }}
                      className="flex-fill"
                    >
                      <p className="dsf-size12 dsf-font-weight-700">
                        {" "}
                        Related PO Numbers{" "}
                      </p>
                      {activeSort === "relatedPONumbers" && (
                        <img
                          src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                          className="bwi-delist-filter-arrow"
                        />
                      )}
                    </div>
                    <div
                      id="bwirpmid-inventoryUL"
                      onClick={(e) =>
                        handleSortChange(e, false, "RPM Suppliers")
                      }
                      style={{ width: "6%", display: "flex" }}
                      className="flex-fill"
                    >
                      <p className="dsf-size12 dsf-font-weight-700">
                        {" "}
                        Inventory UL{" "}
                      </p>
                      {activeSort === "inventoryUL" && (
                        <img
                          src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                          className="bwi-delist-filter-arrow"
                        />
                      )}
                    </div>
                    <div
                      id="bwirpmid-inventoryRPMVolume"
                      onClick={(e) =>
                        handleSortChange(e, false, "RPM Suppliers")
                      }
                      style={{ width: "15%", display: "flex" }}
                      className="flex-fill"
                    >
                      <p className="dsf-size12 dsf-font-weight-700">
                        {" "}
                        Inventory Supplier Volume(All expected included in
                        confirmed PO Volume){" "}
                      </p>
                      {activeSort === "inventoryRPMVolume" && (
                        <img
                          src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                          className="bwi-delist-filter-arrow"
                        />
                      )}
                    </div>
                    <div
                      id="bwirpmid-supplierUOM"
                      onClick={(e) =>
                        handleSortChange(e, true, "RPM Suppliers")
                      }
                      style={{ width: "4%", display: "flex" }}
                      className="flex-fill"
                    >
                      <p className="dsf-size12 dsf-font-weight-700"> UOM </p>
                      {activeSort === "supplierUOM" && (
                        <img
                          src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                          className="bwi-delist-filter-arrow"
                        />
                      )}
                    </div>
                    <div
                      id="bwirpmid-dateOfNextRPMProd"
                      onClick={(e) =>
                        handleSortChange(e, "date", "RPM Suppliers")
                      }
                      style={{ width: "6%", display: "flex" }}
                      className="flex-fill"
                    >
                      <p className="dsf-size12 dsf-font-weight-700">
                        {" "}
                        Date Of Next Production{" "}
                      </p>
                      {activeSort === "dateOfNextRPMProd" && (
                        <img
                          src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                          className="bwi-delist-filter-arrow"
                        />
                      )}
                    </div>
                    <div
                      id="bwirpmid-plannedVolumeNextProduction"
                      onClick={(e) =>
                        handleSortChange(e, false, "RPM Suppliers")
                      }
                      style={{ width: "8%", display: "flex" }}
                      className="flex-fill"
                    >
                      <p className="dsf-size12 dsf-font-weight-700">
                        {" "}
                        Planned Volume in next production{" "}
                      </p>
                      {activeSort === "plannedVolumeNextProduction" && (
                        <img
                          src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                          className="bwi-delist-filter-arrow"
                        />
                      )}
                    </div>
                  </Row>
                  {!isLoading &&
                    selectedSupplierRPM?.rpmSupplierDetail &&
                    selectedSupplierRPM?.rpmSupplierDetail.map(
                      (item, index) => {
                        return (
                          <Row
                            className="bwi-inventorycm-table-row"
                            key={index}
                          >
                            <div className="flex-fill" style={{ width: "6%" }}>
                              <span className="dsf-span-s">
                                {dateTimeFormat(
                                  item?.dateTemplateSnapshot,
                                  "DD/MM/YYYY"
                                )}
                              </span>
                            </div>
                            <div className="flex-fill" style={{ width: "4%" }}>
                              <span className="dsf-span-s">
                                {selectedSupplierRPM?.supplierCode}
                              </span>
                            </div>
                            <div className="flex-fill" style={{ width: "5%" }}>
                              <span className="dsf-span-s">
                                {selectedSupplierRPM?.supplierName}
                              </span>
                            </div>
                            <div className="flex-fill" style={{ width: "4%" }}>
                              <span className="dsf-span-s bwi-font-break-all">
                                {selectedSupplierRPM?.plantCode}
                              </span>
                            </div>
                            <div className="flex-fill" style={{ width: "5%" }}>
                              <span className="dsf-span-s ">
                                {selectedSupplierRPM?.plantName}
                              </span>
                            </div>
                            <div className="flex-fill" style={{ width: "4%" }}>
                              <span className="dsf-span-s">
                                {item?.rpmCode}
                              </span>
                            </div>
                            <div className="flex-fill" style={{ width: "6%" }}>
                              <span className="dsf-span-s">
                                {item?.rpmName}
                              </span>
                            </div>
                            <div className="flex-fill" style={{ width: "7%" }}>
                              <span className="dsf-span-s break-all">
                                {item?.relatedFGs}
                              </span>
                            </div>

                            <div className="flex-fill" style={{ width: "6%" }}>
                              <span className="dsf-span-s">
                                {item?.confirmedPOVolume}
                              </span>
                            </div>
                            <div className="flex-fill" style={{ width: "4%" }}>
                              <span className="dsf-span-s">{item?.uom}</span>
                            </div>
                            <div className="flex-fill" style={{ width: "5%" }}>
                              <span className="dsf-span-s">
                                {item?.relatedPONumbers}
                              </span>
                            </div>
                            <div className="flex-fill" style={{ width: "6%" }}>
                              <span className="dsf-span-s">
                                {parseFloat(item?.inventoryUL).toFixed(2)}
                              </span>
                            </div>
                            <div className="flex-fill" style={{ width: "15%" }}>
                              <span className="dsf-span-s">
                                {item?.inventoryRPMVolume}
                              </span>
                            </div>
                            <div className="flex-fill" style={{ width: "4%" }}>
                              <span className="dsf-span-s">
                                {item?.supplierUOM}
                              </span>
                            </div>
                            <div className="flex-fill" style={{ width: "6%" }}>
                              <span className="dsf-span-s">
                                {dateTimeFormat(
                                  item?.dateOfNextRPMProd,
                                  "DD/MM/YYYY"
                                )}
                                {/* {selectedSupplierRPM.isFileUploaded === 1 &&
                                item.inventoryRPMValue.length > 0
                                  ? dateTimeFormat(
                                      item?.dateOfNextProduction,
                                      "DD/MM/YYYY"
                                    )
                                  : ""} */}
                              </span>
                            </div>
                            <div className="flex-fill" style={{ width: "8%" }}>
                              <span className="dsf-span-s">
                                {item?.plannedVolumeNextProduction}
                              </span>
                            </div>
                          </Row>
                        );
                      }
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="bwi-inventorycm-footer">
          <div className="bwi-footer-prevbtn-div"></div>
          <div className="bwi-modal-footer">
            <Button
              disabled={
                !isEdit ||
                !(functionSelected === "RPM Suppliers"
                  ? selectedSupplierRPM?.isFileUploaded === 1
                  : selectedSupplierInv?.isMailSent === 1
                  ? selectedSupplierInv?.isMailSent === 1
                  : !isSendCmOpsEnabled)
              }
              className="bwi-inventorycm-footer-btn bwi-reset-button"
              variant="secondary"
              onClick={() => onResetBtnClicked()}
            >
              Reset
            </Button>
            <Button
              className="bwi-inventorycm-footer-btn bwi-submit-button"
              variant="secondary"
              disabled={
                !isEdit || isLoading || !isOkBtnEnableInv || !isOkBtnEnableRPM
              }
              onClick={() => onWarnModalOpen(true)}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryCM;
